import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentFundMaster } from '../AllModels';
import { getAgentRequestByIdURL, getAgentWiseFundRequestURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-agentwisefundrequesthistory',
  templateUrl: './agentwisefundrequesthistory.component.html',
  styleUrls: ['./agentwisefundrequesthistory.component.css']
})
export class AgentwisefundrequesthistoryComponent implements OnInit {

  AgentfundrequestHistoryForm: FormGroup;
  AgentFundMasterModel = new AgentFundMaster();
  reasonFlag:boolean=false;
  agentFundRequestHistoryList: any = [];
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog, private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Number', 'Points', 'Request Type', 'Request Date', 'Status',];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.AgentfundrequestHistoryForm = this.formBuilder.group({
      status: new FormControl(''),
      reason: new FormControl('', [Validators.required,])
    })
    this.getList();
  }

  getList() {

    this.httpService.getRequest(getAgentWiseFundRequestURL + "/" + Number(sessionStorage.getItem("agentId"))).subscribe((data: any) => {
    
      this.agentFundRequestHistoryList = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
