import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { LineMaster, ResultEntryMaster } from '../AllModels';
import { updateResultURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-resultentry',
  templateUrl: './resultentry.component.html',
  styleUrls: ['./resultentry.component.css']
})
export class ResultentryComponent implements OnInit {
  ResultEntryForm: FormGroup;
  ResultEntryMasterModel = new ResultEntryMaster();
  gamename: string;
  gamedate: string;
  linemasterModel = new LineMaster();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe, private router: Router, private spinnerService: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
    this.ResultEntryForm = this.formBuilder.group({

      opennumber: new FormControl('', [Validators.required]),
      Closenumber: new FormControl('', [Validators.required]),
      Midnumber: new FormControl('', [Validators.required]),
      session: new FormControl('', [Validators.required]),
    })
    this.gamename = sessionStorage.getItem("lineName");
    this.gamedate = sessionStorage.getItem("lineDate");
    this.linemasterModel = JSON.parse(sessionStorage.getItem("linemaster"));
    this.ResultEntryMasterModel.lineOpenNo = this.linemasterModel.lineOpenNo;
    this.ResultEntryMasterModel.lineMidNo = this.linemasterModel.lineMidNo;
    this.ResultEntryMasterModel.lineCloseNo = this.linemasterModel.lineCloseNo;
    this.ResultEntryForm.controls['Closenumber'].disable();
    this.ResultEntryForm.controls['opennumber'].disable();
    this.ResultEntryForm.controls['Midnumber'].disable();

  }
  open(event) {
    this.ResultEntryForm.controls['Closenumber'].disable();
    this.ResultEntryForm.controls['opennumber'].enable();
    this.ResultEntryForm.controls['Midnumber'].disable();
  }
  close(event) {

    this.ResultEntryForm.controls['Closenumber'].enable();
    this.ResultEntryForm.controls['opennumber'].disable();
    this.ResultEntryForm.controls['Midnumber'].disable();

  }

  temp1: number = 0;
  temp2: number;
  midno: string;

  calculateopendigit(lineOpenNo) {
    if (lineOpenNo.length == 3) {

    this.temp1 = 0;
    this.temp2 = 0;
    this.temp2 = this.ResultEntryMasterModel.lineOpenNo;
    while (this.temp2) {
      this.temp1 += this.temp2 % 10
      this.temp2 = Math.floor(this.temp2 / 10);

    }
    if (this.temp1 >= 10) {
      this.temp1 = this.temp1 % 10;
    }

    this.midno = String(this.temp1);
    this.ResultEntryMasterModel.lineMidNo = this.midno;
  }
}
  temp3: number = 0;
  temp4: number;
  calculateclosedigit(lineCloseNo) {
    if (lineCloseNo.length == 3) {

    this.temp3 = 0;
    this.temp4 = 0;
    this.midno = String(this.ResultEntryMasterModel.lineMidNo);
    this.temp4 = this.ResultEntryMasterModel.lineCloseNo;
  
    while (this.temp4) {
      this.temp3 += this.temp4 % 10
      this.temp4 = Math.floor(this.temp4 / 10);

    }
    if (this.temp3 >= 10) {
      this.temp3 = this.temp3 % 10;
    }


    if (this.midno.length == 1) {
      this.midno = this.midno + this.temp3;
   
    }
    if (this.midno.length == 2) {
      let tempNumber = parseInt(String(Number(this.midno) / 10));
      this.midno = String(tempNumber + "" + this.temp3);
     
    }
    this.ResultEntryMasterModel.lineMidNo = this.midno;
  }
  }

  updateResult() {
    this.spinnerService.show();
    this.ResultEntryMasterModel.lineId = Number(sessionStorage.getItem("lineId"));
    this.ResultEntryMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.ResultEntryMasterModel.lineName = sessionStorage.getItem("lineName");

    this.httpService.putRequest(updateResultURL, this.ResultEntryMasterModel).subscribe((data: any) => {
      if (data) {
        this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
        this.ResultEntryForm.reset();
        this.ResultEntryMasterModel = new ResultEntryMaster();
        this.router.navigateByUrl("/layout/linehistory");
        this.spinnerService.hide();

      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
        this.spinnerService.hide();

      }
    })
  }

}
