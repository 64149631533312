import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentFundMaster, FundMaster } from '../AllModels';
import { getAgentRequestByIdURL, getAgentWalletURL, getAllFundRequestURL, saveAgentFundURL, saveFundURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
declare var $: any;
@Component({
  selector: 'app-agentrequestlist',
  templateUrl: './agentrequestlist.component.html',
  styleUrls: ['./agentrequestlist.component.css']
})
export class AgentrequestlistComponent implements OnInit {

  AgentfundrequestForm: FormGroup;
  AgentFundMasterModel = new AgentFundMaster();
  // fundrequestList = Array<FundMaster>();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog, private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Number', 'Points', 'Request Type', 'Request Date', 'Status', 'Action'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.AgentfundrequestForm = this.formBuilder.group({
      status: new FormControl(''),
      reason: new FormControl('', [Validators.required,])
    })
    $(document).ready(function () {
      $("#myBtn2,#myBtn3").click(function () {
        $("#myModal2").show();
      });
      $(".closeModal2").click(function () {
        $("#myModal2").hide();
      });
    });

    this.getAllUser();
  }

  showFlag: boolean = false;
  getAllUser() {
    this.httpService.getRequest(getAgentRequestByIdURL + "/" + "Pending").subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  GetStatusWiseDetails(status) {
    this.httpService.getRequest(getAgentRequestByIdURL + "/" + status).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  SaveFund() {


      this.spinnerService.show();
       if (this.AgentFundMasterModel.reson != null) {
        this.reasonFlagForButton = true;
      }
      else {
        this.reasonFlagForButton = false;
  
      }
      if (this.reasonFlagForButton == true) {
        this.httpService.putRequest(saveAgentFundURL, this.AgentFundMasterModel).subscribe((data: any) => {
          if (data == true) {
            this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
            this.getAllUser();
            this.AgentfundrequestForm.reset();
            this.AgentFundMasterModel = new AgentFundMaster();
            this.spinnerService.hide();
            location.reload();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
            this.spinnerService.hide();

          }
        })
        this.reasonFlagForButton = false;
      }
      else {
        this.toastr.errorToastr("Please Enter Reason", "Error");
        this.spinnerService.hide();

  
      }


  
  }

  reasonFlagForButton: boolean = false;
  RejectFundById(element) {
    this.reasonFlag = true;
    this.AgentFundMasterModel.agentId = element.agentMaster.agentId;
    this.AgentFundMasterModel.agentPoints = element.agentPoints;
    this.AgentFundMasterModel.agentFundType = element.agentFundType;
    this.AgentFundMasterModel.agentStatus = "Rejected";
    this.AgentFundMasterModel.agentFundRequestId = element.agentFundRequestId;
 

  }
  reasonFlag: boolean = false;
  AcceptFundById(element) {

        this.reasonFlag = false;
        this.reasonFlagForButton = true;
        this.AgentFundMasterModel.agentId = element.agentMaster.agentId;
        this.AgentFundMasterModel.agentPoints = element.agentPoints;
        this.AgentFundMasterModel.agentFundType = element.agentFundType;
        this.AgentFundMasterModel.agentFundRequestId = element.agentFundRequestId;
        this.AgentFundMasterModel.agentStatus = "Approved";
        this.AgentFundMasterModel.reson = "Approved By Admin";


  }
  ///////////////////////For Search ////////////////////
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
