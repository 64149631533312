import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ColorLineResultEntryMaster, ColorLineMaster } from '../AllModels';
import { getColorLineResultURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';


@Component({
  selector: 'app-colorlineresultentry',
  templateUrl: './colorlineresultentry.component.html',
  styleUrls: ['./colorlineresultentry.component.css']
})
export class ColorlineresultentryComponent implements OnInit {

  ColorResultEntryForm: FormGroup;
  ColorResultEntryMasterModel = new ColorLineResultEntryMaster();
  gamename: string;
  gamedate: string;
  ColorLinemasterModel = new ColorLineMaster();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe, private router: Router, private spinnerService: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
    this.ColorResultEntryForm = this.formBuilder.group({

      value: new FormControl('', [Validators.required]),

    })

    this.gamename = sessionStorage.getItem("name");
    this.gamedate = sessionStorage.getItem("date");
    this.ColorLinemasterModel = JSON.parse(sessionStorage.getItem("ColorLineMaster"));
    this.ColorResultEntryMasterModel.value = this.ColorLinemasterModel.value;

  }


  updateResult() {
    this.spinnerService.show();
    this.ColorResultEntryMasterModel.colorLineId = Number(sessionStorage.getItem("colorLineId"));
    this.ColorResultEntryMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.ColorResultEntryMasterModel.gameName = sessionStorage.getItem("name");

    this.httpService.putRequest(getColorLineResultURL, this.ColorResultEntryMasterModel).subscribe((data: any) => {
      if (data) {
        this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
        this.ColorResultEntryForm.reset();
        this.ColorResultEntryMasterModel = new ColorLineResultEntryMaster();
        this.spinnerService.hide();
        this.router.navigateByUrl("/layout/colorlinehistory");
       

      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
        this.spinnerService.hide();

      }
    })
  }


}
