import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatDialog, MatTableDataSource } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { agentHistoryURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-agenttransactionhistory',
  templateUrl: './agenttransactionhistory.component.html',
  styleUrls: ['./agenttransactionhistory.component.css']
})
export class AgenttransactionhistoryComponent implements OnInit {
  AgentTransactionForm: FormGroup;
  AgentTeansactionList:any;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog) {}

  ngOnInit() {
    this.AgentTransactionForm = this.formBuilder.group({
     
    })
  this.getAllHistory()
 
  }

  getAllHistory()
  {
    this.httpService.getRequest(agentHistoryURL +  "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
     
        this.AgentTeansactionList=data;
     
    })
  }
}
