import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LineHistoryMaster } from '../AllModels';
import { getLineWiseBidURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-viewlinehistory',
  templateUrl: './viewlinehistory.component.html',
  styleUrls: ['./viewlinehistory.component.css']
})
export class ViewlinehistoryComponent implements OnInit {
  LineHistoryMasterModel = new LineHistoryMaster();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router: Router) {
  }

  ngOnInit() {
  }

  showbid1() {
    sessionStorage.setItem("gameName", "Single Digit")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid2() {
    sessionStorage.setItem("gameName", "Jodi")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid3() {
    sessionStorage.setItem("gameName", "Single Patti")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid4() {
    sessionStorage.setItem("gameName", "Double Patti")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid5() {
    sessionStorage.setItem("gameName", "Triple Patti")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid6() {
    sessionStorage.setItem("gameName", "Half Sangam")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid7() {
    sessionStorage.setItem("gameName", "Full Sangam")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid8() {
    sessionStorage.setItem("gameName", "SP Motor")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }
  showbid9() {
    sessionStorage.setItem("gameName", "DP Motor")
    this.router.navigateByUrl("layout/bidwiselinehistory");
  }

}
