import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserregistrationComponent } from './userregistration/userregistration.component';
import { FinancewisereportsComponent } from './financewisereports/financewisereports.component';
import { AgentregistrationComponent } from './agentregistration/agentregistration.component';
import { LinemasterComponent } from './linemaster/linemaster.component';
import { FundrequestComponent } from './fundrequest/fundrequest.component';
import { AgentfundrequestComponent } from './agentfundrequest/agentfundrequest.component';
import { AgentrequestlistComponent } from './agentrequestlist/agentrequestlist.component';
import { AgenttransactionhistoryComponent } from './agenttransactionhistory/agenttransactionhistory.component';
import { AgenttransactionreportsComponent } from './agenttransactionreports/agenttransactionreports.component';
import { GameratemasterComponent } from './gameratemaster/gameratemaster.component';
import { HowtoplaymasterComponent } from './howtoplaymaster/howtoplaymaster.component';
import { UserhistoryComponent } from './userhistory/userhistory.component';
import { viewUserTransactionURL } from './AllURL';
import { ViewuserhistoryComponent } from './viewuserhistory/viewuserhistory.component';
import { LinehistoryComponent } from './linehistory/linehistory.component';
import { CompanyregistrationComponent } from './companyregistration/companyregistration.component';
import { ViewlinehistoryComponent } from './viewlinehistory/viewlinehistory.component';
import { BidwiselinehistoryComponent } from './bidwiselinehistory/bidwiselinehistory.component';
import { HomeComponent } from './home/home.component';
import { ResultentryComponent } from './resultentry/resultentry.component';
import { SchedularlinemasterComponent } from './schedularlinemaster/schedularlinemaster.component';
import { RevertresultComponent } from './revertresult/revertresult.component';
import { AgentfundrequestreportsComponent } from './agentfundrequestreports/agentfundrequestreports.component';
import { AgentwiseuserwinningreportComponent } from './agentwiseuserwinningreport/agentwiseuserwinningreport.component';
import { AgentwiseuserbidlistreportComponent } from './agentwiseuserbidlistreport/agentwiseuserbidlistreport.component';
import { NotificattionmasterComponent } from './notificattionmaster/notificattionmaster.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ResultComponent } from './result/result.component';
import { ResultchartComponent } from './resultchart/resultchart.component';
import { UserloginComponent } from './pages/userlogin/userlogin.component';
import { ShreestarlinemasterComponent } from './shreestarlinemaster/shreestarlinemaster.component';
import { ShreestarlinegameratemasterComponent } from './shreestarlinegameratemaster/shreestarlinegameratemaster.component';
import { ShreestarlinehistoryComponent } from './shreestarlinehistory/shreestarlinehistory.component';
import { ViewshreestarlinehistoryComponent } from './viewshreestarlinehistory/viewshreestarlinehistory.component';
import { BidwiseshreestarlinehistoryComponent } from './bidwiseshreestarlinehistory/bidwiseshreestarlinehistory.component';
import { ShreestarlineresultentryComponent } from './shreestarlineresultentry/shreestarlineresultentry.component';
import { ShreestarlinerevertresultComponent } from './shreestarlinerevertresult/shreestarlinerevertresult.component';
import { SchedularshreestarlinemasterComponent } from './schedularshreestarlinemaster/schedularshreestarlinemaster.component';
import { ShreestarlineresultchartComponent } from './shreestarlineresultchart/shreestarlineresultchart.component';
import { ShreestarlinecurrentdateactiveComponent } from './shreestarlinecurrentdateactive/shreestarlinecurrentdateactive.component';
import { AdminsettingComponent } from './adminsetting/adminsetting.component';
import { AgentprofileComponent } from './agentprofile/agentprofile.component';
import { AddwithrawdetailsComponent } from './addwithrawdetails/addwithrawdetails.component';
import { AgentwisefundrequesthistoryComponent } from './agentwisefundrequesthistory/agentwisefundrequesthistory.component';
import { BannerComponent } from './banner/banner.component';
import { ColorlinemasterComponent } from './colorlinemaster/colorlinemaster.component';
import { ColornewlinemasterComponent } from './colornewlinemaster/colornewlinemaster.component';
import { ColorratemasterComponent } from './colorratemaster/colorratemaster.component';
import { PrivilegemasterComponent } from './privilegemaster/privilegemaster.component';
import { ColorlinecurrentactivedateComponent } from './colorlinecurrentactivedate/colorlinecurrentactivedate.component';
import { ColorlinehistoryComponent } from './colorlinehistory/colorlinehistory.component';
import { ColorlineviewhistoryComponent } from './colorlineviewhistory/colorlineviewhistory.component';
import { ColorlineresultentryComponent } from './colorlineresultentry/colorlineresultentry.component';
import { ColorlinerevertresultentryComponent } from './colorlinerevertresultentry/colorlinerevertresultentry.component';
import { EmployeemasterComponent } from './employeemaster/employeemaster.component';
import { EmployeeprivilegeComponent } from './employeeprivilege/employeeprivilege.component';
import { ColorlinebidhistoryComponent } from './colorlinebidhistory/colorlinebidhistory.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { AddpointsComponent } from './addpoints/addpoints.component';


const routes: Routes = [
  { path: '', pathMatch: "full", redirectTo: 'home' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule) },

  {
    path: 'layout', component: LayoutComponent,
    children: [
      { path: '', component: DashboardComponent },
      // { path: '', loadChildren: () => import('./booksize/booksize.module').then(mod=> mod.BooksizeModule)},
      {
        path: 'userregistration', component: UserregistrationComponent,
      },
      {
        path: 'agentregistration', component: AgentregistrationComponent,
      },
      {
        path: 'linemaster', component: LinemasterComponent,
      },
      {
        path: 'fundrequest', component: FundrequestComponent,
      },
      {
        path: 'agentfundrequest', component: AgentfundrequestComponent,
      },
      {
        path: 'agentfundrequestlist', component: AgentrequestlistComponent,
      },
      {
        path: 'agenttransactionhistory', component: AgenttransactionhistoryComponent,
      },
      {
        path: 'agenttransactionreports', component: AgenttransactionreportsComponent,
      },
      {
        path: 'gameratemaster', component: GameratemasterComponent,
      },
      {
        path: 'howtoplaymaster', component: HowtoplaymasterComponent,
      },
      {
        path: 'userhistory', component: UserhistoryComponent,
      },
      {
        path: 'viewuserhistory', component: ViewuserhistoryComponent,
      },
      {
        path: 'linehistory', component: LinehistoryComponent,
      },
      {
        path: 'companyregistration', component: CompanyregistrationComponent,
      },
      {
        path: 'viewlinehistory', component: ViewlinehistoryComponent,
      },
      {
        path: 'bidwiselinehistory', component: BidwiselinehistoryComponent,
      },
      {
        path: 'resultentry', component: ResultentryComponent,
      },
      {
        path: 'revertresult', component: RevertresultComponent,
      },
      {
        path: 'schedularlinemaster', component: SchedularlinemasterComponent,
      },

      {
        path: 'agentfundrequestreports', component: AgentfundrequestreportsComponent,
      },
      {
        path: 'agentwiseuserwinningreport', component: AgentwiseuserwinningreportComponent,
      },
      {
        path: 'agentwiseuserbidlistreport', component: AgentwiseuserbidlistreportComponent,
      },
      {
        path: 'notificationmaster', component: NotificattionmasterComponent,
      },
      // {
      //   path: 'agentfundrequestreports', component: AgentfundrequestreportsComponent,
      // },
      {
        path: 'financewisereports', component: FinancewisereportsComponent,
      },
      /////////shreestarline///////////
      {
        path: 'shreestarlinemaster', component: ShreestarlinemasterComponent,
      },
      {
        path: 'shreestarlinegameratemaster', component: ShreestarlinegameratemasterComponent,
      },
      {
        path: 'shreestarlinehistory', component: ShreestarlinehistoryComponent,
      },
      {
        path: 'viewshreestarlinehistory', component: ViewshreestarlinehistoryComponent,
      },
      {
        path: 'bidwiseshreestarlinehistory', component: BidwiseshreestarlinehistoryComponent,
      },


      {
        path: 'shreestarlineresultentry', component: ShreestarlineresultentryComponent,
      },
      {
        path: 'shreestarlinerevertresult', component: ShreestarlinerevertresultComponent,
      },
      {
        path: 'schedularshreestarlinemaster', component: SchedularshreestarlinemasterComponent,
      },
      {
        path: 'adminsetting', component: AdminsettingComponent
      },
      {
        path: 'agentprofile', component: AgentprofileComponent
      },
      {
        path: 'addwithrawdetails', component: AddwithrawdetailsComponent
      },
      {
        path: 'agentwisefundrequesthistory', component: AgentwisefundrequesthistoryComponent
      },
      {
        path: 'banner', component: BannerComponent
      },
      {
        path: 'colorlinemaster', component: ColorlinemasterComponent
      },
      {
        path: 'colornewlinemaster', component: ColornewlinemasterComponent
      },
      {
        path: 'colorratemaster', component: ColorratemasterComponent
      },
      {
        path: 'privilegemaster', component: PrivilegemasterComponent
      },
      {
        path:'colorlinehistory',component:ColorlinehistoryComponent
      },
      {
        path:'colorlineviewhistory',component:ColorlineviewhistoryComponent
      },
      {
        path:'colorlineresultentry',component:ColorlineresultentryComponent
      },
      {
        path:'colorlinerevertresultentry',component:ColorlinerevertresultentryComponent
      },
      {
        path:'employeemaster',component:EmployeemasterComponent
      },
      {
        path:'employeeprivilegemaster',component:EmployeeprivilegeComponent
      },
      {
        path:'colorlinebidhistory',component:ColorlinebidhistoryComponent
      },
      {
        path:'userprofile',component:UserprofileComponent
      },
      {
        path:'addpoints',component:AddpointsComponent
      }
   


    ],
  },
  {
    path: 'home', component: HomeComponent,
  },
  {
    path: 'privacypolicy', component: PrivacypolicyComponent,
  },
  {
    path: 'result', component: ResultComponent,
  },
  {
    path: 'resultchart', component: ResultchartComponent,
  },
  { path: 'userlogin', component: UserloginComponent },

  {
    path: 'shreestarlineresultchart', component: ShreestarlineresultchartComponent,
  },
  {
    path: 'shreestarlinecurrentdateactive', component: ShreestarlinecurrentdateactiveComponent,
  },

{
  path:'colorlinecurrentactivedate',component:ColorlinecurrentactivedateComponent
}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
