import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource, MatSort, MatPaginator, DateAdapter, MatDialog, throwToolbarMixedModesError } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FundMaster } from '../AllModels';
import { getAgentWalletURL, getAllFundRequestURL, saveFundURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
declare var $: any;

@Component({
  selector: 'app-fundrequest',
  templateUrl: './fundrequest.component.html',
  styleUrls: ['./fundrequest.component.css']
})
export class FundrequestComponent implements OnInit {

  fundrequestForm: FormGroup;
  FundMasterModel = new FundMaster();
  // fundrequestList = Array<FundMaster>();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog, private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Number', 'Points', 'Request Type', 'Request Date', 'Status', 'Action'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
    this.fundrequestForm = this.formBuilder.group({
      status: new FormControl(''),
      reason: new FormControl('', [Validators.required,])
    })
    this.getAllUser();
    $(document).ready(function () {
      $("#myBtn2,#myBtn3").click(function () {
        $("#myModal2").show();
      });
      $(".closeModal2").click(function () {
        $("#myModal2").hide();
      });
    });


  }
  showFlag: boolean = false;
  getAllUser() {
    this.httpService.getRequest(getAllFundRequestURL + "/" + sessionStorage.getItem("agentId") + "/" + "Pending").subscribe((data: any) => {
      if (data) {
      
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  GetStatusWiseDetails(status) {
    this.httpService.getRequest(getAllFundRequestURL + "/" + sessionStorage.getItem("agentId") + "/" + status).subscribe((data: any) => {
      if (data) {
     
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
checkBalance()
{
  this.httpService.getRequest(getAgentWalletURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
    if(this.FundMasterModel.requestType == 'Add'){
    if (this.FundMasterModel.points <= data.agentwalletPoints) {
  
      this.SaveFund();
    }
    else {
            this.toastr.errorToastr("Insufficient Balance", "Error");
          }
    }
    else{
 
      this.SaveFund();
    }
  })


}
  SaveFund() {
  
      this.spinnerService.show();

        if (this.FundMasterModel.reson != null) {
          this.reasonFlagForButton = true;
        }
        else {
          this.reasonFlagForButton = false;

        }
        if (this.reasonFlagForButton == true) {
          this.httpService.putRequest(saveFundURL, this.FundMasterModel).subscribe((data: any) => {
            if (data) {
              this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
              this.getAllUser();
              this.fundrequestForm.reset();
              this.FundMasterModel = new FundMaster();
              location.reload();
              this.spinnerService.hide();
            }
            else {
              this.toastr.errorToastr("Something went Wrong.", "Error");
              this.spinnerService.hide();
            }
          })
          this.reasonFlagForButton = false;
        }
        else {
          this.toastr.errorToastr("Please Enter Reason", "Error");
          this.spinnerService.hide();

        }
      }


  reasonFlagForButton: boolean = false;
  RejectFundById(element) {

    this.reasonFlag = true;
    this.FundMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.FundMasterModel.points = element.points;
    this.FundMasterModel.byWith = element.byWith;
    this.FundMasterModel.requestType = element.requestType;
    this.FundMasterModel.fundRequestId = element.fundRequestId;
    this.FundMasterModel.userId = element.userMaster.userId;
    this.FundMasterModel.status = "Rejected";

  }
  reasonFlag: boolean = false;
  AcceptFundById(element) {
  
    this.FundMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.FundMasterModel.points = element.points;
    this.FundMasterModel.byWith = element.byWith;
    this.FundMasterModel.requestType = element.requestType;
    this.FundMasterModel.fundRequestId = element.fundRequestId;
    this.FundMasterModel.userId = element.userMaster.userId;
    this.FundMasterModel.reson = "Approved By Admin";
    this.FundMasterModel.status = "Approved";

  }
  ///////////////////////For Search ////////////////////
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
