import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatCheckboxChange, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PrivilegeMaster } from '../AllModels';
import { createPrivilegeMasterURL, getActiveAgentURL, getAllListPrivilegeMasterURL, getByIdPrivilegeMasterURL, updatePriilegeMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-privilegemaster',
  templateUrl: './privilegemaster.component.html',
  styleUrls: ['./privilegemaster.component.css']
})
export class PrivilegemasterComponent implements OnInit {

  PrivilegeMasterForm: FormGroup;
  PrivilegeMasterModel = new PrivilegeMaster();
  ActiveAgentList:Array<PrivilegeMaster>;
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  tempId : number;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe
  ) {
    this.dateAdapter.setLocale('en-GB');
  }
  dataSource = new MatTableDataSource<PrivilegeMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.PrivilegeMasterForm = this.formBuilder.group({
      agentId: new FormControl('',),
      adminSetting: new FormControl('', ),
      agentProfile: new FormControl('', ),
      agentFundRequest: new FormControl('', ),
      agent: new FormControl('',),
      agentRegistration: new FormControl('',),
      agentTransaction: new FormControl('',),
      bannerMaster: new FormControl('',),
      colorGameRateMaster: new FormControl('',),
      colorLineMasterMain: new FormControl('',),
      colorLineMasterSub: new FormControl('',),
      colorSchedularLine: new FormControl('', ),
      dashboard: new FormControl('',),
      fundRequestForm: new FormControl('',),
      fundRequestHistory: new FormControl('',),
      fundRequestList: new FormControl('',),
      gameMaster: new FormControl('',),
      gameRateMaster: new FormControl('',),
      howToPlayMaster: new FormControl('',),
      lineHistory: new FormControl('',),
      lineMasterMain: new FormControl('',),
      lineMasterSub: new FormControl('',),
      notifications: new FormControl('',),
      notificationsMaster: new FormControl('',),
      onlineStarLineGameRateMaster: new FormControl('',),
      onlineStarLineHistory: new FormControl('',),
      onlineStarLineMasterMain: new FormControl('',),
      onlineStarLineMasterSub: new FormControl('',),
      privilegeMaster: new FormControl('',),
      reports: new FormControl('',),
      schedularLine: new FormControl('',),
      schedularOnlineStarLine: new FormControl('',),
      todaysAddWithdraw: new FormControl('',),
      transactionHistory: new FormControl('',),
      userBidList: new FormControl('',),
      userFundRequest: new FormControl('',),
      userHistory: new FormControl('',),
      userMaster: new FormControl('',),
      userRegistration: new FormControl('',),
      userWinningReports: new FormControl('',),
      employeePrivilegeMaster:new FormControl('',),
      employee:new FormControl('',),
      employeeMain:new FormControl('',),


    })

    this.getActiveAgent();
  }

  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }

  SavePrivilege() {
    if (this.isEdit) {
      this.httpService.postRequest(createPrivilegeMasterURL, this.PrivilegeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllPrivilege();
          this.PrivilegeMasterForm.reset();
          this.PrivilegeMasterModel = new PrivilegeMaster();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updatePriilegeMasterURL, this.PrivilegeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllPrivilege();
          this.PrivilegeMasterForm.reset();
          this.PrivilegeMasterModel = new PrivilegeMaster();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
   
    }
  }

  
  getPrivilegeById(privilegeId) {
    this.httpService.getRequest(getByIdPrivilegeMasterURL + "/" + privilegeId).subscribe((data: any) => {
      this.PrivilegeMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }

  getAllPrivilege() {
    this.tempId = this.PrivilegeMasterModel.agentId;
    this.httpService.getRequest(getAllListPrivilegeMasterURL + "/" + this.PrivilegeMasterModel.agentId).subscribe((data: any) => {
      if(data!=null){
        this.PrivilegeMasterModel = data;
        this.PrivilegeMasterModel.agentId=this.tempId;
      }

    })
  }

  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.PrivilegeMasterModel.agent=true;
      this.PrivilegeMasterModel.agentFundRequest=true;
      this.PrivilegeMasterModel.agentProfile=true;
      this.PrivilegeMasterModel.agentRegistration=true;
      this.PrivilegeMasterModel.agentTransaction=true;
      this.PrivilegeMasterModel.bannerMaster=true;
      this.PrivilegeMasterModel.colorGameRateMaster=true;
      this.PrivilegeMasterModel.colorLineMasterMain=true;
      this.PrivilegeMasterModel.colorLineMasterSub=true;
      this.PrivilegeMasterModel.colorSchedularLine=true;
      this.PrivilegeMasterModel.dashboard=true;
      this.PrivilegeMasterModel.fundRequestForm=true;
      this.PrivilegeMasterModel.fundRequestHistory=true;
      this.PrivilegeMasterModel.fundRequestList=true;
      this.PrivilegeMasterModel.gameMaster=true;
      this.PrivilegeMasterModel.gameRateMaster=true;
      this.PrivilegeMasterModel.howToPlayMaster=true;
      this.PrivilegeMasterModel.lineHistory=true;
      this.PrivilegeMasterModel.lineMasterMain=true;
      this.PrivilegeMasterModel.lineMasterSub=true;
      this.PrivilegeMasterModel.notifications=true;
      this.PrivilegeMasterModel.notificationsMaster=true;
      this.PrivilegeMasterModel.onlineStarLineGameRateMaster=true;
      this.PrivilegeMasterModel.onlineStarLineHistory=true;
      this.PrivilegeMasterModel.onlineStarLineMasterMain=true;
      this.PrivilegeMasterModel.onlineStarLineMasterSub=true;
      this.PrivilegeMasterModel.privilegeMaster=true;
      this.PrivilegeMasterModel.reports=true;
      this.PrivilegeMasterModel.schedularLine=true;
      this.PrivilegeMasterModel.schedularOnlineStarLine=true;
      this.PrivilegeMasterModel.todaysAddWithdraw=true;
      this.PrivilegeMasterModel.transactionHistory=true;
      this.PrivilegeMasterModel.userBidList=true;
      this.PrivilegeMasterModel.userFundRequest=true;
      this.PrivilegeMasterModel.userHistory=true;
      this.PrivilegeMasterModel.userMaster=true;
      this.PrivilegeMasterModel.userRegistration=true;
      this.PrivilegeMasterModel.userWinningReports=true;
      this.PrivilegeMasterModel.adminSetting=true;
      this.PrivilegeMasterModel.employee=true;
      this.PrivilegeMasterModel.employeePrivilegeMaster=true;
      this.PrivilegeMasterModel.employeeMain=true;
    }
    else {
      this.PrivilegeMasterModel.agent=false;
      this.PrivilegeMasterModel.agentFundRequest=false;
      this.PrivilegeMasterModel.agentProfile=false;
      this.PrivilegeMasterModel.agentRegistration=false;
      this.PrivilegeMasterModel.agentTransaction=false;
      this.PrivilegeMasterModel.bannerMaster=false;
      this.PrivilegeMasterModel.colorGameRateMaster=false;
      this.PrivilegeMasterModel.colorLineMasterMain=false;
      this.PrivilegeMasterModel.colorLineMasterSub=false;
      this.PrivilegeMasterModel.colorSchedularLine=false;
      this.PrivilegeMasterModel.dashboard=false;
      this.PrivilegeMasterModel.fundRequestForm=false;
      this.PrivilegeMasterModel.fundRequestHistory=false;
      this.PrivilegeMasterModel.fundRequestList=false;
      this.PrivilegeMasterModel.gameMaster=false;
      this.PrivilegeMasterModel.gameRateMaster=false;
      this.PrivilegeMasterModel.howToPlayMaster=false;
      this.PrivilegeMasterModel.lineHistory=false;
      this.PrivilegeMasterModel.lineMasterMain=false;
      this.PrivilegeMasterModel.lineMasterSub=false;
      this.PrivilegeMasterModel.notifications=false;
      this.PrivilegeMasterModel.notificationsMaster=false;
      this.PrivilegeMasterModel.onlineStarLineGameRateMaster=false;
      this.PrivilegeMasterModel.onlineStarLineHistory=false;
      this.PrivilegeMasterModel.onlineStarLineMasterMain=false;
      this.PrivilegeMasterModel.onlineStarLineMasterSub=false;
      this.PrivilegeMasterModel.privilegeMaster=false;
      this.PrivilegeMasterModel.reports=false;
      this.PrivilegeMasterModel.schedularLine=false;
      this.PrivilegeMasterModel.schedularOnlineStarLine=false;
      this.PrivilegeMasterModel.todaysAddWithdraw=false;
      this.PrivilegeMasterModel.transactionHistory=false;
      this.PrivilegeMasterModel.userBidList=false;
      this.PrivilegeMasterModel.userFundRequest=false;
      this.PrivilegeMasterModel.userHistory=false;
      this.PrivilegeMasterModel.userMaster=false;
      this.PrivilegeMasterModel.userRegistration=false;
      this.PrivilegeMasterModel.userWinningReports=false;
      this.PrivilegeMasterModel.adminSetting=false;
      this.PrivilegeMasterModel.employee=false;
      this.PrivilegeMasterModel.employeePrivilegeMaster=false;
      this.PrivilegeMasterModel.employeeMain=false;

    }
  }
}
