import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShreeStarLineResultEntryMaster, ShreeStarLineMaster } from '../AllModels';
import { updateResultURL, updateShreeStarLineResultURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-shreestarlineresultentry',
  templateUrl: './shreestarlineresultentry.component.html',
  styleUrls: ['./shreestarlineresultentry.component.css']
})
export class ShreestarlineresultentryComponent implements OnInit {
  ShreeStarLineResultEntryForm: FormGroup;
  ShreeStarLineResultEntryMasterModel = new ShreeStarLineResultEntryMaster();
  gamename: string;
  gamedate: string;
  shreestarlinemasterModel = new ShreeStarLineMaster();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe, private router: Router, private spinnerService: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
    this.ShreeStarLineResultEntryForm = this.formBuilder.group({

      opennumber: new FormControl('', [Validators.required]),
      Closenumber: new FormControl('', [Validators.required]),
      Midnumber: new FormControl('', [Validators.required]),
      session: new FormControl('', [Validators.required]),
    })
    this.gamename = sessionStorage.getItem("lineName");
    this.gamedate = sessionStorage.getItem("lineDate");
    this.shreestarlinemasterModel = JSON.parse(sessionStorage.getItem("linemaster"));
    this.ShreeStarLineResultEntryMasterModel.openPannaDigit = this.shreestarlinemasterModel.openPannaDigit;
    this.ShreeStarLineResultEntryMasterModel.jodiDigit = this.shreestarlinemasterModel.jodiDigit;
    this.ShreeStarLineResultEntryMasterModel.closePannaDigit = this.shreestarlinemasterModel.closePannaDigit;
    this.ShreeStarLineResultEntryForm.controls['Closenumber'].disable();
    this.ShreeStarLineResultEntryForm.controls['opennumber'].disable();
    this.ShreeStarLineResultEntryForm.controls['Midnumber'].disable();

  }

  open(event) {

    this.ShreeStarLineResultEntryForm.controls['Closenumber'].disable();
    this.ShreeStarLineResultEntryForm.controls['opennumber'].enable();
    this.ShreeStarLineResultEntryForm.controls['Midnumber'].disable();
  }
  close(event) {

    this.ShreeStarLineResultEntryForm.controls['Closenumber'].enable();
    this.ShreeStarLineResultEntryForm.controls['opennumber'].disable();
    this.ShreeStarLineResultEntryForm.controls['Midnumber'].disable();



  }

  temp1: number = 0;
  temp2: number;
  midno: string;

  calculateopendigit(lineOpenNo) {
    if (lineOpenNo.length == 3) {

    this.temp1 = 0;
    this.temp2 = 0;
    this.temp2 = this.ShreeStarLineResultEntryMasterModel.openPannaDigit;
    while (this.temp2) {
      this.temp1 += this.temp2 % 10
      this.temp2 = Math.floor(this.temp2 / 10);

    }
    if (this.temp1 >= 10) {
      this.temp1 = this.temp1 % 10;
    }

    this.midno = String(this.temp1);
    this.ShreeStarLineResultEntryMasterModel.jodiDigit = this.midno;
  }
}
  temp3: number = 0;
  temp4: number;
  calculateclosedigit(lineCloseNo) {
    if (lineCloseNo.length == 3) {

    this.temp3 = 0;
    this.temp4 = 0;
    this.midno = String(this.ShreeStarLineResultEntryMasterModel.jodiDigit);
    this.temp4 = this.ShreeStarLineResultEntryMasterModel.closePannaDigit;



    while (this.temp4) {
      this.temp3 += this.temp4 % 10
      this.temp4 = Math.floor(this.temp4 / 10);

    }
    if (this.temp3 >= 10) {
      this.temp3 = this.temp3 % 10;
    }


    if (this.midno.length == 1) {
      this.midno = this.midno + this.temp3;

    }
    if (this.midno.length == 2) {
      let tempNumber = parseInt(String(Number(this.midno) / 10));
      this.midno = String(tempNumber + "" + this.temp3);

    }

    this.ShreeStarLineResultEntryMasterModel.jodiDigit = this.midno;
  }
  }

  updateResult() {
    this.spinnerService.show();
    this.ShreeStarLineResultEntryMasterModel.shreeStarLineId = Number(sessionStorage.getItem("lineId"));
    this.ShreeStarLineResultEntryMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.ShreeStarLineResultEntryMasterModel.name = sessionStorage.getItem("lineName");
   
    this.httpService.putRequest(updateShreeStarLineResultURL, this.ShreeStarLineResultEntryMasterModel).subscribe((data: any) => {
      if (data) {
        this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
        this.ShreeStarLineResultEntryForm.reset();
        this.ShreeStarLineResultEntryMasterModel = new ShreeStarLineResultEntryMaster();
        this.router.navigateByUrl("/layout/shreestarlinehistory");
        this.spinnerService.hide();

      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
        this.spinnerService.hide();

      }
    })
  }

}
