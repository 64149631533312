import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ColorLineMaster } from '../AllModels';
import { getColorLinesCurrentActiveListURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-colorlinecurrentactivedate',
  templateUrl: './colorlinecurrentactivedate.component.html',
  styleUrls: ['./colorlinecurrentactivedate.component.css']
})
export class ColorlinecurrentactivedateComponent implements OnInit {

  resultName: string;
  resultList: Array<ColorLineMaster>;
  curr = new Date;

  constructor(private formBuilder: FormBuilder, private router: Router, private httpService: HttpmethodsService, public toastr: ToastrManager) { }


  ngOnInit() {
    this.showChart();
  }

  showChart() {

    this.httpService.getRequest(getColorLinesCurrentActiveListURL).subscribe((data: any) => {
      this.resultList = data;
      this.resultName=this.resultList[0].name;
      console.log("resultList=="+JSON.stringify(this.resultList));
      

    })
  }
  navigatehome(){
    if(sessionStorage.getItem("userId") !=null){
      this.router.navigateByUrl("/pages/userhome")
    }
    else{
      this.router.navigateByUrl("/home");
    }
  }
}
