import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BidPointLineWise, ColorLineMaster, UserMaster } from '../AllModels';
import { getActiveColorLineMasterURL, getTotalWinningBidingPointsURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';


@Component({
  selector: 'app-colorlinehistory',
  templateUrl: './colorlinehistory.component.html',
  styleUrls: ['./colorlinehistory.component.css']
})
export class ColorlinehistoryComponent implements OnInit {

  colorLinehistoryForm: FormGroup;
  bidPointLineWiseModel = new BidPointLineWise();
  ActiveLineList:Array<ColorLineMaster>;
  roleFlag:boolean=false;
  roleId:number;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router : Router) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Line Name', 'Line Date', 'Open No','Status', 'Action'];
  dataSource = new MatTableDataSource<UserMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {

    this.getActiveColorLine();

    
  }


  getActiveColorLine()
  {
      this.httpService.getRequest(getActiveColorLineMasterURL).subscribe((data: any) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
        }
        else {
          this.toastr.errorToastr("No Records Found.", "Error");
        }      
      })
    }

    getLineWiseData(colorLineId)
    {
  
        this.httpService.getRequest(getTotalWinningBidingPointsURL + "/" + colorLineId).subscribe((data: any) => {
         this.bidPointLineWiseModel=data;
         if(this.bidPointLineWiseModel.totalBidAmount==null)
         {
          this.bidPointLineWiseModel.totalWinAmount=0;
         }
         if(this.bidPointLineWiseModel.totalBidUser==null)
         {
          this.bidPointLineWiseModel.totalWinUser=0;
         }
    
        })
      }
    getLineById(element)
    {

         sessionStorage.setItem("colorLineId" , element.colorLineId);
         sessionStorage.setItem("name", element.name)
          this.router.navigateByUrl('/layout/colorlineviewhistory');
    }
    getLineById1(element)
    {

      sessionStorage.setItem("ColorLineMaster" , JSON.stringify(element));
         sessionStorage.setItem("colorLineId" , element.colorLineId);
         sessionStorage.setItem("name", element.name)
         sessionStorage.setItem("date", element.date)

          this.router.navigateByUrl('/layout/colorlineresultentry');
    }
    getLineById2(element)
    {

      sessionStorage.setItem("ColorLineMaster" , JSON.stringify(element));
         sessionStorage.setItem("colorLineId" , element.colorLineId);
         sessionStorage.setItem("name", element.name)
         sessionStorage.setItem("date", element.date)

          this.router.navigateByUrl('/layout/colorlinerevertresultentry');
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    exportToExcel() {
      const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
      xlsx.writeFile(wb, 'Color Line History.xlsx');
      }
}
