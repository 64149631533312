import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeePrivilegeMaster, PrivilegeMaster } from 'src/app/AllModels';
import { getAllEmployeePrivilegeMasterURL, getAllListPrivilegeMasterURL } from 'src/app/AllURL';
import { HttpmethodsService } from '../service/httpmethods.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  roleId: number;
  roleFlag: boolean = false;
  roleFlag1: boolean = false;
  privilegeModel = new PrivilegeMaster();
  // employeePrivilegeMasterModel = new EmployeePrivilegeMaster();

  constructor(private router: Router, private httpService: HttpmethodsService) { }

  ngOnInit() {
    this.roleId = Number(sessionStorage.getItem("roleId"));


    if (this.roleId == 1) {
      this.roleFlag = true;
      this.roleFlag1 = false;
    }
    else {
      this.roleFlag = false;
      this.roleFlag1 = true;
    }

    let privilegeFlag = sessionStorage.getItem("editFlag");

if(privilegeFlag === 'true')
{
  this.httpService.getRequest(getAllEmployeePrivilegeMasterURL + "/" + sessionStorage.getItem("employeeId")).subscribe((data: any) => {
    this.privilegeModel.dashboard = data.dashboard;
    this.privilegeModel.agentProfile = data.agentProfile;
    this.privilegeModel.adminSetting = data.adminSetting;
    this.privilegeModel.bannerMaster = data.bannerMaster;
    this.privilegeModel.userMaster = data.userMaster;
    this.privilegeModel.userRegistration = data.userRegistration;
    this.privilegeModel.userFundRequest = data.userFundRequest;
    this.privilegeModel.userHistory = data.userHistory;
    this.privilegeModel.agent = data.agent;
    this.privilegeModel.agentRegistration = data.agentRegistration;
    this.privilegeModel.fundRequestForm = data.fundRequestForm;
    this.privilegeModel.fundRequestList = data.fundRequestList;
    this.privilegeModel.transactionHistory = data.transactionHistory;
    this.privilegeModel.fundRequestHistory = data.fundRequestHistory;
    this.privilegeModel.privilegeMaster = data.privilegeMaster;
    this.privilegeModel.lineMasterMain = data.lineMasterMain;
    this.privilegeModel.lineMasterSub = data.lineMasterSub;
    this.privilegeModel.lineHistory = data.lineHistory;
    this.privilegeModel.schedularLine = data.schedularLine;
    this.privilegeModel.colorLineMasterMain = data.colorLineMasterMain;
    this.privilegeModel.colorSchedularLine = data.colorSchedularLine;
    this.privilegeModel.colorLineMasterSub = data.colorLineMasterSub;
    this.privilegeModel.colorGameRateMaster = data.colorGameRateMaster;
    this.privilegeModel.gameMaster = data.gameMaster;
    this.privilegeModel.gameRateMaster = data.gameRateMaster;
    this.privilegeModel.howToPlayMaster = data.howToPlayMaster;
    this.privilegeModel.onlineStarLineMasterMain = data.onlineStarLineMasterMain;
    this.privilegeModel.schedularOnlineStarLine = data.schedularOnlineStarLine;
    this.privilegeModel.onlineStarLineMasterSub = data.onlineStarLineMasterSub;
    this.privilegeModel.onlineStarLineHistory = data.onlineStarLineHistory;
    this.privilegeModel.onlineStarLineGameRateMaster = data.onlineStarLineGameRateMaster;
    this.privilegeModel.notifications = data.notifications;
    this.privilegeModel.notificationsMaster = data.notificationsMaster;
    this.privilegeModel.reports = data.reports;
    this.privilegeModel.userWinningReports = data.userWinningReports;
    this.privilegeModel.userBidList = data.userBidList;
    this.privilegeModel.todaysAddWithdraw = data.todaysAddWithdraw;
    this.privilegeModel.agentTransaction = data.agentTransaction;
    this.privilegeModel.agentFundRequest = data.agentFundRequest;
    this.privilegeModel.employee = data.employee;
    this.privilegeModel.employeePrivilegeMaster=data.employeePrivilegeMaster;
    this.privilegeModel.employeeMain=data.employeeMain;

  })

}
else{
  this.httpService.getRequest(getAllListPrivilegeMasterURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
    this.privilegeModel.dashboard = data.dashboard;
    this.privilegeModel.agentProfile = data.agentProfile;
    this.privilegeModel.adminSetting = data.adminSetting;
    this.privilegeModel.bannerMaster = data.bannerMaster;
    this.privilegeModel.userMaster = data.userMaster;
    this.privilegeModel.userRegistration = data.userRegistration;
    this.privilegeModel.userFundRequest = data.userFundRequest;
    this.privilegeModel.userHistory = data.userHistory;
    this.privilegeModel.agent = data.agent;
    this.privilegeModel.agentRegistration = data.agentRegistration;
    this.privilegeModel.fundRequestForm = data.fundRequestForm;
    this.privilegeModel.fundRequestList = data.fundRequestList;
    this.privilegeModel.transactionHistory = data.transactionHistory;
    this.privilegeModel.fundRequestHistory = data.fundRequestHistory;
    this.privilegeModel.privilegeMaster = data.privilegeMaster;
    this.privilegeModel.lineMasterMain = data.lineMasterMain;
    this.privilegeModel.lineMasterSub = data.lineMasterSub;
    this.privilegeModel.lineHistory = data.lineHistory;
    this.privilegeModel.schedularLine = data.schedularLine;
    this.privilegeModel.colorLineMasterMain = data.colorLineMasterMain;
    this.privilegeModel.colorSchedularLine = data.colorSchedularLine;
    this.privilegeModel.colorLineMasterSub = data.colorLineMasterSub;
    this.privilegeModel.colorGameRateMaster = data.colorGameRateMaster;
    this.privilegeModel.gameMaster = data.gameMaster;
    this.privilegeModel.gameRateMaster = data.gameRateMaster;
    this.privilegeModel.howToPlayMaster = data.howToPlayMaster;
    this.privilegeModel.onlineStarLineMasterMain = data.onlineStarLineMasterMain;
    this.privilegeModel.schedularOnlineStarLine = data.schedularOnlineStarLine;
    this.privilegeModel.onlineStarLineMasterSub = data.onlineStarLineMasterSub;
    this.privilegeModel.onlineStarLineHistory = data.onlineStarLineHistory;
    this.privilegeModel.onlineStarLineGameRateMaster = data.onlineStarLineGameRateMaster;
    this.privilegeModel.notifications = data.notifications;
    this.privilegeModel.notificationsMaster = data.notificationsMaster;
    this.privilegeModel.reports = data.reports;
    this.privilegeModel.userWinningReports = data.userWinningReports;
    this.privilegeModel.userBidList = data.userBidList;
    this.privilegeModel.todaysAddWithdraw = data.todaysAddWithdraw;
    this.privilegeModel.agentTransaction = data.agentTransaction;
    this.privilegeModel.agentFundRequest = data.agentFundRequest;
    this.privilegeModel.employee = data.employee;
    this.privilegeModel.employeePrivilegeMaster=data.employeePrivilegeMaster
    this.privilegeModel.employeeMain=data.employeeMain;


  })
}
     

     
  
  
    }

  
  logout() {
  
    sessionStorage.removeItem("agentId");
    this.router.navigateByUrl("/auth/login");
  }
}
