import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserBiddingReportMaster, UserWinningReportMaster } from '../AllModels';
import { getActiveAgentURL, getAgentWiseUserBiddingListURL, getTransactionReportURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-agentwiseuserbidlistreport',
  templateUrl: './agentwiseuserbidlistreport.component.html',
  styleUrls: ['./agentwiseuserbidlistreport.component.css']
})
export class AgentwiseuserbidlistreportComponent implements OnInit {
  roleId:number;
  roleFlag:boolean=false;
  role1Flag:boolean=false;
  UserBiddingReportForm: FormGroup;
  UserBiddingReportMasterModel = new UserBiddingReportMaster();
  ActiveAgentList = Array<UserBiddingReportMaster>();
  totalAmount : number = 0;
  reasonFlag:boolean=false;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog,private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.','User Name','Agent Name','Agent No', 'Game','Line','Date','point','Value','session'];
  dataSource = new MatTableDataSource<UserBiddingReportMaster>();
 
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.UserBiddingReportForm = this.formBuilder.group({
      status: new FormControl(''),
      date: new FormControl('', [Validators.required,]),
      toDate: new FormControl('', [Validators.required,]),
      agentId: new FormControl(''),

    })
    this.roleId=Number(sessionStorage.getItem("roleId"));

    if(this.roleId==1)
    {
      this.roleFlag=true;
      this.role1Flag=false;
    }
    else{
      this.roleFlag=false;
      this.role1Flag=true;
    }
    this.getActiveAgent();
  }

  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }

  getTransactionreport()
  {
    this.spinnerService.show();
    this.httpService.postRequest(getAgentWiseUserBiddingListURL,this.UserBiddingReportMasterModel).subscribe((data: any) => {
      if (data) {
        let a=0;
        for(let i=0;i<data.length;i++){
            a=a+data[i].bidPoint;
            this.totalAmount = Number(Math.fround(a).toFixed(2));

        }
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
        this.spinnerService.hide();
      }
    })
  }
  getAgentreport()
  {
    this.spinnerService.show();
    this.UserBiddingReportMasterModel.agentId=Number(sessionStorage.getItem("agentId"));
    this.httpService.postRequest(getAgentWiseUserBiddingListURL,this.UserBiddingReportMasterModel).subscribe((data: any) => {
      if (data) {
        let a=0;
        for(let i=0;i<data.length;i++){
            a=a+data[i].bidPoint;
            this.totalAmount = Number(Math.fround(a).toFixed(2));

        }
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
        this.spinnerService.hide();
      }
    })
  }

  
exportToExcel() {
  const ws: xlsx.WorkSheet =
  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
  const wb: xlsx.WorkBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, 'Agent Bid Report.xlsx');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
