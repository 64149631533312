
export const addUserMasterURL = '/user';
export const updateUserMasterURL = '/user';
export const getAllUserMasterURL = '/user/getAllList';
export const getByIdUserMasterURL='/user/editUser';
export const checkMobileNumberURL = '/user/checkMobNo';
export const getActiveUserListURL="/user/getActiveList";

export const viewUserTransactionURL='/getuserTransction';
export const viewBetTransactionURL='/bidMaster/getAllList';
export const viewWinningTransactionURL='/wining/getWiningList';

export const getActiveFinanceURL='/financeMaster/getActiveList';
export const filterFinanceWiseDataURL='/financeDetails/getFinanceReport/Filter';

export const saveLoginURL='/agent/agentLogin';
// ////////

export const addAgentUserURL='/agent';
export const getAllAgentUser='/agent/getAllList';
export const getUserByIdURL='/agent/editAgent';
export const updateAgentUserURL='/agent';
export const getAgentWalletURL='/agent/getAgentDetails'

export const addLineMasterURL='/lineMaster';
export const updateLineMasterURL='/lineMaster';
export const getAllLineMasterURL = '/lineMaster/getAllList';
export const getByIdLineMasterURL='/lineMaster/editLine';
export const getActiveListURL='/lineMaster/getActiveList';
export const getLineWiseDataURL='/bidMaster/getTotalWinningAmount';
export const getByNameResult="/lineMaster/byname";
export const getAllLineResult='/newlineMaster/getActiveListOfNewLineMaster';

export const getAllFundRequestURL='/fundRequest/getAllByStatusList';
export const saveFundURL='/fundRequest/points';
export const getFundRequestByUserIdURL='/fundRequest/getAllList';
export const saveAgentRequestURL='/agengtFundRequest';
export const getAgentRequestByIdURL='/agengtFundRequest/getPendingRequestList';
export const saveAgentFundURL='/agengtFundRequest';
export const agentHistoryURL='/agengtFundRequest/getAgentTransactionHistoryList';
export const getActiveAgentURL='/agent/getActiveList';
export const getTransactionReportURL='/agengtFundRequest/getAgentTransactionReport';
export const getFundRequestReportURL='/dashboard/getAgentFunReqDetails';
export const getAddWithdrawURL ='/fundRequest/getTodaysData';

export const getAgentWiseUserWinningListURL='/agent/agentWiseUserWinningList';
export const getAgentWiseUserBiddingListURL='/agent/agentWiseUserBidList';


export const saveGameRateMasterURL='/gameratemaster';
export const updateGameRateMasterURL='/gameratemaster';
export const getAllGameRateURL='/gameratemaster/getAllList';
export const getByIdGameRateURl='/gameratemaster/getByIdList';
export const getAllListUrl='/gameratemaster/getAllList';

export const saveHowToPlayURL='/howtoplaymaster';
export const updateHowToPlayURL='/howtoplaymaster';
export const getAllHowToPlayURL='/howtoplaymaster/getAllList';
export const getByIdHowToPlayURl='/howtoplaymaster/getByIdList';

export const saveCompanyURL='/companymaster';
export const updateCompanyURL='/companymaster';
export const getAllCompanyURL='/companymaster/getAllList';
export const getCompanyByIdURL='/companymaster/getByIdList';

export const getUserLineWiseBidURL='/bidMaster/getBidMasterDetails';
export const getLineWiseBidURL='/bidMaster/getBidValueCount';
export const insertIntoBidMaster='/bidMaster';

export const updateResultURL='/result';
export const updateRevertResultURL='/result/updateResult';

export const saveNewLineURL='/newlineMaster';
export const updateNewLineURL='/newlineMaster';
export const getAllNewLineURL='/newlineMaster/getAllNewLineMaster';
export const getByIdNewLineURL='/newlineMaster/editNewLine';

export const getDashboardCountURL='/dashboard/getDashboardCount';
export const getTodaysBidCount='/dashboard/getTodaysBidCount';


export const saveNotificationURL='/notification';
export const updateNotificationURL='/notification';
export const getAllNotificationURL='/notification';
export const getActiveNotificationURL="/notification/getActiveList";
export const getByIdNotificationURL='/notification/getByNotificationMasterId'


// User Panel

////////////////UserLogin/////////////////

export const userLoginURL='/user/userLogin';
export const addFundURL='/fundRequest';
export const getFundsByUserURl='/fundRequest/getAllList';
export const getActiveLineByAgentIdURL='/gameratemaster/getGameRateListByAgentId';

///////game////////
export const getCurrentTimeURL='/result/getCurrentTime';


//Shree Star Line

export const getAllNewShreeStarLineActive='/newShreeStarlineMaster/getActiveListOfNewShreeStarLineMaster';
export const addShreeStarLineMasterURL='/shreestarline';
export const updateShreeStarLineMasterURL='/shreestarline';
export const getAllShreeStarLineMasterURL = '/shreestarline/getAllList';
export const getByIdShreeStarLineMasterURL='/shreestarline/editShreeStarLine';
export const getShreeStarLineActiveListURL='/shreestarline/getActiveList';
export const getStarLineLineWiseDataURL='/shreeStarLineResult/getTotalWinningBidingPointsByShreeStarLineId';
export const getByShreeStarLineNameResult="/shreestarline/byname";
export const getShreeStarLineCurrentDateActiveList="/shreestarline/getShreeStarLineCurrentDateActiveList";

//shree star line game rate
export const saveShreeStarLineGameRateMasterURL='/shreeStarLineGameratemaster';
export const updateShreeStarLineGameRateMasterURL='/shreeStarLineGameratemaster';
export const getAllShreeStarLineGameRateURL='/shreeStarLineGameratemaster/getAllList';
export const getByIdShreeStarLineGameRateURl='/shreeStarLineGameratemaster/getByIdList';
export const getRateByShreeStarLineGameNameURL='/shreeStarLineGameratemaster/getShreeStarLineGameRate';


//new shree star line
export const saveNewShreeStarLineURL='/newShreeStarlineMaster';
export const updateNewShreeStarLineURL='/newShreeStarlineMaster';
export const getAllNewShreeStarLineURL='/newShreeStarlineMaster/getAllNewShreeStarLineMaster';
export const getByIdNewShreeStarLineURL='/newShreeStarlineMaster/editNewShreeStarLine';
export const getShreeStarLineWiseBidURL='/shreeStarLineBidMaster/getShreeStarLineBidMasterDetails';
export const updateShreeStarLineResultURL='/shreeStarLineResult';
export const updateShreeStarLineRevertResultURL='/shreeStarLineResult/updateResult';


//////biswise line history/////
export const getRateByGameNameURL='/gameratemaster/getGameRate';

////admin setting//////
export const createAdminSettingURL = '/adminsetting';
export const updateAdminSettingURL = '/adminsetting';
export const getByIdAdminSettingURL = '/adminsetting/editAdminSetting';
export const getAllAdminSettingURL='/adminsetting/getAllList';

///dashboard
export const getWeeklyListByAgentId= '/bidMaster/getWeeklyListByAgentId';

export const getGameRateListByAgentId='/gameratemaster/getGameRateListByAgentId';


/////shree star line game master
export const getShreeGameRateListByAgentId="/shreeStarLineGameratemaster/getGameRateListByAgentId";


//////agent registration/////

export const getCheckmobno="/agent/checkMobNo";

/////agent fundrequesthistory////
export const getAgentWiseFundRequestURL="/agengtFundRequest/getAgentFundRequestHistoryList";

//Shree star line bidmaster
export const insertIntoShreeStarLineBidMaster="/shreeStarLineBidMaster";
export const shreeStarLineBidHistoryURL="/shreeStarLineBidMaster/getAllList";
export const shreestarLineWinningHistoryURL="/shreeStarLineBidMaster/getAllShreeStarLineWiningList";

//////banner master///
export const createBannerMasterURL="/bannerMaster";
export const updateBannerMasterURL="/bannerMaster";
export const getBannerMasterURL="/bannerMaster/editBannerMaster";
export const getBannerMasterActiveURL="/bannerMaster/getActiveList";
export const getAllBannerMasterURL="/bannerMaster/getAllBannerMasterList";
export const fileUpload="/uploadfile";

/////////colorlinemaster///////////
export const createColorLineMasterURL="/colorline";
export const updateColorLineMasterURL="/colorline";
export const getByIdColorLineMasterURL="/colorline/editColorLine";
export const getActiveColorLineMasterURL="/colorline/getActiveList";
export const getByAllColorLineMasterURL="/colorline/getAllList";
export const getColorLinesCurrentActiveListURL="/colorline/getColorLineCurrentDateActiveList";

////////newcolorlinemaster///////
export const createNewColorLineMasterURL="/newColorlineMaster";
export const updateNewColorLineMasterURL="/newColorlineMaster";
export const getByIdNewColorLineMasterURL="/newColorlineMaster/editNewColorLine";
export const getActiveNewColorLineMasterURL="/newColorlineMaster/getActiveListOfNewColorLineMaster";
export const getAllNewColorLineMasterURL="/newColorlineMaster/getAllNewColorLineMaster";

///////newcolorratemaster//////
export const createNewColorRateMasterURL="/colorLineGameratemaster";
export const updateNewColorRateMasterURL="/colorLineGameratemaster";
export const getActiveNewColorRateMasterURL="/colorLineGameratemaster/getActiveList";
export const getAllListNewColorRateMasterURL="/colorLineGameratemaster/getAllList";
export const getByIdNewColorRateMasterURL="/colorLineGameratemaster/getByIdList";
export const getColorRateListByAgentIdURL="/colorLineGameratemaster/getColorRateListByAgentId";

/////////privilege master////
export const createPrivilegeMasterURL="/privilegeMaster";
export const updatePriilegeMasterURL="/privilegeMaster";
export const getByIdPrivilegeMasterURL="/privilegeMaster/editPrivilege";
export const getAllListPrivilegeMasterURL="/privilegeMaster/getAllList";


////////colorlinehistory//////////
export const getTotalWinningBidingPointsURL="/colorLineResult/getTotalWinningBidingPointsByColorLineId";
export const getColorLineResultURL="/colorLineResult";
export const updateColorLineResultURL="/colorLineResult/updateResult";


///////employee master///////////

export const createEmployeeMasterURL="/employee";
export const updateEmployeeMasterURL="/employee";
export const getAllEmployeeMasterURL="/employee";
export const getByIdEmployeeMasterURL="/employee/getByEmployeeId";
export const getActiveEmployeeMasterURL="/employee/activeEmployee";
export const getLoginEmployeeMasterURL="/employee/employeeLogin";
export const changeEmployeePasswordURL="/employee/changeEmployeePassword/";

//////employee privilege master////
export const createEmployeePrivilegeMasterURL="/employeePrivilegeMaster";
export const updateEmployeePrivilegeMasterURL="/employeePrivilegeMaster";
export const getAllEmployeePrivilegeMasterURL="/employeePrivilegeMaster/getAllAllEmployeePrivilege";
export const getByIdEmployeePrivilegeMasterURL="/employeePrivilegeMaster/editEmployeePrivilege";  

////////color bid////////////
export const createColorBidMasterURL="/colorLineBidMaster";
export const getAllColorBidMasterURL="/colorLineBidMaster/getAllList";
export const colorBidMasterURL="/colorLineBidMaster/getColorLineBidMasterDetails";
export const getAllwinninglistByUserIdURL="/colorLineBidMaster/getAllColorLineWiningList";


////////profit loss game rate///////
export const getGameRateURL="/colorLineGameratemaster/getColorLineGameRate";


///forgorpassword
export const forgotPasswordURL="/agent/forgotPassword";
export const verifyOtpURL="/agent/verifyOtp";
export const changePasswordURL="/agent/changePassword";

////Add points
export const addWallatePointsURL="/fundRequest/addWallatePoints"

