import { ClassStmt } from "@angular/compiler";
import { NumberValueAccessor } from "@angular/forms";
import { NumericConfig } from "@rxweb/reactive-form-validators/models/config";

export class UserMaster {
    fullName: string;
    mobNo: string;
    email: string;
    password: string;
    walletPoints: number = 0;
    status: string;
    agentId: number;
    tranType: String;
    address: string;
    city: string;
    pincode: number;
    userId: number;
    accountHolderName: string;
    confirmCustomerPassword: string;
    accountNo: number;
    bankName: string;
    ifscCode: string;
    gpayNumber: number;
    paytmNumber: number;
    phonepeNumber: number;
}

export class Finance {
    agreementNo: string;
    financeName: string;
    customerFullName: string;
    residenceAddress: string;
    make: string;
    armNameAndNo: string;
    rrmNameANdNo: string;
    rbmNameAndNo: string;
    zrmNameAndNo: string;
    branch: string;
    regdNum: string;
    chasisNum: string;
    engineNum: string;
    status: string;
    dataDate: string;
    financeId: number;
    dpdBtkOd: string;
}

export class ExportFinance {
    financeDetailsMasterReqDtoArrayList: Array<Finance> = [];
    financeId: number;
    compId: number;
}


export class LoginMaster {
    userMob: string;
    password: string;
}

export class FinanceReportMaster {
    financeId: string;
    status: string;
    compId: number;
}

export class AgentMaster {
    agentAccountHolderName: string;
    agentAccountNo: string;
    agentAddress: string;
    agentBankName: string;
    agentEmail: string;
    agentGpayNumber: string;
    agentIfscCode: string;
    agentMobNo: string;
    agentName: string;
    agentPassword: string;
    agentPaytmNumber: string;
    agentPhonepeNumber: string;
    agentPinCode: string;
    agentStatus: string;
    agentcity: string;
    roleId: number;
    agentwalletPoints: number = 0;
    companyMasterId: number;
    whatsAppNo: string;
    commision: number;
}

export class LineMaster {
    agentId: number;
    lineCloseNo: number;
    lineDate: string;
    lineEndTime: Date;
    lineMidNo: string;
    lineName: string;
    lineOpenNo: number;
    lineStartTime: Date;
    status: string;
}

export class FundMaster {
    points: number;
    requestDate: Date;
    requestType: string;
    mobNo: string;
    status: string;
    byWith: string;
    reson: string;
    agentId: number;
    fundRequestId: number;
    userId: number;
}

export class AgentFundMaster {
    agentPoints: number;
    agentId: number;
    agentFundType: string;
    agentStatus: string;
    reson: string;
    agentFundRequestId: number;
}
export class AgentReportMaster {
    fromDate: string;
    toDate: string;
    tranType: string;
    agentId: number;

}

export class AgentFundReportMaster {
    fromdate: string;
    todate: string;
    status: string;
    // agentId:number;
}
export class UserWinningReportMaster {
    fromDate: string;
    toDate: string;
    agentId: number;
}
export class UserBiddingReportMaster {
    fromDate: string;
    toDate: string;
    agentId: number;
}
export class Gameratemaster {
    gameName: string;
    gameRate: number;
    status: string;
    agentId: number;
    agentId1: number;

}
export class shreeStarLineGameRate {
    shreeStarLineGameRateMasterId: number;
    shreeStarLineGameName: string;
    shreeStarLineGameRate: number;
    agentId: number;
    agentName: string;
    shreeStarLineStatus: string;
}
export class Howtoplaymaster {
    videoName: string;
    videoLink: string;
    status: string;
}
export class CompanyMaster {
    companyAddress: string;
    companyEmail: string;
    companyMobNo: string;
    companyName: string;
    companyPassword: string;
    status: string;
}
export class LineHistoryMaster {
    lineId: number;
    agentId: number;
    lineName: string;
    gameName: string;
}

export class ResultEntryMaster {
    lineOpenNo: number;
    lineMidNo: string;
    lineCloseNo: number;
    session: string;
    lineId: number;
    lineName: string;
    agentId: number;

}

export class NewLineMaster {
    newlineName: string;
    newlineEndTime: Date;
    newlineStartTime: Date;
    status: string;
    monday: boolean = false;
    tuesday: boolean = false;
    wednesday: boolean = false;
    thursday: boolean = false;
    friday: boolean = false;
    saturday: boolean = false;
    sunday: boolean = false;
}

export class Dashboard {
    activeUserCnt: number;
    activeAgentCnt: number;
    totalBidAmount: number;
    totalWinAmount: number;
    todayBidCnt: number;
}
export class RevertResultEntryMaster {
    lineOpenNo: number;
    lineMidNo: number;
    lineCloseNo: number;
    session: string;
    lineId: number;
    lineName: string;
    agentId: number;
}
export class LineWaise {
    totalBidpointAmount: number;
    totalCountBidpoint: number;
    totalWinPointsAmount: number;
    totalCountWinPoints: number;
}
export class NotificationMaster {
    notificationMasterTitle: string;
    notificationMasterDesc: string;
    notificationMasterDate: Date;
    status: string;
}

export class FinalResult {
    resultChart1 = new resultChart();
    resultChart2 = new resultChart();
    resultChart3 = new resultChart();
    resultChart4 = new resultChart();
    resultChart5 = new resultChart();
    resultChart6 = new resultChart();
    resultChart7 = new resultChart();
}
export class resultChart {
    lineName: string;
    lineStartTime: any
    lineEndTime: any;
    lineOpenNo: string;
    lineMidNo: string;
    lineCloseNo: string;
    lineDate: any;
    viewStatus: string;
    status: string;
    finalStatus: string;
    openStatus: string;
    markRed: boolean;
}

export class addFund {
    points: number;
    status: string;
    requestType: string;
    userId: number;
    mobNo: number;
}

export class GameMaster {
    lineName: string;
    lineId: number;
    lineStartTime: number;
    lineEndTime: number;
    lineDate: number;
    lineOpenNo: number;
    lineMidNo: number;
    lineCloseNo: number;
    status: string;
    openStatus: string;
    finalStatus: string;
    msg: string;
    colorFlag: string;
    showResult: string;

}

export class shreeStarLineMaster {
    newShreeStarlineName: string;
    newShreeStarlineStartTime: number;
    newShreeStarlineEndTime: number;
    session: string;
    closePannaDigit: number;
    openPannaDigit: number;
    msg: string;
    colorFlag: string;
    showResult: string;
    jodiDigit: number;

}

export class UserGame {

    userId: number;
    lineId: number;
    bidDate: any;
    bidPoint: number;
    game: number;
    value: string;
    session: string;

    openpana: number;
    openCloseDigit: number;
    closepana: number;
    CloseOpenDigit: number;

    agentId: number;
}

export class shreeStarLineBidMaster {
    bidDate: any;
    bidPoint: number;
    game: string;
    session: string;
    shreeStarLineBidId: number;
    shreeStarLineId: number;
    userId: number;
    value: number;
    agentId: number;
    shreeStarLineGameName: string;
    shreeStarLineGameRate: number;
    shreeStarLineStatus: string;
    name: string;

}

export class ShreeStarLineMaster {
    //agentId:number;
    closePannaDigit: number;
    date: string;
    endTime: Date;
    jodiDigit: string;
    name: string;
    openPannaDigit: number;
    startTime: Date;
    status: string;
    monday: boolean = false;
    tuesday: boolean = false;
    wednesday: boolean = false;
    thursday: boolean = false;
    friday: boolean = false;
    saturday: boolean = false;
    sunday: boolean = false;
}

export class ShreestarlineGameratemaster {
    shreeStarLineGameName: string;
    shreeStarLineGameRate: number;
    shreeStarLineStatus: string;
    agentId: number;
    agentId1: number;

}

export class ShreeStarLineHistoryMaster {
    shreeStarLineId: number;
    agentId: number;
    name: string;
    game: string;
}

export class ShreeStarLineResultEntryMaster {
    openPannaDigit: number;
    jodiDigit: string;
    closePannaDigit: number;
    session: string;
    shreeStarLineId: number;
    name: string;
    agentId: number;

}

export class NewShreeStarLineMaster {
    newShreeStarlineName: string;
    newShreeStarlineEndTime: Date;
    newShreeStarlineStartTime: Date;
    status: string;

    monday: boolean = false;
    tuesday: boolean = false;
    wednesday: boolean = false;
    thursday: boolean = false;
    friday: boolean = false;
    saturday: boolean = false;
    sunday: boolean = false;
}

export class ShreeStarLineRevertResultEntryMaster {
    openPannaDigit: number;
    jodiDigit: number;
    closePannaDigit: number;
    session: string;
    shreeStarLineId: number;
    name: string;
    agentId: number;
}

export class ShreeStarLineWaise {
    totalBidAmount: number;
    totalBidUser: number;
    totalWinAmount: number;
    totalWinUser: number;
}
export class ShreeStarLineFinalResult {
    shreeStarLineresultChart1 = new shreeStarLineresultChart();
    shreeStarLineresultChart2 = new shreeStarLineresultChart();
    shreeStarLineresultChart3 = new shreeStarLineresultChart();
    shreeStarLineresultChart4 = new shreeStarLineresultChart();
    shreeStarLineresultChart5 = new shreeStarLineresultChart();
    shreeStarLineresultChart6 = new shreeStarLineresultChart();
    shreeStarLineresultChart7 = new shreeStarLineresultChart();
}
export class shreeStarLineresultChart {
    lineName: string;
    lineStartTime: any
    lineEndTime: any;
    lineOpenNo: string;
    lineMidNo: string;
    lineCloseNo: string;
    lineDate: any;
    viewStatus: string;
    status: string;
    finalStatus: string;
    openStatus: string;
    markRed: boolean;
}

export class profitloss {
    value: string;
    countValue: number;
    totalAmount: number;
    session: string;
    profit: number;
    loss: number;
    no: number;
}

export class colorProfitloss {
    value: string;
    countValue: number;
    totalAmount: number;
    profit: number;
    loss: number;
    no: number;
}

export class AdminSetting {
    withdrawAmount: number;
    addAmount: number;
    adminSettingId: number;
    upiId: string;
    apkVersion: string;
    adminWithdrawAmount: any;
    adminAddAmount: any;
    merchantId: any;
    qrCode:any;
}

export class AddWithdrawMaster {
    todaydate: string;
    status: string;
    agentId: number;
}

export class AgentIdMaster {
    agentId: number;
}

export class bannerMaster {
    bannerId: number;
    expiryDate: Date;
    title: string;
    type: string;
    path: string;
    status: string;
}


export class GetGameRate {
    shreeStarLineGameName: string;
    agentId: number;

}


export class GetGameRateMaster {
    gameName: string;
    agentId: number;

}

export class ColorLineMaster {

    colorLineId: number;
    value: string;
    date: string;
    endTime: Date;
    friday: boolean = false;
    monday: boolean = false;
    name: string;
    saturday: false;
    startTime: Date;
    status: string;
    sunday: boolean = false;
    thursday: boolean = false;
    tuesday: boolean = false;
    wednesday: boolean = false;
    msg: string;
    colorFlag: string;
    showResult: string;



}

export class ColorNewLineMaster {
    newColorLineEndTime: Date;
    newColorLineId: number;
    newColorLineName: string;
    newColorLineStartTime: Date;
    friday: boolean;
    monday: boolean;
    saturday: boolean;
    status: string;
    sunday: string;
    thursday: string;
    tuesday: string;
    wednesday: string;
    msg: string;
    colorFlag: string;
    showResult: string;
    value: string;

}

export class ColorRateMaster {
    agentId: number;
    agentId1: number;
    colorLineGameName: string;
    colorLineGameRate: number;
    colorLineGameRateMasterId: number;
    colorLineStatus: string;
}

export class PrivilegeMaster {
    agentId: number;
    privilegeId: number;
    adminSetting: boolean = false;
    agentProfile: boolean = false;
    agentFundRequest: boolean = false;
    agent: boolean = false;
    agentRegistration: boolean = false;
    agentTransaction: boolean = false;
    bannerMaster: boolean = false;
    colorGameRateMaster: boolean = false;
    colorLineMasterMain: boolean = false;
    colorLineMasterSub: boolean = false;
    colorSchedularLine: boolean = false;
    dashboard: boolean = false;
    fundRequestForm: boolean = false;
    fundRequestHistory: boolean = false;
    fundRequestList: boolean = false;
    gameMaster: boolean = false;
    gameRateMaster: boolean = false;
    howToPlayMaster: boolean = false;
    lineHistory: boolean = false;
    lineMasterMain: boolean = false;
    lineMasterSub: boolean = false;
    notifications: boolean = false;
    notificationsMaster: boolean = false;
    onlineStarLineGameRateMaster: boolean = false;
    onlineStarLineHistory: boolean = false;
    onlineStarLineMasterMain: boolean = false;
    onlineStarLineMasterSub: boolean = false;
    privilegeMaster: boolean = false;
    reports: boolean = false;
    schedularLine: boolean = false;
    schedularOnlineStarLine: boolean = false;
    todaysAddWithdraw: boolean = false;
    transactionHistory: boolean = false;
    userBidList: boolean = false;
    userFundRequest: boolean = false;
    userHistory: boolean = false;
    userMaster: boolean = false;
    userRegistration: boolean = false;
    userWinningReports: boolean = false;
    employee: boolean = false;
    employeePrivilegeMaster: boolean = false;
    employeeMain: boolean = false;
}

export class PrivilegeNew {
    agentId: number;
}

export class BidPointLineWise {
    totalBidAmount: number;
    totalWinAmount: number;
    totalBidUser: number;
    totalWinUser: number;
}

export class ColorLineResultEntryMaster {
    agentId: number;
    colorLineId: number;
    gameName: string;
    value: string;
}

export class ColorLineGame {
    bidDate: Date;
    bidPoint: number;
    colorLineBidId: number;
    colorLineId: number;
    game: string;
    userId: number;
    value: string;
    agentId: number;
}

export class EmployeeMaster {
    agentId: number;
    alternativeMobileNo: string;
    employeeAddress: string;
    employeeDesignation: string;
    employeeEmail: string;
    employeeFullName: string;
    employeeId: number;
    employeeMobileNo: string;
    password: string;
    status: string;
}

export class EmployeePrivilegeMaster {
    agentId: number;
    employeeId: number;
    employeePrivilegeId: number;
    adminSetting: boolean = false;
    agent: boolean = false;
    agentFundRequest: boolean = false;
    agentProfile: boolean = false;
    agentRegistration: boolean = false;
    agentTransaction: boolean = false;
    bannerMaster: boolean = false;
    colorGameRateMaster: boolean = false;
    colorLineMasterMain: boolean = false;
    colorLineMasterSub: boolean = false;
    colorSchedularLine: boolean = false;
    dashboard: boolean = false;
    fundRequestForm: boolean = false;
    fundRequestHistory: boolean = false;
    fundRequestList: boolean = false;
    gameMaster: boolean = false;
    gameRateMaster: boolean = false;
    howToPlayMaster: boolean = false;
    lineHistory: boolean = false;
    lineMasterMain: boolean = false;
    lineMasterSub: boolean = false;
    notifications: boolean = false;
    notificationsMaster: boolean = false;
    onlineStarLineGameRateMaster: boolean = false;
    onlineStarLineHistory: boolean = false;
    onlineStarLineMasterMain: boolean = false;
    onlineStarLineMasterSub: boolean = false;
    privilegeMaster: boolean = false;
    schedularOnlineStarLine: boolean = false;
    reports: boolean = false;
    schedularLine: boolean = false;
    todaysAddWithdraw: boolean = false;
    transactionHistory: boolean = false;
    userBidList: boolean = false;
    userFundRequest: boolean = false;
    userHistory: boolean = false;
    userMaster: boolean = false;
    userRegistration: boolean = false;
    userWinningReports: boolean = false;
    employee: boolean = false;
    employeePrivilegeMaster: boolean = false;
    employeeMain: boolean = false;
}

export class EmployeeLogin {
    password: string;
    userName: string;
}

export class EmployeeChangePassword {
    confirmPassword: string;
    employeeId: number;
    newPassword: string;
    oldPassword: string;
}

export class ColorGameBidMaster {
    agentId: number;
    colorLineId: number;
    game: string;
    name: string;
}

export class GetColorGameRateMaster {
    agentId: number;
    game: string;
}

export class UserForgotPsw {
    mobNo: string;
}

export class Verfiy {

    agentMobNo: string;
    otp: number

}

export class updatePassowrd{
    confirmPassword:string;
    newPassword:string;
    agentMobNo:string;  
}

export class AddWalletPoints{
    agentId!:number;
    userId!:number;
    walletPoints!:number;
}