import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentReportMaster } from '../AllModels';
import { getActiveAgentURL, getTransactionReportURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-agenttransactionreports',
  templateUrl: './agenttransactionreports.component.html',
  styleUrls: ['./agenttransactionreports.component.css']
})
export class AgenttransactionreportsComponent implements OnInit {
  AgentTransactionReportForm: FormGroup;
  AgentReportMasterModel = new AgentReportMaster();
  ActiveAgentList = Array<AgentReportMaster>();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog,private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'Agent Name', 'Points', 'Date', 'Transaction Type'];
  dataSource = new MatTableDataSource<AgentReportMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.AgentTransactionReportForm = this.formBuilder.group({
      status: new FormControl(''),
      date: new FormControl('', [Validators.required,]),
      toDate: new FormControl('', [Validators.required,]),
      agentId: new FormControl(''),

    })
    this.getActiveAgent();
  }

  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }

  getTransactionreport()
  {
    this.spinnerService.show();
    this.httpService.postRequest(getTransactionReportURL,this.AgentReportMasterModel).subscribe((data: any) => {
      
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
        this.spinnerService.hide();
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
