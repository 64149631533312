import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShreeStarLineRevertResultEntryMaster, LineMaster, RevertResultEntryMaster, ShreeStarLineMaster } from '../AllModels';
import { updateRevertResultURL, updateShreeStarLineRevertResultURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-shreestarlinerevertresult',
  templateUrl: './shreestarlinerevertresult.component.html',
  styleUrls: ['./shreestarlinerevertresult.component.css']
})
export class ShreestarlinerevertresultComponent implements OnInit {

  ShreeStarLineRevertResultEntryForm: FormGroup;
  ShreeStarLineRevertResultEntryMasterModel = new ShreeStarLineRevertResultEntryMaster();
 gamename:string;
 gamedate:string;
 shreestarlinemasterModel= new ShreeStarLineMaster();
 constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe , private router : Router, private spinnerService: Ng4LoadingSpinnerService) {
 }

 ngOnInit() {
   this.ShreeStarLineRevertResultEntryForm = this.formBuilder.group({

     opennumber: new FormControl('', [Validators.required]),
     Closenumber: new FormControl('', [Validators.required]),
     Midnumber: new FormControl('', [Validators.required]),
     session:new FormControl('', [Validators.required]),
   })
   this.gamename=sessionStorage.getItem("lineName");
   this.gamedate=sessionStorage.getItem("lineDate");
   this.shreestarlinemasterModel = JSON.parse(sessionStorage.getItem("linemaster"));


   this.ShreeStarLineRevertResultEntryForm.controls['Closenumber'].disable();
   this.ShreeStarLineRevertResultEntryForm.controls['opennumber'].disable();
   this.ShreeStarLineRevertResultEntryForm.controls['Midnumber'].disable();

 }

 open(event) {

   this.ShreeStarLineRevertResultEntryForm.controls['Closenumber'].disable();
   this.ShreeStarLineRevertResultEntryForm.controls['opennumber'].enable();
   this.ShreeStarLineRevertResultEntryForm.controls['Midnumber'].disable();
 }
 close(event) {

   this.ShreeStarLineRevertResultEntryForm.controls['Closenumber'].enable();
   this.ShreeStarLineRevertResultEntryForm.controls['opennumber'].disable();
   this.ShreeStarLineRevertResultEntryForm.controls['Midnumber'].disable();
 }

 temp1: number=0 ;
 temp2: number ;
 midno:string;
 
 calculateopendigit(openPannaDigit) {
   if (openPannaDigit.length == 3) {
   this.temp2 = this.ShreeStarLineRevertResultEntryMasterModel.openPannaDigit;
   while (this.temp2) {
     this.temp1 += this.temp2 % 10
     this.temp2 = Math.floor(this.temp2 / 10);

   }
   if(this.temp1 >= 10)
   {
     this.temp1= this.temp1%10;
   }
   this.midno = String(this.temp1);
   this.ShreeStarLineRevertResultEntryMasterModel.jodiDigit= Number(this.midno);

 }
}
 temp3: number = 0;
 temp4: number ;
 calculateclosedigit(lineCloseNo)
 {
   if (lineCloseNo.length == 3) {

   this.temp3=0;

   this.midno=String(this.ShreeStarLineRevertResultEntryMasterModel.jodiDigit);
   this.temp4 = this.ShreeStarLineRevertResultEntryMasterModel.closePannaDigit;

   while (this.temp4) {
     this.temp3 += this.temp4 % 10
     this.temp4 = Math.floor(this.temp4 / 10);
   }
   if(this.temp3 >= 10)
   {
     this.temp3= this.temp3%10;
   }
   if(this.midno.length==1){
     this.midno =this.midno + this.temp3;
   }
   if(this.midno.length==2){
     let tempNumber = parseInt(String(Number(this.midno)/10));
     this.midno = String(tempNumber +""+this.temp3);
   }   
   this.ShreeStarLineRevertResultEntryMasterModel.jodiDigit= Number(this.midno);

 }
}

 updateResult()
 {
   this.spinnerService.show();
   this.ShreeStarLineRevertResultEntryMasterModel.shreeStarLineId = Number(sessionStorage.getItem("lineId"));
   this.ShreeStarLineRevertResultEntryMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
   this.ShreeStarLineRevertResultEntryMasterModel.name = sessionStorage.getItem("lineName");

   this.httpService.putRequest(updateShreeStarLineRevertResultURL, this.ShreeStarLineRevertResultEntryMasterModel).subscribe((data: any) => {
     if (data) {
       this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
       this.ShreeStarLineRevertResultEntryForm.reset();
       this.ShreeStarLineRevertResultEntryMasterModel = new ShreeStarLineRevertResultEntryMaster();
       this.router.navigateByUrl("/layout/shreestarlinehistory");
       this.spinnerService.hide();
     }
     else {
       this.toastr.errorToastr("Something went Wrong.", "Error");
       this.spinnerService.hide();
     }
   })
 }

}
