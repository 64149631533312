import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FinalResult } from '../AllModels';
import { getByNameResult } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-resultchart',
  templateUrl: './resultchart.component.html',
  styleUrls: ['./resultchart.component.css']
})
export class ResultchartComponent implements OnInit {

  no: string = '123';
  resultList = Array<FinalResult>();
  number1: number;
  number2: number;
  number3: number;
  number4: number;
  number5: number;
  number6: number;
  number7: number;
  number8: number;
  dateStart: string;
  dateEnd: string;
  resultName: string;

  showJodi: string;
  showJodiFlag: boolean = false;
  showChartFlag: boolean = false;
  curr = new Date;

  first = this.curr.getDate() - this.curr.getDay(); 
  last = this.first + 6;
  user:number;

  constructor(private formBuilder: FormBuilder, private router: Router, private httpService: HttpmethodsService, public toastr: ToastrManager) { }



  ngOnInit() {
    this.showChart();
    this.resultName = String(sessionStorage.getItem("name"));
    // this.user = Number(sessionStorage.getItem("userId"));


    if (sessionStorage.getItem("showjodi") == 'showjodi') {
      sessionStorage.getItem("userId");
      this.showJodiFlag = true;
      this.showChartFlag = false;
      sessionStorage.removeItem("showjodi");
    }
    else if (sessionStorage.getItem("showchart") == 'showchart') {
      sessionStorage.getItem("userId");
      this.showJodiFlag = false;
      this.showChartFlag = true;
      sessionStorage.removeItem("showchart");
    }
  }
  showChart() {

    this.httpService.getRequest(getByNameResult + "/" + sessionStorage.getItem("name")).subscribe((data: any) => {
      this.resultList = data;
      for (let i = 0; i <= this.resultList.length; i++) {

      }

    })
  }

navigateHome(){

  if(sessionStorage.getItem("userId") !=null){
    this.router.navigateByUrl("/pages/userhome")
  }
  else{
    this.router.navigateByUrl("/home");
  }
}

}
