import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ExportFinance, FinanceReportMaster } from '../AllModels';
import { filterFinanceWiseDataURL, getActiveFinanceURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-financewisereports',
  templateUrl: './financewisereports.component.html',
  styleUrls: ['./financewisereports.component.css']
})
export class FinancewisereportsComponent implements OnInit {
  financeList: any[];
  finanwisereportsForm:FormGroup;
  FinanceReportMasterModel = new FinanceReportMaster();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'Agreement No', 'financeName', 'branch','customerFullName','residenceAddress', 'make', 'regdNum', 'chasisNum', 'armNameAndNo', 'rrmNameANdNo', 'rbmNameAndNo', 'zrmNameAndNo' ];
  dataSource = new MatTableDataSource<FinanceReportMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef
  ngOnInit() {
    this.finanwisereportsForm = this.formBuilder.group({
      financename: new FormControl(''),
      status:new FormControl('')
    })
    this.getActiveFinanceMaster();
  }

  getActiveFinanceMaster() { 
    this.httpService.getRequest(getActiveFinanceURL + "/" + sessionStorage.getItem("userId")).subscribe((data: any) => { 
      this.financeList = data;
    })
  }

  searchData()
  {
    this.spinnerService.show();
    this.FinanceReportMasterModel.compId = Number(sessionStorage.getItem("compId"));
        this.httpService.postRequest(filterFinanceWiseDataURL , this.FinanceReportMasterModel).subscribe((data: any) => {
          if (data.length != 0) {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.spinnerService.hide();
          }
          else {
            this.toastr.errorToastr("No Records Found.", "Error");
          }
        })
  }

  ///////////////////////For Search ////////////////////
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Finance Wise Report.xlsx');
  }

  ClearAllData(FinanceReportMasterModel){
    if(FinanceReportMasterModel.financeId == null || FinanceReportMasterModel.status){
      this.dataSource = null;
    }
  }
}
