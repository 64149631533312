import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator, ThemePalette } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LineMaster, UserMaster } from '../AllModels';
import { addUserMasterURL, updateUserMasterURL, getByIdUserMasterURL, getAllUserMasterURL, checkMobileNumberURL, addLineMasterURL, getAllLineMasterURL, getByIdLineMasterURL, updateLineMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
  import * as moment from 'moment';
import { formatDate } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { concat } from 'rxjs';


@Component({
  selector: 'app-linemaster',
  templateUrl: './linemaster.component.html',
  styleUrls: ['./linemaster.component.css']
})
export class LinemasterComponent implements OnInit {

  LineMasterForm: FormGroup;
  LineMasterModel = new LineMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Line Name', 'StartTime', 'EndTime', 'Date', 'Status', 'Action'];
  dataSource = new MatTableDataSource<LineMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  @ViewChild('picker', { static: false }) picker: any;
  @ViewChild('picker1', { static: false }) picker1: any;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateControl = new FormControl(new Date());

  public date1: moment.Moment;
  public disabled1 = false;
  public showSpinners1 = true;
  public showSeconds1 = false;
  public touchUi1 = false;
  public enableMeridian1 = false;
  public minDate1: moment.Moment;
  public maxDate1: moment.Moment;
  public stepHour1 = 1;
  public stepMinute1 = 1;
  public stepSecond1 = 1;
  public color1: ThemePalette = 'primary';
  public dateControl1 = new FormControl(new Date());
  
  ngOnInit() {
      this.LineMasterForm = this.formBuilder.group({
        Linename: new FormControl('', [Validators.required,]),
        date: new FormControl('', [Validators.required]),
        status: new FormControl('', [Validators.required]),
      })
    this.getAllUser();
    this.hide = true;
  }

  checkboxChanged(event) {
   
    }
  SaveLine() {
    if (this.isEdit) {
      this.LineMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
      this.httpService.postRequest(addLineMasterURL, this.LineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.LineMasterForm.reset();
          this.LineMasterModel = new LineMaster();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.LineMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
      this.httpService.putRequest(updateLineMasterURL, this.LineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.LineMasterForm.reset();
          this.LineMasterModel = new LineMaster();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
   
    }
  }
  temp1: number = 0;
  temp2: number = 0;
  midno:string;
  
  calculateopendigit() {
    this.temp2 = this.LineMasterModel.lineOpenNo;
    while (this.temp2) {
      this.temp1 += this.temp2 % 10
      this.temp2 = Math.floor(this.temp2 / 10);
    }
    if(this.temp1 >= 10)
    {
      this.temp1= this.temp1%10;
    }
    this.midno = String(this.temp1);
    this.LineMasterModel.lineMidNo= this.midno;
  }
  temp3: number = 0;
  temp4: number = 0;
  calculateclosedigit()
  {
    this.temp3 = this.LineMasterModel.lineCloseNo;
    while (this.temp3) {
      this.temp2 += this.temp3 % 10
      this.temp3 = Math.floor(this.temp3 / 10);
    }

    if(this.temp2 >= 10)
    {
      this.temp2= this.temp2%10;
    }

    this.midno =this.midno + this.temp2;
    this.LineMasterModel.lineMidNo= this.midno;

  }

  getLinemasterById(lineId) {
    this.httpService.getRequest(getByIdLineMasterURL + "/" + lineId).subscribe((data: any) => {
      this.LineMasterModel = data;
      this.LineMasterModel.lineStartTime = new Date(data.lineStartTime);
      this.LineMasterModel.lineEndTime = new Date(data.lineEndTime);
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }

  getAllUser() {
  
    this.httpService.getRequest(getAllLineMasterURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  checkMobileNumber(userMob) {
    this.httpService.getRequest(checkMobileNumberURL + "/" + userMob).subscribe((data: any) => {
      if (data == true) {
        this.mobileMsg = "Valid Mobile No ";
        this.textColorMobile = "green";
        this.mobFlag = true;
      }
      else {
        this.mobileMsg = "Mobile Number Already Exist";
        this.textColorMobile = "Red";
        this.mobFlag = false;
      }
    })
  }

   ///////////////////////For Search ////////////////////
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
