import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import { getByIdUserMasterURL } from '../AllURL';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserprofileComponent implements OnInit {
  userList:any[]=[];

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router: Router) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.getUserDetails();
  }

  getUserDetails() {
    this.httpService.getRequest(getByIdUserMasterURL + "/" + sessionStorage.getItem("userId")).subscribe((data: any) => {
      this.userList.push(data);
    })
  }

}
