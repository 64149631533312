import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
// import { Console } from 'console';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentReportMaster, UserWinningReportMaster } from '../AllModels';
import { getActiveAgentURL, getAgentWiseUserWinningListURL, getTransactionReportURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-agentwiseuserwinningreport',
  templateUrl: './agentwiseuserwinningreport.component.html',
  styleUrls: ['./agentwiseuserwinningreport.component.css']
})
export class AgentwiseuserwinningreportComponent implements OnInit {
  roleId:number;
  roleFlag:boolean=false;
  role1Flag:boolean=false;
  UserWinningReportForm: FormGroup;
  UserWinningReportMasterModel = new UserWinningReportMaster();
  ActiveAgentList = Array<UserWinningReportMaster>();
  totalAmount : number = 0;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog,private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'User Name','Agent Name','Agent No','Line', 'Points', 'Date','Status'];
  dataSource = new MatTableDataSource<UserWinningReportMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.UserWinningReportForm = this.formBuilder.group({
      status: new FormControl(''),
      date: new FormControl('', [Validators.required,]),
      toDate: new FormControl('', [Validators.required,]),
      agentId: new FormControl(''),

    })

    this.roleId=Number(sessionStorage.getItem("roleId"));

    if(this.roleId==1)
    {
      this.roleFlag=true;
      this.role1Flag=false;
    }
    else{
      this.roleFlag=false;
      this.role1Flag=true;
    }
  
    this.getActiveAgent();
  }

  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }
  
  getTransactionreport()
  {
    this.spinnerService.show();
    this.httpService.postRequest(getAgentWiseUserWinningListURL,this.UserWinningReportMasterModel).subscribe((data: any) => {
      console.log(JSON.stringify(data))
      if (data) {

        let a=0;
        for(let i=0;i<data.length;i++){
            a=a+data[i].winPoints;
          this.totalAmount = Number(Math.fround(a).toFixed(2));
        }

        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
        this.spinnerService.hide();
      }
    })
  }

  getAgentreport()
  {
    this.spinnerService.show();
    this.UserWinningReportMasterModel.agentId=Number(sessionStorage.getItem("agentId"));
    this.httpService.postRequest(getAgentWiseUserWinningListURL,this.UserWinningReportMasterModel).subscribe((data: any) => {
      if (data) {
        let a=0;
        for(let i=0;i<data.length;i++){
            a=a+data[i].winPoints;
          this.totalAmount = Number(Math.fround(a).toFixed(2));
        }
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
        this.spinnerService.hide();
      }
    })
  }

  
exportToExcel() {
  const ws: xlsx.WorkSheet =
  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
  const wb: xlsx.WorkBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, 'Agent Report.xlsx');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
