import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator, ThemePalette } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LineMaster, ShreeStarLineMaster } from '../AllModels';
import { addLineMasterURL, updateLineMasterURL, getByIdLineMasterURL, getAllLineMasterURL, checkMobileNumberURL, addShreeStarLineMasterURL, updateShreeStarLineMasterURL, getAllShreeStarLineMasterURL, getByIdShreeStarLineMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as moment from 'moment';


@Component({
  selector: 'app-shreestarlinemaster',
  templateUrl: './shreestarlinemaster.component.html',
  styleUrls: ['./shreestarlinemaster.component.css']
})
export class ShreestarlinemasterComponent implements OnInit {

  ShreeStarLineMasterForm: FormGroup;
  ShreeStarLineMasterModel = new ShreeStarLineMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'StartTime', 'EndTime', 'Date', 'Status', 'Action'];
  dataSource = new MatTableDataSource<ShreeStarLineMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  @ViewChild('picker', { static: false }) picker: any;
  @ViewChild('picker1', { static: false }) picker1: any;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateControl = new FormControl(new Date());

  public date1: moment.Moment;
  public disabled1 = false;
  public showSpinners1 = true;
  public showSeconds1 = false;
  public touchUi1 = false;
  public enableMeridian1 = false;
  public minDate1: moment.Moment;
  public maxDate1: moment.Moment;
  public stepHour1 = 1;
  public stepMinute1 = 1;
  public stepSecond1 = 1;
  public color1: ThemePalette = 'primary';
  public dateControl1 = new FormControl(new Date());
  
  ngOnInit() {
    this.ShreeStarLineMasterForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required,]),
      startTime: new FormControl('', [Validators.required,]),
      endTime: new FormControl('', [Validators.required,]),
      openPannaDigit: new FormControl('', [Validators.required]),
      closePannaDigit: new FormControl('', [Validators.required]),
      jodiDigit: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),


    })
    this.getAllUser();
    this.hide = true;
  }

  checkboxChanged(event) {

    }
  SaveLine() {
    if (this.isEdit) {
   
      this.httpService.postRequest(addShreeStarLineMasterURL, this.ShreeStarLineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.ShreeStarLineMasterForm.reset();
          this.ShreeStarLineMasterModel = new ShreeStarLineMaster();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateShreeStarLineMasterURL, this.ShreeStarLineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.ShreeStarLineMasterForm.reset();
          this.ShreeStarLineMasterModel = new ShreeStarLineMaster();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
  
    }
  }
  temp1: number = 0;
  temp2: number = 0;
  midno:string;
  
  calculateopendigit() {
    this.temp2 = this.ShreeStarLineMasterModel.openPannaDigit;
    while (this.temp2) {
      this.temp1 += this.temp2 % 10
      this.temp2 = Math.floor(this.temp2 / 10);
    }
    if(this.temp1 >= 10)
    {
      this.temp1= this.temp1%10;
    }
    this.midno = String(this.temp1);
    this.ShreeStarLineMasterModel.jodiDigit= this.midno;
  }
  temp3: number = 0;
  temp4: number = 0;
  calculateclosedigit()
  {
    this.temp3 = this.ShreeStarLineMasterModel.closePannaDigit;
    while (this.temp3) {
      this.temp2 += this.temp3 % 10
      this.temp3 = Math.floor(this.temp3 / 10);
    }

    if(this.temp2 >= 10)
    {
      this.temp2= this.temp2%10;
    }

    this.midno =this.midno + this.temp2;
    this.ShreeStarLineMasterModel.jodiDigit= this.midno;

  }

  getLinemasterById(shreeStarLineId) {
    this.httpService.getRequest(getByIdShreeStarLineMasterURL + "/" + shreeStarLineId).subscribe((data: any) => {
      this.ShreeStarLineMasterModel = data;
      this.ShreeStarLineMasterModel.startTime = new Date(data.startTime);
      this.ShreeStarLineMasterModel.endTime = new Date(data.endTime);
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllUser() {
    this.httpService.getRequest(getAllShreeStarLineMasterURL).subscribe((data: any) => {
      if (data) {
      
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  checkMobileNumber(userMob) {
    this.httpService.getRequest(checkMobileNumberURL + "/" + userMob).subscribe((data: any) => {
      if (data == true) {
        this.mobileMsg = "Valid Mobile No ";
        this.textColorMobile = "green";
        this.mobFlag = true;
      }
      else {
        this.mobileMsg = "Mobile Number Already Exist";
        this.textColorMobile = "Red";
        this.mobFlag = false;
      }
    })
  }

   ///////////////////////For Search ////////////////////
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
