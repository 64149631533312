import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LineHistoryMaster, profitloss, GetGameRateMaster } from '../AllModels';
import { getLineWiseBidURL, getRateByGameNameURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';


@Component({
  selector: 'app-bidwiselinehistory',
  templateUrl: './bidwiselinehistory.component.html',
  styleUrls: ['./bidwiselinehistory.component.css']
})
export class BidwiselinehistoryComponent implements OnInit {
  gameName: string = "";
  // i:number=0;
  totalBidOpen: number = 0;
  totalBidClose: number = 0;
  bidList = new Array<profitloss>();
  bidList1 = new Array<profitloss>();
  bidList2 = new Array<profitloss>();
  list4 = new Array<profitloss>();
  list5 = new Array<profitloss>();
  list6 = new Array<profitloss>();
  getGameRateModel = new GetGameRateMaster();
  listNum = new Array<number>();
  profitLoss = new profitloss();
  profitLoss1 = new profitloss();
  rate: number;
  opentableFlag: boolean = true;
  temp: any = {};

  LineHistoryMasterModel = new LineHistoryMaster();
  pagearray: number[];
  textColorLoss: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router: Router) {
  }
  displayedColumns: string[] = ['Sr.No.', 'Number', 'BID Date', 'Points', 'Session', 'Profit', 'Loss'];
  dataSource = new MatTableDataSource();
  displayedColumns1: string[] = ['Sr.No.', 'Number', 'BID Date', 'Points', 'Session', 'Profit', 'Loss'];
  dataSource1 = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  @ViewChild('epltable1', { static: false }) epltable1: ElementRef;


  ngOnInit() {
    this.showbid1();

    setTimeout(() => {
      location.reload();
    }, 60000);

  }

  showbid1() {
    this.LineHistoryMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.LineHistoryMasterModel.lineId = Number(sessionStorage.getItem("lineId"));
    this.LineHistoryMasterModel.lineName = sessionStorage.getItem("lineName");
    this.LineHistoryMasterModel.gameName = sessionStorage.getItem("gameName");


    if (this.LineHistoryMasterModel.gameName == "Single Digit") {
      this.pagearray = [20, 50, 100, 150];
    }
    if (this.LineHistoryMasterModel.gameName == "Jodi") {
      this.pagearray = [100, 200, 300, 400];
    }
    if (this.LineHistoryMasterModel.gameName == "Single Patti") {
      this.pagearray = [150, 250, 350, 450];
    }
    if (this.LineHistoryMasterModel.gameName == "Double Patti") {
      this.pagearray = [100, 200, 300, 400];
    }
    if (this.LineHistoryMasterModel.gameName == "Triple Patti") {
      this.pagearray = [100, 200, 300, 400];
    }
    if (this.LineHistoryMasterModel.gameName == "Half Sangam") {
      this.pagearray = [100, 200, 300, 400];
    }
    if (this.LineHistoryMasterModel.gameName == "Full Sangam") {
      this.pagearray = [100, 200, 300, 400];
    }


    this.getGameRateModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.getGameRateModel.gameName = sessionStorage.getItem("gameName");
    this.httpService.postRequest(getRateByGameNameURL, this.getGameRateModel).subscribe((data: any) => {
      this.rate = data;

    })
    this.httpService.postRequest(getLineWiseBidURL, this.LineHistoryMasterModel).subscribe((data: any) => {
      if (data) {
        this.bidList = data;

        for (let j = 0; j < this.bidList.length; j++) {
          if (this.bidList[j].session == "OPEN") {
            this.totalBidOpen = this.totalBidOpen + this.bidList[j].totalAmount;

          }
          if (this.bidList[j].session == "CLOSE") {
            this.totalBidClose = this.totalBidClose + this.bidList[j].totalAmount;

          }
        }


        if (sessionStorage.getItem("gameName") == "Half Sangam") {
          for (let j = 0; j < this.bidList.length; j++) {
            if (this.bidList[j].session == "OPEN") {

              this.profitLoss.value = this.bidList[j].value;
              this.profitLoss.countValue = this.bidList[j].countValue;
              this.profitLoss.totalAmount = this.bidList[j].totalAmount;
              this.profitLoss.session = this.bidList[j].session;
              this.profitLoss.profit = this.totalBidOpen;
              this.profitLoss.loss = 0;

              this.bidList1.push(this.profitLoss);
              this.profitLoss = new profitloss();

            }
            if (this.bidList[j].session == "CLOSE") {

              this.profitLoss1.value = this.bidList[j].value;
              this.profitLoss1.countValue = this.bidList[j].countValue;
              this.profitLoss1.totalAmount = this.bidList[j].totalAmount;
              this.profitLoss1.session = this.bidList[j].session;
              this.profitLoss1.profit = this.totalBidClose;
              this.profitLoss1.loss = 0;

              this.bidList2.push(this.profitLoss1);
              this.profitLoss1 = new profitloss();

            }
          }


          this.dataSource = new MatTableDataSource(this.bidList1);
          this.dataSource1 = new MatTableDataSource(this.bidList2);
        }

        if (sessionStorage.getItem("gameName") == "Full Sangam") {
          for (let j = 0; j < this.bidList.length; j++) {
            if (this.bidList[j].session == "OPEN") {

              this.profitLoss.value = this.bidList[j].value;
              this.profitLoss.countValue = this.bidList[j].countValue;
              this.profitLoss.totalAmount = this.bidList[j].totalAmount;
              this.profitLoss.session = this.bidList[j].session;
              this.profitLoss.profit = this.totalBidOpen;
              this.profitLoss.loss = 0;

              this.bidList1.push(this.profitLoss);
              this.profitLoss = new profitloss();

            }
            if (this.bidList[j].session == "CLOSE") {

              this.profitLoss1.value = this.bidList[j].value;
              this.profitLoss1.countValue = this.bidList[j].countValue;
              this.profitLoss1.totalAmount = this.bidList[j].totalAmount;
              this.profitLoss1.session = this.bidList[j].session;
              this.profitLoss1.profit = this.totalBidClose;
              this.profitLoss1.loss = 0;

              this.bidList2.push(this.profitLoss1);
              this.profitLoss1 = new profitloss();

            }
          }


          this.dataSource = new MatTableDataSource(this.bidList1);
          this.dataSource1 = new MatTableDataSource(this.bidList2);
        }

        if (sessionStorage.getItem("gameName") == "Single Digit" || sessionStorage.getItem("gameName") == "Jodi") {


          if (sessionStorage.getItem("gameName") == "Single Digit") {
            var length = 9;
          }

          if (sessionStorage.getItem("gameName") == "Jodi") {

            var length = 99;
            var jodiFlag = true;
            this.opentableFlag = false;
          }

          for (let i = 0; i <= length; ++i) {


            this.profitLoss.countValue = 0;
            this.profitLoss.totalAmount = 0;

            this.profitLoss.session = "OPEN";
            this.profitLoss.profit = this.totalBidOpen;
            this.profitLoss.loss = 0;
            if (jodiFlag && String(i).length == 1) {

              this.profitLoss.value = (0 + String(i));

            }
            else {
              this.profitLoss.value = String(i);
            }
            this.bidList1.push(this.profitLoss);
            this.profitLoss = new profitloss;



          }

          for (let i = 0; i <= length; ++i) {


            this.profitLoss1.countValue = 0;
            this.profitLoss1.totalAmount = 0;

            this.profitLoss1.session = "CLOSE";
            this.profitLoss1.profit = this.totalBidClose;
            this.profitLoss1.loss = 0;

            if (jodiFlag && String(i).length == 1) {
              this.profitLoss1.value = (0 + String(i));

            }
            else {
              this.profitLoss1.value = String(i);
            }
            this.bidList2.push(this.profitLoss1);
            this.profitLoss1 = new profitloss();


          }



          for (let j = 0; j < this.bidList.length; j++) {

            if (Number(this.bidList1[Number(this.bidList[j].value)].value) == Number(this.bidList[j].value) && this.bidList[j].session == "OPEN") {


              this.bidList1[this.bidList[j].value] = this.bidList[j];
              if (this.totalBidOpen >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList1[this.bidList[j].value].profit = this.totalBidOpen - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList1[this.bidList[j].value].loss = 0;

              }
              else {
                this.bidList1[this.bidList[j].value].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidOpen;
                this.bidList1[this.bidList[j].value].profit = 0;

              }

            }

            if (Number(this.bidList2[Number(this.bidList[j].value)].value) == Number(this.bidList[j].value) && this.bidList[j].session == "CLOSE") {

              this.bidList2[Number(this.bidList[j].value)] = this.bidList[j];
              if (this.totalBidClose >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList2[Number(this.bidList[j].value)].profit = this.totalBidClose - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList2[Number(this.bidList[j].value)].loss = 0;

              }
              else {
                this.bidList2[Number(this.bidList[j].value)].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidClose;
                this.bidList2[Number(this.bidList[j].value)].profit = 0;

              }

            }
          }
        }
        if (sessionStorage.getItem("gameName") == "Single Patti") {

          this.singalPannaOpen();
          this.singalPannaClose();
          this.bidList1 = this.list4;
          this.bidList2 = this.list5;

          for (let a = 0; a < this.bidList.length; a++) {
            for (let b = 0; b < this.list4.length; b++) {
              if (this.list4[b].value == this.bidList[a].value) {


                this.bidList[a].no = b;
              }
            }
          }
          for (let j = 0; j < this.bidList.length; j++) {

            if (Number(this.bidList1[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "OPEN") {


              this.bidList1[this.bidList[j].no] = this.bidList[j];
              if (this.totalBidOpen >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList1[this.bidList[j].no].profit = this.totalBidOpen - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList1[this.bidList[j].no].loss = 0;

              }
              else {
                this.bidList1[this.bidList[j].no].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidOpen;
                this.bidList1[this.bidList[j].no].profit = 0;


              }

            }

            if (Number(this.bidList2[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "CLOSE") {


              this.bidList2[Number(this.bidList[j].no)] = this.bidList[j];
              if (this.totalBidClose >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList2[Number(this.bidList[j].no)].profit = this.totalBidClose - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList2[Number(this.bidList[j].no)].loss = 0;

              }
              else {
                this.bidList2[Number(this.bidList[j].no)].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidClose;
                this.bidList2[Number(this.bidList[j].no)].profit = 0;

              }

            }
          }

        }

        if (sessionStorage.getItem("gameName") == "Double Patti") {

          this.doublePannOpen();
          this.doublePannClose();
          this.bidList1 = this.list4;
          this.bidList2 = this.list5;


          for (let a = 0; a < this.bidList.length; a++) {
            for (let b = 0; b < this.list4.length; b++) {
              if (this.list4[b].value == this.bidList[a].value) {

                this.bidList[a].no = b;
              }
            }
          }

          for (let j = 0; j < this.bidList.length; j++) {

            if (Number(this.bidList1[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "OPEN") {

              this.bidList1[this.bidList[j].no] = this.bidList[j];
              if (this.totalBidOpen >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList1[this.bidList[j].no].profit = this.totalBidOpen - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList1[this.bidList[j].no].loss = 0;

              }
              else {
                this.bidList1[this.bidList[j].no].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidOpen;
                this.bidList1[this.bidList[j].no].profit = 0;

              }

            }

            if (Number(this.bidList2[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "CLOSE") {

              this.bidList2[Number(this.bidList[j].no)] = this.bidList[j];
              if (this.totalBidClose >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList2[Number(this.bidList[j].no)].profit = this.totalBidClose - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList2[Number(this.bidList[j].no)].loss = 0;

              }
              else {
                this.bidList2[Number(this.bidList[j].no)].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidClose;
                this.bidList2[Number(this.bidList[j].no)].profit = 0;

              }

            }
          }

        }

        if (sessionStorage.getItem("gameName") == "SP Motor") {

          this.singalPannaOpen();
          this.singalPannaClose();
          this.bidList1 = this.list4;
          this.bidList2 = this.list5;

          for (let a = 0; a < this.bidList.length; a++) {
            for (let b = 0; b < this.list4.length; b++) {
              if (this.list4[b].value == this.bidList[a].value) {


                this.bidList[a].no = b;
              }
            }
          }
          for (let j = 0; j < this.bidList.length; j++) {

            if (Number(this.bidList1[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "OPEN") {



              this.bidList1[this.bidList[j].no] = this.bidList[j];
              if (this.totalBidOpen >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList1[this.bidList[j].no].profit = this.totalBidOpen - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList1[this.bidList[j].no].loss = 0;

              }
              else {
                this.bidList1[this.bidList[j].no].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidOpen;
                this.bidList1[this.bidList[j].no].profit = 0;


              }

            }

            if (Number(this.bidList2[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "CLOSE") {

              this.bidList2[Number(this.bidList[j].no)] = this.bidList[j];
              if (this.totalBidClose >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList2[Number(this.bidList[j].no)].profit = this.totalBidClose - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList2[Number(this.bidList[j].no)].loss = 0;

              }
              else {
                this.bidList2[Number(this.bidList[j].no)].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidClose;
                this.bidList2[Number(this.bidList[j].no)].profit = 0;

              }

            }
          }

        }

        if (sessionStorage.getItem("gameName") == "DP Motor") {

          this.doublePannOpen();
          this.doublePannClose();
          this.bidList1 = this.list4;
          this.bidList2 = this.list5;


          for (let a = 0; a < this.bidList.length; a++) {
            for (let b = 0; b < this.list4.length; b++) {
              if (this.list4[b].value == this.bidList[a].value) {

                this.bidList[a].no = b;
              }
            }
          }

          for (let j = 0; j < this.bidList.length; j++) {

            if (Number(this.bidList1[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "OPEN") {



              this.bidList1[this.bidList[j].no] = this.bidList[j];
              if (this.totalBidOpen >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList1[this.bidList[j].no].profit = this.totalBidOpen - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList1[this.bidList[j].no].loss = 0;

              }
              else {
                this.bidList1[this.bidList[j].no].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidOpen;
                this.bidList1[this.bidList[j].no].profit = 0;

              }

            }

            if (Number(this.bidList2[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "CLOSE") {

              this.bidList2[Number(this.bidList[j].no)] = this.bidList[j];
              if (this.totalBidClose >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList2[Number(this.bidList[j].no)].profit = this.totalBidClose - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList2[Number(this.bidList[j].no)].loss = 0;

              }
              else {
                this.bidList2[Number(this.bidList[j].no)].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidClose;
                this.bidList2[Number(this.bidList[j].no)].profit = 0;

              }

            }
          }

        }

        if (sessionStorage.getItem("gameName") == "Triple Patti") {

          this.tripplePannOpen();
          this.tripplePannClose();
          this.bidList1 = this.list4;
          this.bidList2 = this.list5;

          for (let a = 0; a < this.bidList.length; a++) {
            for (let b = 0; b < this.list4.length; b++) {
              if (this.list4[b].value == this.bidList[a].value) {


                this.bidList[a].no = b;
              }
            }
          }

          for (let j = 0; j < this.bidList.length; j++) {
            if (Number(this.bidList1[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "OPEN") {

              this.bidList1[this.bidList[j].no] = this.bidList[j];
              if (this.totalBidOpen >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList1[this.bidList[j].no].profit = this.totalBidOpen - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList1[this.bidList[j].no].loss = 0;

              }
              else {
                this.bidList1[this.bidList[j].no].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidOpen;
                this.bidList1[this.bidList[j].no].profit = 0;

              }

            }

            if (Number(this.bidList2[this.bidList[j].no].value) == Number(this.bidList[j].value) && this.bidList[j].session == "CLOSE") {

              this.bidList2[Number(this.bidList[j].no)] = this.bidList[j];
              if (this.totalBidClose >= ((this.rate / 10) * this.bidList[j].totalAmount)) {
                this.bidList2[Number(this.bidList[j].no)].profit = this.totalBidClose - ((this.rate / 10) * this.bidList[j].totalAmount);
                this.bidList2[Number(this.bidList[j].no)].loss = 0;

              }
              else {
                this.bidList2[Number(this.bidList[j].no)].loss = ((this.rate / 10) * this.bidList[j].totalAmount) - this.totalBidClose;
                this.bidList2[Number(this.bidList[j].no)].profit = 0;

              }

            }
          }
        }


        this.bidList1 = this.bidList1.sort(
          (low, high) => +high.totalAmount - +low.totalAmount
        );

        this.bidList2 = this.bidList2.sort(
          (low, high) => +high.totalAmount - +low.totalAmount
        );




        this.dataSource = new MatTableDataSource(this.bidList1);

        this.dataSource1 = new MatTableDataSource(this.bidList2);

        this.dataSource.paginator = this.paginator;
        this.dataSource1.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
      }
    })
  }

  singalPannaOpen() {



    var init = 123;
    var num = 123;
    var limit = 120;
    var lastlimit = 8;
    var iteration1 = 8;
    var lastlimitincre = 30;
    var modvalue = 10;
    do {


      for (let z = 0, iteration = iteration1; z < 8; z++, iteration--) {

        for (let i = 1, j = num, k = limit; i <= iteration; i++, j++) {

          if (j % 10 == 0) {


            this.profitLoss.value = String(k);
            this.profitLoss.countValue = 0;
            this.profitLoss.totalAmount = 0;

            this.profitLoss.session = "OPEN";
            this.profitLoss.profit = this.totalBidOpen;
            this.profitLoss.loss = 0;
            this.list4.push(this.profitLoss);
            this.profitLoss = new profitloss();

            limit = limit + modvalue;
       
          } else {

            this.profitLoss.value = String(j);
            this.profitLoss.countValue = 0;
            this.profitLoss.totalAmount = 0;

            this.profitLoss.session = "OPEN";
            this.profitLoss.profit = this.totalBidOpen;
            this.profitLoss.loss = 0;
            this.list4.push(this.profitLoss);
            this.profitLoss = new profitloss();
          
          }
        }
        num = num + 11;
      }
      lastlimit--;
      init = init + 111;
      num = init;
      iteration1 = iteration1 - 1;
     
      limit = limit + lastlimitincre;
      lastlimitincre = lastlimitincre + 10;
    
    } while (lastlimit != 0);

    for (let i = 0; i < this.list4.length; i++) {
      this.list4[i].no = i;
    }

  }

  singalPannaClose() {



    var init = 123;
    var num = 123;
    var limit = 120;
    var lastlimit = 8;
    var iteration1 = 8;
    var lastlimitincre = 30;
    var modvalue = 10;
    do {


      for (let z = 0, iteration = iteration1; z < 8; z++, iteration--) {

        for (let i = 1, j = num, k = limit; i <= iteration; i++, j++) {

          if (j % 10 == 0) {


            this.profitLoss.value = String(k);
            this.profitLoss.countValue = 0;
            this.profitLoss.totalAmount = 0;

            this.profitLoss.session = "CLOSE";
            this.profitLoss.profit = this.totalBidClose;
            this.profitLoss.loss = 0;
            this.list5.push(this.profitLoss);
            this.profitLoss = new profitloss();

            limit = limit + modvalue;
         
          } else {

            this.profitLoss.value = String(j);
            this.profitLoss.countValue = 0;
            this.profitLoss.totalAmount = 0;

            this.profitLoss.session = "CLOSE";
            this.profitLoss.profit = this.totalBidClose;
            this.profitLoss.loss = 0;
            this.list5.push(this.profitLoss);
            this.profitLoss = new profitloss();
       
          }
        }
        num = num + 11;
      }
      lastlimit--;
      init = init + 111;
      num = init;
      iteration1 = iteration1 - 1;
      limit = limit + lastlimitincre;
      lastlimitincre = lastlimitincre + 10;
    } while (lastlimit != 0);

    for (let i = 0; i < this.list5.length; i++) {
      this.list5[i].no = i;
    }

  }


  tripplePannOpen() {
    var no = '000';
    var no1 = 111;
    for (let c = 0; c < 10; c++) {

      this.profitLoss.value = String(no);

      this.profitLoss.countValue = 0;
      this.profitLoss.totalAmount = 0;

      this.profitLoss.session = "OPEN";
      this.profitLoss.profit = this.totalBidOpen;
      this.profitLoss.loss = 0;
      this.profitLoss.no = c;
      this.list4.push(this.profitLoss);
      this.profitLoss = new profitloss();


      if (no == '000') {

        no = String(no1);
      }
      else {

        no1 = no1 + 111;
        no = String(no1);

      }

    }
  }
  tripplePannClose() {
    var no = '000';
    var no1 = 111;
    for (let c = 0; c < 10; c++) {
      this.profitLoss1.value = String(no);
      this.profitLoss1.countValue = 0;
      this.profitLoss1.totalAmount = 0;
      this.profitLoss1.session = "CLOSE";
      this.profitLoss1.profit = this.totalBidClose;
      this.profitLoss1.loss = 0;
      this.profitLoss1.no = c;
      this.list5.push(this.profitLoss1);
      this.profitLoss1 = new profitloss();


      if (no == '000') {

        no = String(no1);
      }
      else {
        no1 = no1 + 111;
        no = String(no1);

      }

    }
  }
  doublePannOpen() {
    var init1 = 112;
    var init22 = 112;
    var incr = 0;
    var n1 = 9;
    var n = 9;
    var iter = 9;

    do {
      init22 = init1;

      for (let i = 1; i <= iter; i++) {

        if (i == iter) {
          init22 = init22 - 10;
          this.profitLoss.value = String(init22);

          console.log("profitLoss value list5 " + JSON.stringify(init22));
          this.profitLoss.countValue = 0;
          this.profitLoss.totalAmount = 0;

          this.profitLoss.session = "OPEN";
          this.profitLoss.profit = this.totalBidOpen;
          this.profitLoss.loss = 0;
          this.list4.push(this.profitLoss);
          this.profitLoss = new profitloss();


        }
        else {

          this.profitLoss.value = String(init22);
          this.profitLoss.countValue = 0;
          this.profitLoss.totalAmount = 0;

          this.profitLoss.session = "OPEN";
          this.profitLoss.profit = this.totalBidOpen;
          this.profitLoss.loss = 0;
          this.list4.push(this.profitLoss);
          console.log("profitLoss value list5 else" + JSON.stringify(init22));
          this.profitLoss = new profitloss();
          init22 = init22 + 1;
        }

      }
      init1 = init1 + 111;
      n--;
      iter = iter - 1;
    } while (n != 0);

    var initsecond = 0;
    var initsecond1 = 122;
    var iter1 = 9;
    do {
      initsecond = initsecond1;
      for (let z = 1; z <= iter1; z++) {
        if (z == iter1) {
          initsecond = initsecond - 110;

          this.profitLoss.value = String(initsecond);
          this.profitLoss.countValue = 0;
          this.profitLoss.totalAmount = 0;

          this.profitLoss.session = "OPEN";
          this.profitLoss.profit = this.totalBidOpen;
          this.profitLoss.loss = 0;
          this.list4.push(this.profitLoss);
          this.profitLoss = new profitloss();


        } else {
          ;


          this.profitLoss.value = String(initsecond);
          this.profitLoss.countValue = 0;
          this.profitLoss.totalAmount = 0;

          this.profitLoss.session = "OPEN";
          this.profitLoss.profit = this.totalBidOpen;
          this.profitLoss.loss = 0;
          this.list4.push(this.profitLoss);
          this.profitLoss = new profitloss();
          initsecond = initsecond + 11;

        }

      }

      initsecond1 = initsecond1 + 111;
      n1--;
      iter1 = iter1 - 1;

    } while (n1 != 0);

    for (let i = 0; i < this.list4.length; i++) {
      this.list4[i].no = i;

    }


    for (let i = 0; i < this.list4.length; i++) {
      console.log("final list5 " + JSON.stringify(this.list4));
    }


  }
  doublePannClose() {

    var init11 = 112;
    var init222 = 112;
    var incr = 0;
    var n1 = 9;
    var n = 9;
    var iter = 9;

    do {
      init222 = init11;

      for (let i = 1; i <= iter; i++) {

        if (i == iter) {
          init222 = init222 - 10;
          this.profitLoss1.value = String(init222);

          console.log("profitLoss value list55 " + JSON.stringify(init222));
          this.profitLoss1.countValue = 0;
          this.profitLoss1.totalAmount = 0;

          this.profitLoss1.session = "CLOSE";
          this.profitLoss1.profit = this.totalBidClose;
          this.profitLoss1.loss = 0;
          this.list5.push(this.profitLoss1);
          this.profitLoss1 = new profitloss();


        }
        else {

          this.profitLoss1.value = String(init222);
          this.profitLoss1.countValue = 0;
          this.profitLoss1.totalAmount = 0;

          this.profitLoss1.session = "CLOSE";
          this.profitLoss1.profit = this.totalBidClose;
          this.profitLoss1.loss = 0;
          this.list5.push(this.profitLoss1);
          console.log("profitLoss value list55 else" + JSON.stringify(init222));
          this.profitLoss1 = new profitloss();
          init222 = init222 + 1;
        }

      }
      init11 = init11 + 111;
      n--;
      iter = iter - 1;
    } while (n != 0);

    var initsecond = 0;
    var initsecond1 = 122;
    var iter1 = 9;
    do {
      initsecond = initsecond1;
      for (let z = 1; z <= iter1; z++) {
        if (z == iter1) {
          initsecond = initsecond - 110;

          this.profitLoss1.value = String(initsecond);
          this.profitLoss1.countValue = 0;
          this.profitLoss1.totalAmount = 0;

          this.profitLoss1.session = "CLOSE";
          this.profitLoss1.profit = this.totalBidClose;
          this.profitLoss1.loss = 0;
          this.list5.push(this.profitLoss1);
          this.profitLoss1 = new profitloss();

        } else {
        
          this.profitLoss1.value = String(initsecond);
          this.profitLoss1.countValue = 0;
          this.profitLoss1.totalAmount = 0;

          this.profitLoss1.session = "CLOSE";
          this.profitLoss1.profit = this.totalBidClose;
          this.profitLoss1.loss = 0;
          this.list5.push(this.profitLoss1);
          this.profitLoss1 = new profitloss();
          initsecond = initsecond + 11;

        }

      }

      initsecond1 = initsecond1 + 111;
      n1--;
      iter1 = iter1 - 1;

    } while (n1 != 0);

    for (let i = 0; i < this.list5.length; i++) {
      this.list5[i].no = i;

    }


  }
  exportToExcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'BidWiseLineHistoryOpen.xlsx');
  }

  exportToExcel1() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable1.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'BidWiseLineHistoryClose.xlsx');
  }


 

}


