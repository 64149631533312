import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator, ThemePalette } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { bannerMaster } from '../AllModels';
import * as moment from 'moment';
import { createBannerMasterURL, fileUpload, getAllBannerMasterURL, getBannerMasterURL, updateBannerMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  bannerForm: FormGroup;
  bannerMasterModel = new bannerMaster();
  isEdit: boolean = true;
  hide = true;
  filetoUpload: File = null;
  url='';

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Path', 'type', 'title', 'exdate', 'Status', 'Action'];
  dataSource = new MatTableDataSource<bannerMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateControl = new FormControl(new Date());

  ngOnInit() {
    this.bannerForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
      path: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
    })
    this.getAllBanner();
  }
  SaveBanner() {
    if (this.isEdit) {
     
        this.httpService.postRequest(createBannerMasterURL, this.bannerMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllBanner();
            this.bannerForm.reset();
             this.bannerMasterModel.path=null;
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
    
      this.httpService.putRequest(updateBannerMasterURL, this.bannerMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllBanner();
          this.bannerForm.reset();
          this.bannerMasterModel.path=null;
          this.bannerMasterModel = new bannerMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getBannerById(bannerId) {
    this.httpService.getRequest(getBannerMasterURL + "/" + bannerId).subscribe((data: any) => {
      this.bannerMasterModel = data;
      this.bannerMasterModel.expiryDate = new Date(data.expiryDate);
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }
  getAllBanner() {
    this.httpService.getRequest(getAllBannerMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.bannerMasterModel.path = data.path;
      }
      else {
        this.toastr.errorToastr(data.path, 'error!', { timeout: 500 });
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
