import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ColorGameBidMaster, colorProfitloss, GetColorGameRateMaster, GetGameRateMaster, profitloss } from '../AllModels';
import { colorBidMasterURL, getGameRateURL, getRateByGameNameURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-colorlinebidhistory',
  templateUrl: './colorlinebidhistory.component.html',
  styleUrls: ['./colorlinebidhistory.component.css']
})
export class ColorlinebidhistoryComponent implements OnInit {

  ColorLineBidModel = new ColorGameBidMaster();
  bidList = new Array<colorProfitloss>();
  getGameRateModel = new GetColorGameRateMaster();
  profitLoss = new colorProfitloss();
  bidList1 = new Array<colorProfitloss>();

  pagearray: number[];
  rate: number;
  totalBidOpen: number = 0;
  totalBidClose: number = 0;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router: Router) {
  }
  displayedColumns: string[] = ['Sr.No.', 'Number', 'BID Date', 'Points', 'Profit', 'Loss'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.showbid1();
  }

  showbid1() {
    this.ColorLineBidModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.ColorLineBidModel.colorLineId = Number(sessionStorage.getItem("colorLineId"));
    this.ColorLineBidModel.name = sessionStorage.getItem("name");
    this.ColorLineBidModel.game = sessionStorage.getItem("game");

    if (this.ColorLineBidModel.game == "Red And Black Game") {
      this.pagearray = [20, 50, 100, 150];
    }

    this.getGameRateModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.getGameRateModel.game = sessionStorage.getItem("game");

    this.httpService.postRequest(getGameRateURL, this.getGameRateModel).subscribe((data: any) => {
      this.rate = data;

    })

    this.httpService.postRequest(colorBidMasterURL, this.ColorLineBidModel).subscribe((data: any) => {
    
      if (data) {
        this.bidList = data;

        for (let j = 0; j < this.bidList.length; j++) {
         
            this.totalBidOpen = this.totalBidOpen + this.bidList[j].totalAmount;
         
        }

        for (let i = 0; i <= 1; ++i) {


          this.profitLoss.countValue = 0;
          this.profitLoss.totalAmount = 0;
          this.profitLoss.profit = this.totalBidOpen;
          this.profitLoss.loss = 0;
          if (i==0) {

            this.profitLoss.value = "Black";

          }
          else {
            this.profitLoss.value = "Red";
          }
          this.bidList1.push(this.profitLoss);
          this.profitLoss = new profitloss;


        }
  
        if(sessionStorage.getItem("game") == "Red And Black Game"){
          for (let j = 0; j < this.bidList1.length; j++) {

                for(let i=0; i<this.bidList.length;i++){
              
     
            if ((this.bidList1[j].value) == (this.bidList[i].value) ) {


              this.bidList1[j] = this.bidList[i];
              if (this.totalBidOpen >= ((this.rate / 10) * this.bidList[i].totalAmount)) {
                this.bidList1[j].profit = this.totalBidOpen - ((this.rate / 10) * this.bidList[i].totalAmount);
                this.bidList1[j].loss = 0;

              }
              else {
              
                this.bidList1[j].loss = ((this.rate / 10) * this.bidList[i].totalAmount) - this.totalBidOpen;
                this.bidList1[j].profit = 0;

              }

            }
            }

        
          }
        }
        // this.dataSource = new MatTableDataSource(this.bidList);3
     

        this.dataSource = new MatTableDataSource(this.bidList1);


      }
    })

  } 
    

}
