import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Gameratemaster, ShreestarlineGameratemaster } from '../AllModels';
import { saveGameRateMasterURL, updateGameRateMasterURL, getByIdGameRateURl, getAllGameRateURL, saveShreeStarLineGameRateMasterURL, updateShreeStarLineGameRateMasterURL, getByIdShreeStarLineGameRateURl, getAllShreeStarLineGameRateURL, getActiveAgentURL, getShreeGameRateListByAgentId } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-shreestarlinegameratemaster',
  templateUrl: './shreestarlinegameratemaster.component.html',
  styleUrls: ['./shreestarlinegameratemaster.component.css']
})
export class ShreestarlinegameratemasterComponent implements OnInit {
  
  shreestarlinegameratemasterForm: FormGroup;
  shreestarlineGameratemasterModel = new ShreestarlineGameratemaster();
  ActiveAgentList:Array<ShreestarlineGameratemaster>;
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  agentId: number;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Game Rate','status', 'Action'];
  dataSource = new MatTableDataSource<ShreestarlineGameratemaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.shreestarlinegameratemasterForm = this.formBuilder.group({
      gamename: new FormControl('', [Validators.required,]),
      gamerate: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      agentId:new FormControl('', [Validators.required]),
      agentId1:new FormControl('', []),
    })
    this.getAllUser();
    this.getActiveAgent();
    this.hide = true;
  }

  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }

  getShreeGameRateListByAgentId()
  {
    
    this.httpService.getRequest(getShreeGameRateListByAgentId + "/" + this.shreestarlineGameratemasterModel.agentId1).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }


  SaveUser() {
    if (this.isEdit) {
        this.httpService.postRequest(saveShreeStarLineGameRateMasterURL, this.shreestarlineGameratemasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllUser();
            this.shreestarlinegameratemasterForm.reset();
            this.mobileMsg=" ";
            
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
        this.httpService.putRequest(updateShreeStarLineGameRateMasterURL, this.shreestarlineGameratemasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
            this.getAllUser();
            this.shreestarlinegameratemasterForm.reset();
            this.mobileMsg=" ";
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
   
    }  
  }

  getUserById(shreeStarLineGameRateMasterId) {
    this.httpService.getRequest(getByIdShreeStarLineGameRateURl + "/" + shreeStarLineGameRateMasterId).subscribe((data: any) => {
      this.shreestarlineGameratemasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllUser() {

    this.httpService.getRequest(getShreeGameRateListByAgentId + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
