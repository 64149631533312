import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DateAdapter, MatCheckboxChange, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EmployeeMaster, EmployeePrivilegeMaster, PrivilegeMaster } from '../AllModels';
import { createEmployeePrivilegeMasterURL, getActiveAgentURL, getActiveEmployeeMasterURL, getAllEmployeePrivilegeMasterURL, getAllListPrivilegeMasterURL, getByIdEmployeePrivilegeMasterURL, getByIdPrivilegeMasterURL, updateEmployeePrivilegeMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-employeeprivilege',
  templateUrl: './employeeprivilege.component.html',
  styleUrls: ['./employeeprivilege.component.css']
})
export class EmployeeprivilegeComponent implements OnInit {
  employeePrivilegeMasterForm: FormGroup;
  employeePrivilegeMasterModel = new EmployeePrivilegeMaster();
  ActiveEmployeeList:Array<EmployeeMaster>;
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  tempId : number;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','des', 'number','address','email','password', 'status', 'Action'];
  dataSource = new MatTableDataSource<EmployeePrivilegeMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.employeePrivilegeMasterForm = this.formBuilder.group({
      agentId: new FormControl('',),
      adminSetting: new FormControl('', ),
      agentProfile: new FormControl('', ),
      agentFundRequest: new FormControl('', ),
      agent: new FormControl('',),
      agentRegistration: new FormControl('',),
      agentTransaction: new FormControl('',),
      bannerMaster: new FormControl('',),
      colorGameRateMaster: new FormControl('',),
      colorLineMasterMain: new FormControl('',),
      colorLineMasterSub: new FormControl('',),
      colorSchedularLine: new FormControl('', ),
      dashboard: new FormControl('',),
      fundRequestForm: new FormControl('',),
      fundRequestHistory: new FormControl('',),
      fundRequestList: new FormControl('',),
      gameMaster: new FormControl('',),
      gameRateMaster: new FormControl('',),
      howToPlayMaster: new FormControl('',),
      lineHistory: new FormControl('',),
      lineMasterMain: new FormControl('',),
      lineMasterSub: new FormControl('',),
      notifications: new FormControl('',),
      notificationsMaster: new FormControl('',),
      onlineStarLineGameRateMaster: new FormControl('',),
      onlineStarLineHistory: new FormControl('',),
      onlineStarLineMasterMain: new FormControl('',),
      onlineStarLineMasterSub: new FormControl('',),
      privilegeMaster: new FormControl('',),
      reports: new FormControl('',),
      schedularLine: new FormControl('',),
      schedularOnlineStarLine: new FormControl('',),
      todaysAddWithdraw: new FormControl('',),
      transactionHistory: new FormControl('',),
      userBidList: new FormControl('',),
      userFundRequest: new FormControl('',),
      userHistory: new FormControl('',),
      userMaster: new FormControl('',),
      userRegistration: new FormControl('',),
      userWinningReports: new FormControl('',),
      employeePrivilegeMaster:new FormControl('',),
      employee:new FormControl('',),
      employeeMain:new FormControl('',),



    })
    this.getActiveEmployee();
  }
  getActiveEmployee() {
    this.httpService.getRequest(getActiveEmployeeMasterURL).subscribe((data: any) => {
      this.ActiveEmployeeList = data;
    })
  }

  SavePrivilege() {
    if (this.isEdit) {
      this.httpService.postRequest(createEmployeePrivilegeMasterURL, this.employeePrivilegeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllPrivilege();
          this.employeePrivilegeMasterForm.reset();
          this.employeePrivilegeMasterModel = new EmployeePrivilegeMaster();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateEmployeePrivilegeMasterURL, this.employeePrivilegeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllPrivilege();
          this.employeePrivilegeMasterForm.reset();
          this.employeePrivilegeMasterModel = new EmployeePrivilegeMaster();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
   
    }
  }

  
  getPrivilegeById(employeePrivilegeId) {
    this.httpService.getRequest(getByIdEmployeePrivilegeMasterURL + "/" + employeePrivilegeId).subscribe((data: any) => {
      this.employeePrivilegeMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }

  getAllPrivilege() {
    this.tempId = this.employeePrivilegeMasterModel.employeeId;
    this.httpService.getRequest(getAllEmployeePrivilegeMasterURL + "/" + this.employeePrivilegeMasterModel.employeeId).subscribe((data: any) => {
      if(data!=null){
 
        this.employeePrivilegeMasterModel = data;
        this.employeePrivilegeMasterModel.employeeId=this.tempId;
      }

    })
  }

  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.employeePrivilegeMasterModel.agent=true;
      this.employeePrivilegeMasterModel.agentFundRequest=true;
      this.employeePrivilegeMasterModel.agentProfile=true;
      this.employeePrivilegeMasterModel.agentRegistration=true;
      this.employeePrivilegeMasterModel.agentTransaction=true;
      this.employeePrivilegeMasterModel.bannerMaster=true;
      this.employeePrivilegeMasterModel.colorGameRateMaster=true;
      this.employeePrivilegeMasterModel.colorLineMasterMain=true;
      this.employeePrivilegeMasterModel.colorLineMasterSub=true;
      this.employeePrivilegeMasterModel.colorSchedularLine=true;
      this.employeePrivilegeMasterModel.dashboard=true;
      this.employeePrivilegeMasterModel.fundRequestForm=true;
      this.employeePrivilegeMasterModel.fundRequestHistory=true;
      this.employeePrivilegeMasterModel.fundRequestList=true;
      this.employeePrivilegeMasterModel.gameMaster=true;
      this.employeePrivilegeMasterModel.gameRateMaster=true;
      this.employeePrivilegeMasterModel.howToPlayMaster=true;
      this.employeePrivilegeMasterModel.lineHistory=true;
      this.employeePrivilegeMasterModel.lineMasterMain=true;
      this.employeePrivilegeMasterModel.lineMasterSub=true;
      this.employeePrivilegeMasterModel.notifications=true;
      this.employeePrivilegeMasterModel.notificationsMaster=true;
      this.employeePrivilegeMasterModel.onlineStarLineGameRateMaster=true;
      this.employeePrivilegeMasterModel.onlineStarLineHistory=true;
      this.employeePrivilegeMasterModel.onlineStarLineMasterMain=true;
      this.employeePrivilegeMasterModel.onlineStarLineMasterSub=true;
      this.employeePrivilegeMasterModel.privilegeMaster=true;
      this.employeePrivilegeMasterModel.reports=true;
      this.employeePrivilegeMasterModel.schedularLine=true;
      this.employeePrivilegeMasterModel.schedularOnlineStarLine=true;
      this.employeePrivilegeMasterModel.todaysAddWithdraw=true;
      this.employeePrivilegeMasterModel.transactionHistory=true;
      this.employeePrivilegeMasterModel.userBidList=true;
      this.employeePrivilegeMasterModel.userFundRequest=true;
      this.employeePrivilegeMasterModel.userHistory=true;
      this.employeePrivilegeMasterModel.userMaster=true;
      this.employeePrivilegeMasterModel.userRegistration=true;
      this.employeePrivilegeMasterModel.userWinningReports=true;
      this.employeePrivilegeMasterModel.adminSetting=true;
      this.employeePrivilegeMasterModel.employee=true;
      this.employeePrivilegeMasterModel.employeePrivilegeMaster=true;
      this.employeePrivilegeMasterModel.employeeMain=true;
    }
    else {
      this.employeePrivilegeMasterModel.agent=false;
      this.employeePrivilegeMasterModel.agentFundRequest=false;
      this.employeePrivilegeMasterModel.agentProfile=false;
      this.employeePrivilegeMasterModel.agentRegistration=false;
      this.employeePrivilegeMasterModel.agentTransaction=false;
      this.employeePrivilegeMasterModel.bannerMaster=false;
      this.employeePrivilegeMasterModel.colorGameRateMaster=false;
      this.employeePrivilegeMasterModel.colorLineMasterMain=false;
      this.employeePrivilegeMasterModel.colorLineMasterSub=false;
      this.employeePrivilegeMasterModel.colorSchedularLine=false;
      this.employeePrivilegeMasterModel.dashboard=false;
      this.employeePrivilegeMasterModel.fundRequestForm=false;
      this.employeePrivilegeMasterModel.fundRequestHistory=false;
      this.employeePrivilegeMasterModel.fundRequestList=false;
      this.employeePrivilegeMasterModel.gameMaster=false;
      this.employeePrivilegeMasterModel.gameRateMaster=false;
      this.employeePrivilegeMasterModel.howToPlayMaster=false;
      this.employeePrivilegeMasterModel.lineHistory=false;
      this.employeePrivilegeMasterModel.lineMasterMain=false;
      this.employeePrivilegeMasterModel.lineMasterSub=false;
      this.employeePrivilegeMasterModel.notifications=false;
      this.employeePrivilegeMasterModel.notificationsMaster=false;
      this.employeePrivilegeMasterModel.onlineStarLineGameRateMaster=false;
      this.employeePrivilegeMasterModel.onlineStarLineHistory=false;
      this.employeePrivilegeMasterModel.onlineStarLineMasterMain=false;
      this.employeePrivilegeMasterModel.onlineStarLineMasterSub=false;
      this.employeePrivilegeMasterModel.privilegeMaster=false;
      this.employeePrivilegeMasterModel.reports=false;
      this.employeePrivilegeMasterModel.schedularLine=false;
      this.employeePrivilegeMasterModel.schedularOnlineStarLine=false;
      this.employeePrivilegeMasterModel.todaysAddWithdraw=false;
      this.employeePrivilegeMasterModel.transactionHistory=false;
      this.employeePrivilegeMasterModel.userBidList=false;
      this.employeePrivilegeMasterModel.userFundRequest=false;
      this.employeePrivilegeMasterModel.userHistory=false;
      this.employeePrivilegeMasterModel.userMaster=false;
      this.employeePrivilegeMasterModel.userRegistration=false;
      this.employeePrivilegeMasterModel.userWinningReports=false;
      this.employeePrivilegeMasterModel.adminSetting=false;
      this.employeePrivilegeMasterModel.employee=false;
      this.employeePrivilegeMasterModel.employeePrivilegeMaster=false;
      this.employeePrivilegeMasterModel.employeeMain=false;

    }
  }

}
