import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentFundMaster } from '../AllModels';
import { getAgentWiseFundRequestURL, getAllAdminSettingURL, getUserByIdURL, saveAgentRequestURL } from '../AllURL';
import { EventEmitterService } from '../shared/service/event-emitter.service';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-agentfundrequest',
  templateUrl: './agentfundrequest.component.html',
  styleUrls: ['./agentfundrequest.component.css']
})
export class AgentfundrequestComponent implements OnInit {
  AgentfundrequestForm: FormGroup;
  AgentFundMasterModel = new AgentFundMaster();
  currentWalletPoints: any;
  adminWithdrawAmount: any;
  adminAddMinAmt: any;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog, private eventEmitterService: EventEmitterService) { }

  ngOnInit() {
    this.AgentfundrequestForm = this.formBuilder.group({
      status: new FormControl(''),
      points: new FormControl('', [Validators.required,])
    })
    this.getWalletPoints();
    this.getAllAdminSettings();
  }

  saveFund() {

    this.AgentFundMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    if (this.AgentFundMasterModel.agentFundType == 'Add') {
      if (this.AgentFundMasterModel.agentFundType == 'Add' && this.adminAddMinAmt <= this.AgentFundMasterModel.agentPoints) {
        this.httpService.postRequest(saveAgentRequestURL, this.AgentFundMasterModel).subscribe((data: any) => {
          if (data) {
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.eventEmitterService.onFirstComponentButtonCart();
            this.AgentfundrequestForm.reset();
            this.AgentFundMasterModel = new AgentFundMaster();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })

      }

      else {
        this.toastr.warningToastr("Your Add Amount should be minimum " + this.adminAddMinAmt + " !", "Warning!", { timeout: 500 });
      }
    }

    if (this.AgentFundMasterModel.agentFundType == 'Withdraw') {
      if (this.AgentFundMasterModel.agentFundType == 'Withdraw' && this.adminWithdrawAmount <= this.AgentFundMasterModel.agentPoints) {
        if (this.AgentFundMasterModel.agentPoints <= this.currentWalletPoints) {
          this.httpService.postRequest(saveAgentRequestURL, this.AgentFundMasterModel).subscribe((data: any) => {
            if (data) {
              this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
              this.eventEmitterService.onFirstComponentButtonCart();
              this.AgentfundrequestForm.reset();
              this.AgentFundMasterModel = new AgentFundMaster();
            }
            else {
              this.toastr.errorToastr("Something went Wrong.", "Error");
            }
          })
        }
        else {
          this.toastr.warningToastr("Your Withdrawal Amount should be less than Wallet Points !", "Warning!", { timeout: 500 });
        }
      }
      else {
        this.toastr.warningToastr("Your Withdrawal Amount should be minimum " + this.adminWithdrawAmount + " !", "Warning!", { timeout: 500 });
      }

    }

    this.getWalletPoints();
    this.getAllAdminSettings();

  }

  getWalletPoints() {

    this.httpService.getRequest(getUserByIdURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {

      this.currentWalletPoints = data.agentwalletPoints;
    });
  }

  getAllAdminSettings() {
    this.httpService.getRequest(getAllAdminSettingURL).subscribe((data: any) => {

      this.adminWithdrawAmount = data[0].adminWithdrawAmount;
      this.adminAddMinAmt = data[0].adminAddAmount;
    });
  }
}