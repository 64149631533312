import { Injectable } from '@angular/core';
import { MainURL } from 'src/app/constants/configUrl';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpmethodsService {

  baseUrl = MainURL.HostUrl;
  constructor(private http : HttpClient) { 

  }

  postRequest(url,body) {
    
    return this.http.post(this.baseUrl+url,body)
  }

  getRequest(url){
    return this.http.get(this.baseUrl+url)
  }

  putRequest(url,body){
    return this.http.put(this.baseUrl+url,body)
  }

  deleteRequest(url,id){
    return this.http.delete(this.baseUrl+url+"/"+id)
  }

//   public fileUpload(url: string,formData: File)
// {

// const uploadfile: FormData = new FormData();

// uploadfile.append('uploadexcel', formData);

// return this.http.post(this.baseUrl+url, uploadfile);
// }

public fileUpload(url: string,formData : File)
{

const uploadfile: FormData = new FormData();

// uploadfile.append('uploadexcel', formData);
uploadfile.append('uploadfile', formData);  
 
return this.http.post(this.baseUrl+url, uploadfile);
}
}