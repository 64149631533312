import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ColorRateMaster, Gameratemaster } from '../AllModels';
import { createNewColorRateMasterURL, getActiveAgentURL, getByIdNewColorRateMasterURL, getColorRateListByAgentIdURL, updateNewColorRateMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-colorratemaster',
  templateUrl: './colorratemaster.component.html',
  styleUrls: ['./colorratemaster.component.css']
})
export class ColorratemasterComponent implements OnInit {

  colorRateForm: FormGroup;
  colorRateModel = new ColorRateMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  ActiveAgentList: Array<ColorRateMaster>;
  agentId: number;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Game Rate', 'status', 'Action'];
  dataSource = new MatTableDataSource<ColorRateMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.colorRateForm = this.formBuilder.group({
      colorLineGameName: new FormControl('', [Validators.required,]),
      colorLineGameRate: new FormControl('', [Validators.required]),
      colorLineStatus: new FormControl('', [Validators.required]),
      agentId: new FormControl('', [Validators.required]),
      agentId1: new FormControl('', []),
    })
    this.getAllGameRate();
    this.getActiveAgent();
    this.hide = true;
  }


  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }

  SaveGameRate() {
    if (this.isEdit) {
      this.httpService.postRequest(createNewColorRateMasterURL, this.colorRateModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllGameRate();
          this.colorRateForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateNewColorRateMasterURL, this.colorRateModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllGameRate();
          this.colorRateForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }


  getGameRateById(colorLineGameRateMasterId) {
    this.httpService.getRequest(getByIdNewColorRateMasterURL + "/" + colorLineGameRateMasterId).subscribe((data: any) => {
      this.colorRateModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllGameRate() {
    this.httpService.getRequest(getColorRateListByAgentIdURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  
  getGameRateListByAgentId()
  {

    this.httpService.getRequest(getColorRateListByAgentIdURL + "/" + this.colorRateModel.agentId1).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
