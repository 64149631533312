import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CompanyMaster, UserMaster } from '../AllModels';
import { addUserMasterURL, updateUserMasterURL, getByIdUserMasterURL, getAllUserMasterURL, checkMobileNumberURL, saveCompanyURL, updateCompanyURL, getAllCompanyURL, getCompanyByIdURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-companyregistration',
  templateUrl: './companyregistration.component.html',
  styleUrls: ['./companyregistration.component.css']
})
export class CompanyregistrationComponent implements OnInit {

  companyregistrationForm: FormGroup;
  CompanyMasterModel = new CompanyMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Company Name', 'Number', 'Mail ID', 'Password', 'Status', 'Action'];
  dataSource = new MatTableDataSource<CompanyMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.companyregistrationForm = this.formBuilder.group({
      companyname: new FormControl('', [Validators.required,]),
      number: new FormControl('', [Validators.required, Validators.pattern("[6-9]{1}[0-9]{9}")]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
    })
    this.getAllUser();
    this.hide = true;
  }


  SaveUser() {
    if (this.isEdit) {
        this.httpService.postRequest(saveCompanyURL, this.CompanyMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllUser();
            this.companyregistrationForm.reset();
            this.mobileMsg=" ";
            
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
        this.httpService.putRequest(updateCompanyURL, this.CompanyMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
            this.getAllUser();
            this.companyregistrationForm.reset();
            this.mobileMsg=" ";
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
   
    }  
  }

  getUserById(companyMasterId) {
    this.httpService.getRequest(getCompanyByIdURL + "/" + companyMasterId).subscribe((data: any) => {
      this.CompanyMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllUser() {
    this.httpService.getRequest(getAllCompanyURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  // checkMobileNumber(mobNo)
  // {
  //   this.httpService.getRequest(checkMobileNumberURL + "/" + mobNo).subscribe((data: any) => {
  //     if (data == true) {
  //       this.mobileMsg = "Valid Mobile No ";
  //       this.textColorMobile = "green";
  //       this.mobFlag = true;
  //     }
  //     else {
  //       this.mobileMsg = "Mobile Number Already Exist";
  //       this.textColorMobile = "Red";
  //       this.mobFlag = false;
  //     }
  //   })
  // }  
  checkMobileNumber(companyMobNo)
  {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
