import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentMaster, UserMaster } from '../AllModels';
import { addUserMasterURL, updateUserMasterURL, getByIdUserMasterURL, getAllUserMasterURL, checkMobileNumberURL, addAgentUserURL, getAllAgentUser, getUserByIdURL, updateAgentUserURL, getCheckmobno } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
declare var $:any;
@Component({
  selector: 'app-agentregistration',
  templateUrl: './agentregistration.component.html',
  styleUrls: ['./agentregistration.component.css']
})
export class AgentregistrationComponent implements OnInit {

  agentregistrationForm: FormGroup;
  // UserMasterModel = new UserMaster();
  AgentMasterModel = new AgentMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Number', 'Commission', 'Status', 'Action'];
  dataSource = new MatTableDataSource<UserMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.agentregistrationForm = this.formBuilder.group({
      fullname: new FormControl('', [Validators.required,]),
      // altnumber: new FormControl('', [Validators.pattern("[6-9]{1}[0-9]{9}")]),
      number: new FormControl('', [Validators.required, Validators.pattern("[6-9]{1}[0-9]{9}")]),
      // email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      status: new FormControl(''),
      Pincode: new FormControl(''),
      IFSCCode: new FormControl(''),
      city: new FormControl(''),
      accnumber: new FormControl(''),
      bankname: new FormControl(''),
      accholder: new FormControl(''),
      paytmnumber: new FormControl(''),
      gpaymnumber: new FormControl(''),
      phonepaynumber: new FormControl(''),
      whatsappnumber: new FormControl('', [Validators.required]),
      Commission:new FormControl('', [Validators.required]),
      walletpoints:new FormControl(''),
    })
   
    this.getAllUser();
    this.hide = true;
  }



  SaveAgentUser() {
  
    if (this.isEdit) {
    this.AgentMasterModel.roleId = 2;
    this.AgentMasterModel.companyMasterId=1;
     if(this.mobFlag==true){
      this.httpService.postRequest(addAgentUserURL, this.AgentMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.router.navigateByUrl("/layout/gameratemaster");
          this.getAllUser();
          this.agentregistrationForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
     }
     else{
       this.toastr.errorToastr("Please Enter Valid Mobile Number.", "Error");
     }
 
    }
    else {
      // this.AgentMasterModel.roleId = 2;
      // this.AgentMasterModel.agentwalletPoints = 0;
      this.AgentMasterModel.companyMasterId=1;
      this.httpService.putRequest(updateAgentUserURL, this.AgentMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.agentregistrationForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
      // }
      // else {
      //   this.toastr.errorToastr("Please Select Valid Date.", "Error");
      // }
    }
  }

  getUserById(agentId) {
    this.httpService.getRequest(getUserByIdURL + "/" + agentId).subscribe((data: any) => {
      this.AgentMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllUser() {
    this.httpService.getRequest(getAllAgentUser).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  checkMobileNumber(agentMobNo)
  {
    if (agentMobNo.length == 10){
      this.httpService.getRequest(getCheckmobno + "/" + agentMobNo).subscribe((data: any) => {
        if (data == true) {
          this.mobileMsg = "Valid Mobile No ";
          this.textColorMobile = "green";
          this.mobFlag = true;
        }
        else {
          this.mobileMsg = "Mobile Number Already Exist";
          this.textColorMobile = "Red";
          this.mobFlag = false;
        }
      })
    }

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
