import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource, ThemePalette } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ColorNewLineMaster } from '../AllModels';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as moment from 'moment';
import { createNewColorLineMasterURL, getAllNewColorLineMasterURL, getByIdNewColorLineMasterURL, updateNewColorLineMasterURL } from '../AllURL';


@Component({
  selector: 'app-colornewlinemaster',
  templateUrl: './colornewlinemaster.component.html',
  styleUrls: ['./colornewlinemaster.component.css']
})
export class ColornewlinemasterComponent implements OnInit {

  colorNewLineMasterForm: FormGroup;
  colorNewLineMasterModel = new ColorNewLineMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Line Name', 'StartTime', 'EndTime', 'Status', 'Action'];
  dataSource = new MatTableDataSource<ColorNewLineMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  @ViewChild('picker', { static: false }) picker: any;
  @ViewChild('picker1', { static: false }) picker1: any;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateControl = new FormControl(new Date());

  public date1: moment.Moment;
  public disabled1 = false;
  public showSpinners1 = true;
  public showSeconds1 = false;
  public touchUi1 = false;
  public enableMeridian1 = false;
  public minDate1: moment.Moment;
  public maxDate1: moment.Moment;
  public stepHour1 = 1;
  public stepMinute1 = 1;
  public stepSecond1 = 1;
  public color1: ThemePalette = 'primary';
  public dateControl1 = new FormControl(new Date());

  ngOnInit() {
    this.colorNewLineMasterForm = this.formBuilder.group({
      newColorLineName: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required]),
      monday: new FormControl(''),
      tuesday: new FormControl(''),
      wednesday: new FormControl(''),
      thursday: new FormControl(''),
      friday: new FormControl(''),
      saturday: new FormControl(''),
      sunday: new FormControl(''),
    })
    this.getAllAgent();
  }
  SaveNewColorLine() {
    if (this.isEdit) {

      this.httpService.postRequest(createNewColorLineMasterURL, this.colorNewLineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllAgent();
          this.colorNewLineMasterForm.reset();
          this.colorNewLineMasterModel = new ColorNewLineMaster();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {
      this.httpService.putRequest(updateNewColorLineMasterURL, this.colorNewLineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllAgent();
          this.colorNewLineMasterForm.reset();
          this.colorNewLineMasterModel = new ColorNewLineMaster();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
  
    }
  }
  
  getNewColorLinemasterById(newColorLineId) {
    this.httpService.getRequest(getByIdNewColorLineMasterURL + "/" + newColorLineId).subscribe((data: any) => {
      this.colorNewLineMasterModel = data;
      this.colorNewLineMasterModel.newColorLineStartTime = new Date(data.newColorLineStartTime);
      this.colorNewLineMasterModel.newColorLineEndTime = new Date(data.newColorLineEndTime);
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  
  getAllAgent() {
    this.httpService.getRequest(getAllNewColorLineMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

     ///////////////////////For Search ////////////////////
     applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
}
