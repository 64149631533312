import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentFundReportMaster, AgentReportMaster } from '../AllModels';
import { getActiveAgentURL, getFundRequestReportURL, getTransactionReportURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-agentfundrequestreports',
  templateUrl: './agentfundrequestreports.component.html',
  styleUrls: ['./agentfundrequestreports.component.css']
})
export class AgentfundrequestreportsComponent implements OnInit {

  AgentFundReportForm: FormGroup;
  AgentFundReportMasterModel = new AgentFundReportMaster();
  ActiveAgentList = Array<AgentReportMaster>();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog,private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'Agent Name','Mobile Number', 'Points', 'Date', 'Transaction Type','Status'];
  dataSource = new MatTableDataSource<AgentReportMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.AgentFundReportForm = this.formBuilder.group({
      status: new FormControl(''),
      date: new FormControl('', [Validators.required,]),
      toDate: new FormControl('', [Validators.required,]),
      agentId: new FormControl(''),

    })
    this.getActiveAgent();
  }

  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }

  getFundRequestreport()
  {
    this.spinnerService.show();
    this.httpService.postRequest(getFundRequestReportURL,this.AgentFundReportMasterModel).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data.agentFundList);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
        this.spinnerService.hide();
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
