import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EmployeeMaster } from '../AllModels';
import { createEmployeeMasterURL, getAllEmployeeMasterURL, getByIdEmployeeMasterURL, updateEmployeeMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-employeemaster',
  templateUrl: './employeemaster.component.html',
  styleUrls: ['./employeemaster.component.css']
})
export class EmployeemasterComponent implements OnInit {

  employeeMasterForm: FormGroup;
  employeeMasterModel = new EmployeeMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','des', 'number','address','email','password', 'status', 'Action'];
  dataSource = new MatTableDataSource<EmployeeMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {

    this.employeeMasterForm = this.formBuilder.group({
      alternativeMobileNo: new FormControl('',),
      employeeAddress: new FormControl('', [Validators.required,]),
      employeeDesignation: new FormControl('', [Validators.required]),
      employeeEmail: new FormControl('', [Validators.required]),
      employeeFullName: new FormControl('', [Validators.required]),
      employeeMobileNo: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),


    })

    this.getAllEmployee();
  }
  SaveEmployee() {
    if (this.isEdit) {

      this.employeeMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
      this.httpService.postRequest(createEmployeeMasterURL, this.employeeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllEmployee();
          this.employeeMasterForm.reset();
          this.mobileMsg=" ";
          
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
  }
  else {
    this.employeeMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
      this.httpService.putRequest(updateEmployeeMasterURL, this.employeeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllEmployee();
          this.employeeMasterForm.reset();
          this.mobileMsg=" ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
 
  }  
  }
  getEmployeeById(employeeId) {
    this.httpService.getRequest(getByIdEmployeeMasterURL + "/" + employeeId).subscribe((data: any) => {
      this.employeeMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllEmployee() {
    this.httpService.getRequest(getAllEmployeeMasterURL).subscribe((data: any) => {
      console.log(JSON.stringify(data))
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
