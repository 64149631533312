import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LineMaster, RevertResultEntryMaster } from '../AllModels';
import { updateResultURL, updateRevertResultURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-revertresult',
  templateUrl: './revertresult.component.html',
  styleUrls: ['./revertresult.component.css']
})
export class RevertresultComponent implements OnInit {

   RevertResultEntryForm: FormGroup;
   RevertResultEntryMasterModel = new RevertResultEntryMaster();
  gamename:string;
  gamedate:string;
  linemasterModel= new LineMaster();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe , private router : Router, private spinnerService: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
    this.RevertResultEntryForm = this.formBuilder.group({

      opennumber: new FormControl('', [Validators.required]),
      Closenumber: new FormControl('', [Validators.required]),
      Midnumber: new FormControl('', [Validators.required]),
      session:new FormControl('', [Validators.required]),
    })
    this.gamename=sessionStorage.getItem("lineName");
    this.gamedate=sessionStorage.getItem("lineDate");
    this.linemasterModel = JSON.parse(sessionStorage.getItem("linemaster"));
   
    this.RevertResultEntryForm.controls['Closenumber'].disable();
    this.RevertResultEntryForm.controls['opennumber'].disable();
    this.RevertResultEntryForm.controls['Midnumber'].disable();

  }
 
  open(event) {
  
    this.RevertResultEntryForm.controls['Closenumber'].disable();
    this.RevertResultEntryForm.controls['opennumber'].enable();
    this.RevertResultEntryForm.controls['Midnumber'].disable();
  }
  close(event) {
  
    this.RevertResultEntryForm.controls['Closenumber'].enable();
    this.RevertResultEntryForm.controls['opennumber'].disable();
    this.RevertResultEntryForm.controls['Midnumber'].disable();
  }

  temp1: number=0 ;
  temp2: number ;
  midno:string;
  
  calculateopendigit(lineOpenNo) {
    if (lineOpenNo.length == 3) {
    this.temp2 = this.RevertResultEntryMasterModel.lineOpenNo;
    while (this.temp2) {
      this.temp1 += this.temp2 % 10
      this.temp2 = Math.floor(this.temp2 / 10);

    }
    if(this.temp1 >= 10)
    {
      this.temp1= this.temp1%10;
    }
    this.midno = String(this.temp1);
    this.RevertResultEntryMasterModel.lineMidNo= Number(this.midno);

  }
}
  temp3: number = 0;
  temp4: number ;
  calculateclosedigit(lineCloseNo)
  {
    if (lineCloseNo.length == 3) {

    this.temp3=0;

    this.midno=String(this.RevertResultEntryMasterModel.lineMidNo);
    this.temp4 = this.RevertResultEntryMasterModel.lineCloseNo;

    while (this.temp4) {
      this.temp3 += this.temp4 % 10
      this.temp4 = Math.floor(this.temp4 / 10);
    
    }
    if(this.temp3 >= 10)
    {
      this.temp3= this.temp3%10;
    }
    if(this.midno.length==1){
      this.midno =this.midno + this.temp3;
    }
    if(this.midno.length==2){
      let tempNumber = parseInt(String(Number(this.midno)/10));
      this.midno = String(tempNumber +""+this.temp3);
    }   
    this.RevertResultEntryMasterModel.lineMidNo= Number(this.midno);

  }
}

  updateResult()
  {
    this.spinnerService.show();
    this.RevertResultEntryMasterModel.lineId = Number(sessionStorage.getItem("lineId"));
    this.RevertResultEntryMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.RevertResultEntryMasterModel.lineName = sessionStorage.getItem("lineName");
    
    this.httpService.putRequest(updateRevertResultURL, this.RevertResultEntryMasterModel).subscribe((data: any) => {
      if (data) {
        this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
        this.RevertResultEntryForm.reset();
        this.RevertResultEntryMasterModel = new RevertResultEntryMaster();
        this.router.navigateByUrl("/layout/linehistory");
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
        this.spinnerService.hide();
      }
    })
  }

}
