import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AdminDashboardUI';
  outerSubscription: Subscription;
  tabContentRef!: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router) { }
  // ShowSuccess(){
  //   this.toastr.success("Successfully Worked..!!");private toastr: ToastrService
  // }
  ngOnInit() {
   this.onTop();
 
  }

  onTop() {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
      });
    }
  }
 
}
