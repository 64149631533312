import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatPaginatorModule, MatSidenavModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatAutocompleteModule, MatSlideToggleModule, MatDatepickerModule, MatProgressSpinnerModule, MatNativeDateModule, MatRadioModule, MatCheckboxModule, MatLabel, MatTableModule, MatTabsModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatDialogModule, MatIconModule, MatDividerModule, MatListModule, MatToolbarModule, MatMenuModule, MatCardModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http'; 
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import * as $ from 'jquery';
import { UserregistrationComponent } from './userregistration/userregistration.component';
import { FinancewisereportsComponent } from './financewisereports/financewisereports.component'; 
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { AgentregistrationComponent } from './agentregistration/agentregistration.component';
import { LinemasterComponent } from './linemaster/linemaster.component';
import { NgxMatDatetimePickerModule } from 'ngx-mat-datetime-picker';
import { NgxMatMomentModule } from 'ngx-mat-moment-adapter';
import { FundrequestComponent } from './fundrequest/fundrequest.component';
import { AgentfundrequestComponent } from './agentfundrequest/agentfundrequest.component';
import { AgentrequestlistComponent } from './agentrequestlist/agentrequestlist.component';
import { AgenttransactionhistoryComponent } from './agenttransactionhistory/agenttransactionhistory.component';
import { AgenttransactionreportsComponent } from './agenttransactionreports/agenttransactionreports.component';
import { GameratemasterComponent } from './gameratemaster/gameratemaster.component';
import { HowtoplaymasterComponent } from './howtoplaymaster/howtoplaymaster.component';
import { UserhistoryComponent } from './userhistory/userhistory.component';
import { ViewuserhistoryComponent } from './viewuserhistory/viewuserhistory.component';
import { LinehistoryComponent } from './linehistory/linehistory.component';
import { CompanyregistrationComponent } from './companyregistration/companyregistration.component';
import { ViewlinehistoryComponent } from './viewlinehistory/viewlinehistory.component';
import { BidwiselinehistoryComponent } from './bidwiselinehistory/bidwiselinehistory.component';
import { HomeComponent } from './home/home.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { ResultentryComponent } from './resultentry/resultentry.component';
import { SchedularlinemasterComponent } from './schedularlinemaster/schedularlinemaster.component';
import { RevertresultComponent } from './revertresult/revertresult.component';
import { AgentfundrequestreportsComponent } from './agentfundrequestreports/agentfundrequestreports.component';
import { AgentwinningamountreportsComponent } from './agentwinningamountreports/agentwinningamountreports.component';
import { AgentwiseuserwinningreportComponent } from './agentwiseuserwinningreport/agentwiseuserwinningreport.component';
import { AgentwiseuserbidlistreportComponent } from './agentwiseuserbidlistreport/agentwiseuserbidlistreport.component';
import { NotificattionmasterComponent } from './notificattionmaster/notificattionmaster.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ResultComponent } from './result/result.component';
import { ResultchartComponent } from './resultchart/resultchart.component';
import { UserloginComponent } from './pages/userlogin/userlogin.component';
import { BidwiseshreestarlinehistoryComponent } from './bidwiseshreestarlinehistory/bidwiseshreestarlinehistory.component';
import { SchedularshreestarlinemasterComponent } from './schedularshreestarlinemaster/schedularshreestarlinemaster.component';
import { ShreestarlinecurrentdateactiveComponent } from './shreestarlinecurrentdateactive/shreestarlinecurrentdateactive.component';
import { ShreestarlinegameratemasterComponent } from './shreestarlinegameratemaster/shreestarlinegameratemaster.component';
import { ShreestarlinehistoryComponent } from './shreestarlinehistory/shreestarlinehistory.component';
import { ShreestarlinemasterComponent } from './shreestarlinemaster/shreestarlinemaster.component';
import { ShreestarlineresultchartComponent } from './shreestarlineresultchart/shreestarlineresultchart.component';
import { ShreestarlineresultentryComponent } from './shreestarlineresultentry/shreestarlineresultentry.component';
import { ShreestarlinerevertresultComponent } from './shreestarlinerevertresult/shreestarlinerevertresult.component';
import { ViewshreestarlinehistoryComponent } from './viewshreestarlinehistory/viewshreestarlinehistory.component';
import { AdminsettingComponent } from './adminsetting/adminsetting.component';
import { AgentprofileComponent } from './agentprofile/agentprofile.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AddwithrawdetailsComponent } from './addwithrawdetails/addwithrawdetails.component';
import { AgentwisefundrequesthistoryComponent } from './agentwisefundrequesthistory/agentwisefundrequesthistory.component';
import { BannerComponent } from './banner/banner.component';
import { ColorlinemasterComponent } from './colorlinemaster/colorlinemaster.component';
import { ColornewlinemasterComponent } from './colornewlinemaster/colornewlinemaster.component';
import { ColorratemasterComponent } from './colorratemaster/colorratemaster.component';
import { PrivilegemasterComponent } from './privilegemaster/privilegemaster.component';
import { ColorlinehistoryComponent } from './colorlinehistory/colorlinehistory.component';
import { ColorlinecurrentactivedateComponent } from './colorlinecurrentactivedate/colorlinecurrentactivedate.component';
import { ColorlineviewhistoryComponent } from './colorlineviewhistory/colorlineviewhistory.component';
import { ColorlineresultentryComponent } from './colorlineresultentry/colorlineresultentry.component';
import { ColorlinerevertresultentryComponent } from './colorlinerevertresultentry/colorlinerevertresultentry.component';
import { EmployeemasterComponent } from './employeemaster/employeemaster.component';
import { EmployeeprivilegeComponent } from './employeeprivilege/employeeprivilege.component';
import { ColorlinebidhistoryComponent } from './colorlinebidhistory/colorlinebidhistory.component';
import { UserforgotpasswordComponent } from './userforgotpassword/userforgotpassword.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { AddpointsComponent } from './addpoints/addpoints.component';






@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    UserregistrationComponent,
    FinancewisereportsComponent,
    AgentregistrationComponent,
    LinemasterComponent,
    FundrequestComponent,
    AgentfundrequestComponent,
    AgentrequestlistComponent,
    AgenttransactionhistoryComponent,
    AgenttransactionreportsComponent,
    GameratemasterComponent,
    HowtoplaymasterComponent,
    UserhistoryComponent,
    ViewuserhistoryComponent,
    LinehistoryComponent,
    CompanyregistrationComponent,
    ViewlinehistoryComponent,
    BidwiselinehistoryComponent,
    HomeComponent,
    ResultentryComponent,
    SchedularlinemasterComponent,
    RevertresultComponent,
    AgentfundrequestreportsComponent,
    AgentwinningamountreportsComponent,
    AgentwiseuserwinningreportComponent,
    AgentwiseuserbidlistreportComponent,
    NotificattionmasterComponent,
    PrivacypolicyComponent,
    ResultComponent,
    ResultchartComponent ,
    UserloginComponent,
    ShreestarlinemasterComponent,
    ShreestarlinehistoryComponent,
    ViewshreestarlinehistoryComponent,
    BidwiseshreestarlinehistoryComponent,
    ShreestarlineresultentryComponent,
    SchedularshreestarlinemasterComponent,
    ShreestarlinerevertresultComponent,
    ShreestarlinegameratemasterComponent,
    ShreestarlineresultchartComponent,
    ShreestarlinecurrentdateactiveComponent,
    AdminsettingComponent,
    AgentprofileComponent,
    AddwithrawdetailsComponent,
    AgentwisefundrequesthistoryComponent,
    BannerComponent,
    ColorlinemasterComponent,
    ColornewlinemasterComponent,
    ColorratemasterComponent,
    PrivilegemasterComponent,
    ColorlinehistoryComponent,
    ColorlinecurrentactivedateComponent,
    ColorlineviewhistoryComponent,
    ColorlineresultentryComponent,
    ColorlinerevertresultentryComponent,
    EmployeemasterComponent,
    EmployeeprivilegeComponent,
    ColorlinebidhistoryComponent,
    UserforgotpasswordComponent,
    UserprofileComponent,
    AddpointsComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatButtonModule, MatDialogModule,MatIconModule,MatDividerModule,MatListModule,MatToolbarModule,
    MatMenuModule,MatCardModule, MatPaginatorModule,
    CommonModule,
    RouterModule, 
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule, 
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    ChartsModule,
    MatTabsModule
  ],
  providers: [DatePipe,NgxSpinnerService,ThemeService,{provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  exports: [
    MatButtonModule, MatDialogModule, MatIconModule],
    
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent],

})
export class AppModule { }
