import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userforgotpassword',
  templateUrl: './userforgotpassword.component.html',
  styleUrls: ['./userforgotpassword.component.css']
})
export class UserforgotpasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
