import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ColorNewLineMaster } from '../AllModels';
import { getActiveListURL, getActiveNewColorLineMasterURL, getAllLineResult, getAllNewShreeStarLineActive } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  resultList:any[];
  resultList11:any[];
  resultList1:any[];
  resultList12:Array<ColorNewLineMaster>;

  constructor(private formBuilder: FormBuilder, public router: Router, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.getAllActiveLine();
    this.getAllActive();
    this.getAllNewShreeStarLineActive();
    this.getAllNewColorLineActive();
  }

  getAllActiveLine()
  {
    this.httpService.getRequest(getActiveListURL).subscribe((data: any) => {
      this.resultList = data.slice(0,10);

      for (let i = 0; i < this.resultList.length; i++) {

        if (this.resultList[i].lineCloseNo == null && this.resultList[i].lineOpenNo == null && this.resultList[i].lineMidNo == null) {
          this.resultList[i].showResult = String("***-**-***");
        }

        if (this.resultList[i].lineCloseNo == null && this.resultList[i].lineOpenNo != null && this.resultList[i].lineMidNo != null) {
          this.resultList[i].showResult = String(this.resultList[i].lineOpenNo) + "-" + String(this.resultList[i].lineMidNo)+"*"+"-"+"***";
        }

        if (this.resultList[i].lineCloseNo != null && this.resultList[i].lineOpenNo != null && this.resultList[i].lineMidNo != null) {
          this.resultList[i].showResult = String(this.resultList[i].lineOpenNo) + "-" + String(this.resultList[i].lineMidNo) + "-" + String(this.resultList[i].lineCloseNo);
        }

        if (this.resultList[i].viewStatus == 'true') {
          this.resultList[i].showResult = "Loading....";
        }

      }
    })
    
  }
  getAllActive() {
    this.httpService.getRequest(getAllLineResult).subscribe((data: any) => {
      this.resultList1 = data;
    })
  }
  showChart(result) {
    sessionStorage.setItem("name", result.newlineName)
    this.router.navigateByUrl("/resultchart");
    sessionStorage.setItem("showchart" ,'showchart' )

  }
  showChart1(result) {
    sessionStorage.setItem("name", result.lineName)
    this.router.navigateByUrl("/resultchart");
    sessionStorage.setItem("showchart" ,'showchart' )

  }
  showJodi(result)
  {
    sessionStorage.setItem("name", result.newlineName)
    sessionStorage.setItem("showjodi" ,'showjodi' )
    this.router.navigateByUrl("/resultchart");
  }

  showChart11(result) {
  
    this.router.navigateByUrl("/shreestarlinecurrentdateactive");
    sessionStorage.setItem("showchart" ,'showchart' )

  }


  getAllNewShreeStarLineActive() {
    this.httpService.getRequest(getAllNewShreeStarLineActive).subscribe((data: any) => {
      this.resultList11 = data;
    })
  }

  showChart12(result) {

    this.router.navigateByUrl("/colorlinecurrentactivedate");
    sessionStorage.setItem("showchart", 'showchart')

  }

  getAllNewColorLineActive() {
    this.httpService.getRequest(getActiveNewColorLineMasterURL).subscribe((data: any) => {
      this.resultList12 = data;
    })
  }

}
