import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-colorlineviewhistory',
  templateUrl: './colorlineviewhistory.component.html',
  styleUrls: ['./colorlineviewhistory.component.css']
})
export class ColorlineviewhistoryComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router: Router) {
  }

  ngOnInit() {
  }

  
  showbid1() {
    sessionStorage.setItem("game", "Red And Black Game")
    this.router.navigateByUrl("layout/colorlinebidhistory");
  }
}
