import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AddWithdrawMaster, AgentReportMaster, UserBiddingReportMaster } from '../AllModels';
import { getActiveAgentURL, getAddWithdrawURL, getAgentWiseUserBiddingListURL, getTransactionReportURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-addwithrawdetails',
  templateUrl: './addwithrawdetails.component.html',
  styleUrls: ['./addwithrawdetails.component.css']
})
export class AddwithrawdetailsComponent implements OnInit {

  AddWithdrawForm: FormGroup;
  AddWithdrawMasterModel = new AddWithdrawMaster();
  role1Flag:boolean=false;
  ActiveAgentList = Array<AddWithdrawMaster>();
  errormsg: string;
  errormsgDiv: boolean = false;
  sum: number = 0;
  roleId:number;
  roleFlag:boolean=false;
  totalAmount : number = 0;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog, private spinnerService: Ng4LoadingSpinnerService) { }
  displayedColumns: string[] = ['Sr.No.', 'User Name', 'User No', 'Date', 'Transaction Type', 'Points'];
  dataSource = new MatTableDataSource<AddWithdrawMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.AddWithdrawForm = this.formBuilder.group({
      status: new FormControl(''),
      date: new FormControl('', [Validators.required,]),
      agentId: new FormControl(''),
      

    })
    this.getActiveAgent();
    this.roleId=Number(sessionStorage.getItem("roleId"));
    
    if(this.roleId==1)
    {
      this.roleFlag=true;
 
    }
    else{
      this.roleFlag=false;
     

    }
  }

  getActiveAgent() {
    this.httpService.getRequest(getActiveAgentURL).subscribe((data: any) => {
      this.ActiveAgentList = data;
    })
  }

  getAddWithdrawReport() {
    this.spinnerService.show();
    
    this.httpService.postRequest(getAddWithdrawURL, this.AddWithdrawMasterModel).subscribe((data: any) => {
      // console.log(JSON.stringify(data))
      if (data.length > 0) {
        let a = 0;
        for (let i = 0; i < data.length; i++) {
          a = a + data[i].points;
          this.sum = Number(Math.fround(a).toFixed(2));
        }
      
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();

      }

      else {
        this.spinnerService.hide();
      }
    })
    this.errormsg = "No Records Found";
      this.errormsgDiv = true;
      this.spinnerService.hide();
  }
  

  getAgentreport()
  {
    this.spinnerService.show();
    this.AddWithdrawMasterModel.agentId=Number(sessionStorage.getItem("agentId"));

    this.httpService.postRequest(getAddWithdrawURL,this.AddWithdrawMasterModel).subscribe((data: any) => {
      if (data) {
        let a=0;
        for(let i=0;i<data.length;i++){
            a=a+data[i].bidPoint;
            this.totalAmount = Number(Math.fround(a).toFixed(2));

        }
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
        this.spinnerService.hide();
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
