import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserMaster } from '../AllModels';
import { getAllUserMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';
@Component({
  selector: 'app-userhistory',
  templateUrl: './userhistory.component.html',
  styleUrls: ['./userhistory.component.css']
})
export class UserhistoryComponent implements OnInit {
  userhistoryForm: FormGroup;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date> , private router : Router) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Number', 'Points', 'Status', 'Action'];
  dataSource = new MatTableDataSource<UserMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.userhistoryForm = this.formBuilder.group({
    })
    this.getAllUserList();
  }

  getAllUserList()
  {
    this.httpService.getRequest(getAllUserMasterURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      if (data) {

        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  transValue:string = "";
  getUserById(userId)
  {
    sessionStorage.setItem("userId" , userId);
    sessionStorage.setItem("transValue", "transaction");
    this.router.navigateByUrl("/layout/viewuserhistory");
  }
  getUserById1(userId)
  {
    sessionStorage.setItem("userId" , userId);
    sessionStorage.setItem("transValue", "bet");
    this.router.navigateByUrl("/layout/viewuserhistory");
  }
  getUserById2(userId)
  {
    sessionStorage.setItem("userId" , userId);
    sessionStorage.setItem("transValue", "winning");
    this.router.navigateByUrl("/layout/viewuserhistory");
  }
  getUserById3(userId)
  {
    sessionStorage.setItem("userId" , userId);
    this.router.navigateByUrl("/layout/userprofile");
  }
 
exportToExcel() {
  const ws: xlsx.WorkSheet =
  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
  const wb: xlsx.WorkBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, 'User History.xlsx');
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
