import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agentwinningamountreports',
  templateUrl: './agentwinningamountreports.component.html',
  styleUrls: ['./agentwinningamountreports.component.css']
})
export class AgentwinningamountreportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
