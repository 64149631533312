import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { getShreeStarLineCurrentDateActiveList } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-shreestarlinecurrentdateactive',
  templateUrl: './shreestarlinecurrentdateactive.component.html',
  styleUrls: ['./shreestarlinecurrentdateactive.component.css']
})
export class ShreestarlinecurrentdateactiveComponent implements OnInit {

  resultName: string;
  resultList: any;
  curr = new Date;

  constructor(private formBuilder: FormBuilder, private router: Router, private httpService: HttpmethodsService, public toastr: ToastrManager) { }


  ngOnInit() {

    this.showChart();

  }

  showChart() {

    this.httpService.getRequest(getShreeStarLineCurrentDateActiveList).subscribe((data: any) => {
      this.resultList = data;
      this.resultName=this.resultList[0].name;
      

    })
  }

  navigatehome(){
    if(sessionStorage.getItem("userId") !=null){
      this.router.navigateByUrl("/pages/userhome")
    }
    else{
      this.router.navigateByUrl("/home");
    }
  }
}
