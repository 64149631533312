import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShreeStarLineWaise, UserMaster } from '../AllModels';
import { getLineWiseDataURL, getShreeStarLineActiveListURL, getStarLineLineWiseDataURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-shreestarlinehistory',
  templateUrl: './shreestarlinehistory.component.html',
  styleUrls: ['./shreestarlinehistory.component.css']
})
export class ShreestarlinehistoryComponent implements OnInit {

  shreestarlinehistoryForm: FormGroup;
  ActiveLineList:any;
  roleId:number;
  roleFlag:boolean=false;
  ShreeStarLineWaiseModel= new ShreeStarLineWaise();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router : Router) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Line Name', 'Line Date', 'Open No', 'Mid No','Status', 'Action'];
  dataSource = new MatTableDataSource<UserMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.shreestarlinehistoryForm = this.formBuilder.group({
    })
    this.getActiveLine();
    this.roleId=Number(sessionStorage.getItem("roleId"));

    if(this.roleId==1)
    {
      this.roleFlag=true;
 
    }
    else{
      this.roleFlag=false;
     

    }
  }

  getActiveLine()
  {
      this.httpService.getRequest(getShreeStarLineActiveListURL).subscribe((data: any) => {
        if (data) {
          
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
        }
        else {
          this.toastr.errorToastr("No Records Found.", "Error");
        }      
      })
    }
    getLineWiseData(shreeStarLineId)
    {
        this.httpService.getRequest(getStarLineLineWiseDataURL + "/" + shreeStarLineId).subscribe((data: any) => {
     
         this.ShreeStarLineWaiseModel=data;
         if(this.ShreeStarLineWaiseModel.totalWinUser==null)
         {
          this.ShreeStarLineWaiseModel.totalWinUser=0;
         }
         if(this.ShreeStarLineWaiseModel.totalBidUser==null)
         {
          this.ShreeStarLineWaiseModel.totalBidUser=0;
         }

        })
      }
    getLineById(element)
    {

         sessionStorage.setItem("lineId" , element.shreeStarLineId);
         sessionStorage.setItem("lineName", element.name)
          this.router.navigateByUrl('/layout/viewshreestarlinehistory');
    }
    getLineById1(element)
    {
   
      sessionStorage.setItem("linemaster" , JSON.stringify(element));
         sessionStorage.setItem("lineId" , element.shreeStarLineId);
         sessionStorage.setItem("lineName", element.name)
         sessionStorage.setItem("lineDate", element.date)

          this.router.navigateByUrl('/layout/shreestarlineresultentry');
    }
    getLineById2(element)
    {

      sessionStorage.setItem("linemaster" , JSON.stringify(element));
         sessionStorage.setItem("lineId" , element.shreeStarLineId);
         sessionStorage.setItem("lineName", element.name)
         sessionStorage.setItem("lineDate", element.date)

          this.router.navigateByUrl('/layout/shreestarlinerevertresult');
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    exportToExcel()
    {
      
    }

}
