import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  
  invokeFirstComponentFunction = new EventEmitter(); 
  invokeFirstComponentCart= new EventEmitter();   
  subsVar: Subscription; 
  subsCart: Subscription;
  constructor() { }

  onFirstComponentButtonClick() { 
    this.invokeFirstComponentFunction.emit("Sachin");    
  }   

  onFirstComponentButtonCart() { 
     this.invokeFirstComponentCart.emit("Sachin");    
   }   
}
