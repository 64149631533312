import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  sideBarOpen=true;
  routerSubscription: Subscription;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router) { }
  ngOnInit() {
    if(sessionStorage.getItem("agentId")==null)
    {
      this.router.navigateByUrl("/auth/login");
    }

  }

  sideBarToggler($event)
  {
    this.sideBarOpen= !this.sideBarOpen;
  }

  // sideBarOpen=true;
  // constructor() { }

  // ngOnInit() {
  // }

  // sideBarToggler($event)
  // {
  //   this.sideBarOpen= !this.sideBarOpen;
  // }

 

}
