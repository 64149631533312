import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LoginMaster } from 'src/app/AllModels';
import { getUserByIdURL, userLoginURL } from 'src/app/AllURL';
import { user } from 'src/app/auth/user';
import { HttpmethodsService } from 'src/app/shared/service/httpmethods.service';

@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.css']  
})
export class UserloginComponent implements OnInit {

  submitted: boolean = false;
  mobileflag: boolean = false;
  fieldTextType: boolean;
  errormsg: string;
  LoginMasterModel = new LoginMaster();
  user: user = new Object();
  error: any;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService , private router: Router) { }

  ngOnInit() {

  }
  Login() {
  
    this.httpService.postRequest(userLoginURL, this.LoginMasterModel).subscribe((data: any) => {
    
      if (data.responseCode == 200) {  
        sessionStorage.setItem("UserMaster",JSON.stringify(data));
        this.toastr.successToastr(data.message, 'Success!', {timeout : 500});
        sessionStorage.setItem("userId", data.userId);
        sessionStorage.setItem("agentId", data.agentMaster.agentId);
        sessionStorage.setItem("roleId", data.roleId);
        this.router.navigateByUrl("/pages/userhome");
      }
      if (data.responseCode == 404) {
        this.toastr.errorToastr(data.message, 'Error!', {timeout : 500});
        this.router.navigateByUrl("/userlogin")
        // this.showSpinner = false; 
        this.LoginMasterModel = new LoginMaster();

      }
      if (data.responseCode == 403) {
        this.toastr.errorToastr(data.message, 'Error!', {timeout : 500});
        this.router.navigateByUrl("/userlogin")
        // this.showSpinner = false; 
        this.LoginMasterModel = new LoginMaster();

      }
      if (data.responseCode == 400) {
        this.toastr.errorToastr(data.message, 'Error!', {timeout : 500});
        this.router.navigateByUrl("/userlogin")
        // this.showSpinner = false;
        this.LoginMasterModel = new LoginMaster();

      }
    })
  }
    
  checkMobileLength(userMob) {
    if (userMob.length < 10) {
      this.errormsg = "Enter 10 digit Number";
      this.mobileflag = false; 
    }
    else {
      this.errormsg = "";
      this.mobileflag = true; 
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }



}
