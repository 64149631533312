import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NotificationMaster, LineMaster } from '../AllModels';
import { saveNotificationURL, updateNotificationURL, getByIdNotificationURL, getAllNotificationURL, checkMobileNumberURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-notificattionmaster',
  templateUrl: './notificattionmaster.component.html',
  styleUrls: ['./notificattionmaster.component.css']
})
export class NotificattionmasterComponent implements OnInit {

  NotificationMasterForm: FormGroup;
  NotificationMasterModel = new NotificationMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Title', 'Description', 'Date', 'Status', 'Action'];
  dataSource = new MatTableDataSource<NotificationMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  
  ngOnInit() {
    this.NotificationMasterForm = this.formBuilder.group({
      Title: new FormControl('', [Validators.required,]),
      Description: new FormControl('', [Validators.required,]),
      date: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
    })
    this.getAllUser();
    this.hide = true;
  }

  checkboxChanged(event) {
    }
  SaveNotification() {
    if (this.isEdit) {
      this.httpService.postRequest(saveNotificationURL, this.NotificationMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.NotificationMasterForm.reset();
          this.NotificationMasterModel = new NotificationMaster();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
   
    }
    else {
   
      this.httpService.putRequest(updateNotificationURL, this.NotificationMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.NotificationMasterForm.reset();
          this.NotificationMasterModel = new NotificationMaster();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    
    }
  }
 

  getNotificationmasterById(notificationMasterId) {
    this.httpService.getRequest(getByIdNotificationURL + "/" + notificationMasterId).subscribe((data: any) => {
     this.NotificationMasterModel=data;
     this.NotificationMasterModel.notificationMasterDate=new Date(data.notificationMasterDate)
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllUser() {
    this.httpService.getRequest(getAllNotificationURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  checkMobileNumber(userMob) {
    this.httpService.getRequest(checkMobileNumberURL + "/" + userMob).subscribe((data: any) => {
      if (data == true) {
        this.mobileMsg = "Valid Mobile No ";
        this.textColorMobile = "green";
        this.mobFlag = true;
      }
      else {
        this.mobileMsg = "Mobile Number Already Exist";
        this.textColorMobile = "Red";
        this.mobFlag = false;
      }
    })
  }

   ///////////////////////For Search ////////////////////
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
