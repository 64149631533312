import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AgentMaster, UserMaster } from '../AllModels';
import { getAllAgentUser, getUserByIdURL, updateAgentUserURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-agentprofile',
  templateUrl: './agentprofile.component.html',
  styleUrls: ['./agentprofile.component.css']
})
export class AgentprofileComponent implements OnInit {

  agentregistrationForm: FormGroup;
  AgentMasterModel = new AgentMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  updateFlag: boolean = false;
  editFlag: boolean = true;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Number', 'Mail ID', 'Address', 'Status', 'Action'];
  dataSource = new MatTableDataSource<UserMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.agentregistrationForm = this.formBuilder.group({
      fullname: new FormControl('', [Validators.required,]),
      altnumber: new FormControl('', [Validators.pattern("[6-9]{1}[0-9]{9}")]),
      number: new FormControl('', [Validators.required, Validators.pattern("[6-9]{1}[0-9]{9}")]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      status: new FormControl(''),
      Pincode: new FormControl(''),
      IFSCCode: new FormControl(''),
      city: new FormControl(''),
      accnumber: new FormControl(''),
      bankname: new FormControl(''),
      accholder: new FormControl(''),
      paytmnumber: new FormControl(''),
      gpaymnumber: new FormControl(''),
      phonepaynumber: new FormControl(''),
      whatsappnumber: new FormControl('', [Validators.required]),

    })



    this.getUserById();
    this.profiledisable();

  }

  profiledisable() {
    this.agentregistrationForm.controls['fullname'].disable();
    this.agentregistrationForm.controls['altnumber'].disable();
    this.agentregistrationForm.controls['number'].disable();
    this.agentregistrationForm.controls['email'].disable();
    this.agentregistrationForm.controls['password'].disable();
    this.agentregistrationForm.controls['address'].disable();
    this.agentregistrationForm.controls['status'].disable();
    this.agentregistrationForm.controls['Pincode'].disable();
    this.agentregistrationForm.controls['IFSCCode'].disable();
    this.agentregistrationForm.controls['city'].disable();
    this.agentregistrationForm.controls['accnumber'].disable();
    this.agentregistrationForm.controls['bankname'].disable();
    this.agentregistrationForm.controls['accholder'].disable();
    this.agentregistrationForm.controls['paytmnumber'].disable();
    this.agentregistrationForm.controls['gpaymnumber'].disable();
    this.agentregistrationForm.controls['phonepaynumber'].disable();
    this.agentregistrationForm.controls['whatsappnumber'].disable();
   

  }

  profileenable() {
    this.agentregistrationForm.controls['fullname'].enable();
    this.agentregistrationForm.controls['altnumber'].enable();
    this.agentregistrationForm.controls['number'].enable();
    this.agentregistrationForm.controls['email'].enable();
    this.agentregistrationForm.controls['password'].enable();
    this.agentregistrationForm.controls['address'].enable();
    this.agentregistrationForm.controls['status'].enable();
    this.agentregistrationForm.controls['Pincode'].enable();
    this.agentregistrationForm.controls['IFSCCode'].enable();
    this.agentregistrationForm.controls['city'].enable();
    this.agentregistrationForm.controls['accnumber'].enable();
    this.agentregistrationForm.controls['bankname'].enable();
    this.agentregistrationForm.controls['accholder'].enable();
    this.agentregistrationForm.controls['paytmnumber'].enable();
    this.agentregistrationForm.controls['gpaymnumber'].enable();
    this.agentregistrationForm.controls['phonepaynumber'].enable();
    this.agentregistrationForm.controls['whatsappnumber'].enable();
   


    this.editFlag = false;
    this.updateFlag = true;
  }

  update() {
    
    this.httpService.putRequest(updateAgentUserURL, this.AgentMasterModel).subscribe((data: any) => {
      if (data) {
        this.isEdit = true;
        this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
        this.agentregistrationForm.reset();
        this.mobileMsg = " ";
      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
      }
      this.editFlag = true;
      this.updateFlag = false;
    })
    this.profiledisable();
    this.getUserById();
  }

  getUserById() {
    this.httpService.getRequest(getUserByIdURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      this.AgentMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();

    })
  }
}
