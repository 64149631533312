import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EventEmitterService } from '../shared/service/event-emitter.service';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import { AddWalletPoints } from '../AllModels';
import { addWallatePointsURL, getActiveUserListURL } from '../AllURL';

@Component({
  selector: 'app-addpoints',
  templateUrl: './addpoints.component.html',
  styleUrls: ['./addpoints.component.css']
})
export class AddpointsComponent implements OnInit {
  UserfundrequestForm: FormGroup;
  UserFundMasterModel = new AddWalletPoints ();
  userList:any;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog, private eventEmitterService: EventEmitterService) { }


  ngOnInit() {
    this.UserfundrequestForm = this.formBuilder.group({
      userId:new FormControl('', [Validators.required,]),
      walletPoints: new FormControl('', [Validators.required,])
    })
    this.getActiveUser();
  }

  getActiveUser(){
    this.httpService.getRequest(getActiveUserListURL +"/"+ Number(sessionStorage.getItem("agentId"))).subscribe((data:any)=>{
      this.userList=data;
    })
  }

  addFunds(){
    this.UserFundMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.httpService.postRequest(addWallatePointsURL,this.UserFundMasterModel).subscribe((data:any)=>{
      if (data) {
        this.toastr.successToastr('Add Points Successfully...!', 'Success!', { timeout: 500 });
        this.UserfundrequestForm.reset();
      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
      }
    })
  }

}
