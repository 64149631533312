import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShreeStarLineFinalResult } from '../AllModels';
import { getByNameResult, getByShreeStarLineNameResult } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-shreestarlineresultchart',
  templateUrl: './shreestarlineresultchart.component.html',
  styleUrls: ['./shreestarlineresultchart.component.css']
})
export class ShreestarlineresultchartComponent implements OnInit {

  no: string = '123';
  resultList = Array<ShreeStarLineFinalResult>();
  number1: number;
  number2: number;
  number3: number;
  number4: number;
  number5: number;
  number6: number;
  number7: number;
  number8: number;
  dateStart: string;
  dateEnd: string;
  resultName: string;

  showJodi: string;
  showJodiFlag: boolean = false;
  showChartFlag: boolean = false;
  curr = new Date;

  first = this.curr.getDate() - this.curr.getDay(); 
  last = this.first + 6;

  constructor(private formBuilder: FormBuilder, private router: Router, private httpService: HttpmethodsService, public toastr: ToastrManager) { }



  ngOnInit() {
    this.showChart();
    this.resultName = String(sessionStorage.getItem("name"));

    if (sessionStorage.getItem("showjodi") == 'showjodi') {
      this.showJodiFlag = true;
      this.showChartFlag = false;
      sessionStorage.clear();
    }
    else if (sessionStorage.getItem("showchart") == 'showchart') {
      this.showJodiFlag = false;
      this.showChartFlag = true;
      sessionStorage.clear();
    }
  }
  showChart() {

    this.httpService.getRequest(getByShreeStarLineNameResult + "/" + sessionStorage.getItem("name")).subscribe((data: any) => {
   
      this.resultList = data;
      console.log("resultList=="+JSON.stringify(this.resultList));
  
      for (let i = 0; i <= this.resultList.length; i++) {

      }

    })
  }

}
