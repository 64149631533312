import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShreeStarLineHistoryMaster } from '../AllModels';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-viewshreestarlinehistory',
  templateUrl: './viewshreestarlinehistory.component.html',
  styleUrls: ['./viewshreestarlinehistory.component.css']
})
export class ViewshreestarlinehistoryComponent implements OnInit {

  ShreeStarLineLineHistoryMasterModel  = new ShreeStarLineHistoryMaster();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router : Router) {
  }

  ngOnInit() {
  }

showbid1()
{
  sessionStorage.setItem("gameName", "Single Digit")
  this.router.navigateByUrl("layout/bidwiseshreestarlinehistory");
}
showbid2()
{
  sessionStorage.setItem("gameName", "Single Patti")
  this.router.navigateByUrl("layout/bidwiseshreestarlinehistory");
}
showbid3()
{
  sessionStorage.setItem("gameName", "Double Patti")
  this.router.navigateByUrl("layout/bidwiseshreestarlinehistory");
}
showbid4()
{
  sessionStorage.setItem("gameName", "Triple Patti")
  this.router.navigateByUrl("layout/bidwiseshreestarlinehistory");
}
}
