import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator, ThemePalette } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LineMaster, NewLineMaster } from '../AllModels';
import { addLineMasterURL, updateLineMasterURL, getByIdLineMasterURL, getAllLineMasterURL, checkMobileNumberURL, saveNewLineURL, getAllNewLineURL, updateNewLineURL, getByIdNewLineURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as moment from 'moment';

@Component({
  selector: 'app-schedularlinemaster',
  templateUrl: './schedularlinemaster.component.html',
  styleUrls: ['./schedularlinemaster.component.css']
})
export class SchedularlinemasterComponent implements OnInit {

  LineMasterForm: FormGroup;
  NewLineMasterModel = new NewLineMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Line Name', 'StartTime', 'EndTime', 'Status', 'Action'];
  dataSource = new MatTableDataSource<LineMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  @ViewChild('picker', { static: false }) picker: any;
  @ViewChild('picker1', { static: false }) picker1: any;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateControl = new FormControl(new Date());

  public date1: moment.Moment;
  public disabled1 = false;
  public showSpinners1 = true;
  public showSeconds1 = false;
  public touchUi1 = false;
  public enableMeridian1 = false;
  public minDate1: moment.Moment;
  public maxDate1: moment.Moment;
  public stepHour1 = 1;
  public stepMinute1 = 1;
  public stepSecond1 = 1;
  public color1: ThemePalette = 'primary';
  public dateControl1 = new FormControl(new Date());
  
  ngOnInit() {
    this.LineMasterForm = this.formBuilder.group({
      Linename: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required]),
      monday: new FormControl(''),
      tuesday: new FormControl(''),
      wednesday: new FormControl(''),
      thursday: new FormControl(''),
      friday: new FormControl(''),
      saturday: new FormControl(''),
      sunday: new FormControl(''),
    })
    this.getAllUser();
    this.hide = true;
  }

 
  SaveLine() {
    if (this.isEdit) {

      this.httpService.postRequest(saveNewLineURL, this.NewLineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.LineMasterForm.reset();
          this.NewLineMasterModel = new NewLineMaster();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {
      this.httpService.putRequest(updateNewLineURL, this.NewLineMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.LineMasterForm.reset();
          this.NewLineMasterModel = new NewLineMaster();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
 

  getLinemasterById(newlineId) {
    this.httpService.getRequest(getByIdNewLineURL + "/" + newlineId).subscribe((data: any) => {
      this.NewLineMasterModel = data;
      this.NewLineMasterModel.newlineStartTime = new Date(data.newlineStartTime);
      this.NewLineMasterModel.newlineEndTime = new Date(data.newlineEndTime);
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllUser() {
    this.httpService.getRequest(getAllNewLineURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

 
   ///////////////////////For Search ////////////////////
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
