import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatDialog } from '@angular/material';
import { Label } from 'ng2-charts';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Dashboard } from '../AllModels';
import { getDashboardCountURL, getTodaysBidCount, getWeeklyListByAgentId } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
// import * as $ from 'jquery' ;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  // constructor() { }
  dashboardModel = new Dashboard();
  roleFlag: boolean = false;
  roleFlag1: boolean = false;
  roleId: number;
  todayBidCnt: number;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe) { }

  public canvas: any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;

  public arbarChartLabels: Label[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  public arbarChartType = 'bar';
  public arbarChartOptions: any = { legend: { display: true, labels: { fontColor: 'black' } } };
  public barChartData: any[] = [];


  ngOnInit() {
    ///////////////
    this.getAllUser();
    this.GetAverageVisitBar();
    this.getTodaysBidCount();
    this.roleId = Number(sessionStorage.getItem("roleId"));

    if (this.roleId == 1) {
      this.roleFlag = true;
      this.roleFlag1 = false;

    }
    else {
      this.roleFlag = false;
      this.roleFlag1 = true;

    }

    // $(document).ready(function () {
    //   $('.counter-value').each(function () {
    //     $(this).prop('Counter').animate({
    //       Counter: $(this).text()
    //     }, {
    //       duration: 3500,
    //       easing: 'swing',
    //       step: function (now) {
    //         $(this).text(Math.ceil(now));
    //       }
    //     });
    //   });
    // });

    // ///////

  }

  getTodaysBidCount() {
    this.httpService.getRequest(getTodaysBidCount + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      this.todayBidCnt = data.todayBidCnt;
    })
  }

  getAllUser() {
    this.httpService.getRequest(getDashboardCountURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      if (data) {
        this.dashboardModel = data;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  GetAverageVisitBar() {
    this.httpService.getRequest(getWeeklyListByAgentId + "/" + sessionStorage.getItem("agentId"))
      .subscribe((data: any) => {
        const winingPoints = data.winingPointList.map((value: number | null) => value === null ? 0 : value);
        const bidingPoints = data.bidingPointList.map((value: number | null) => value === null ? 0 : value);
        this.barChartData = [
          { data: winingPoints, label: 'Winning Point' },
          { data: bidingPoints, label: 'Bid Point' }
        ];
        console.log('barChartData', this.barChartData);
      });
  }
}
