import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DateAdapter, MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserMaster } from '../AllModels';
import { agentHistoryURL, viewBetTransactionURL, viewUserTransactionURL, viewWinningTransactionURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-viewuserhistory',
  templateUrl: './viewuserhistory.component.html',
  styleUrls: ['./viewuserhistory.component.css']
})
export class ViewuserhistoryComponent implements OnInit {

  AgentTransactionForm: FormGroup;
  UserMasterModel = new UserMaster();
  UserTransactionList: any;
  UserBetList: any;
  UserWinningList:any;
  transactinFlag : boolean = false;
  betFlag : boolean = false;
  winningFlag : boolean = false;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog) { }

  ngOnInit() {
    this.AgentTransactionForm = this.formBuilder.group({

    })

    this.getAllData();
    
  }

  getAllData(){

    if (sessionStorage.getItem("transValue") == 'transaction') {

      this.httpService.getRequest(viewUserTransactionURL + "/" + sessionStorage.getItem("userId")).subscribe((data: any) => {
        this.UserTransactionList = data;
        this.UserMasterModel = this.UserTransactionList[0].userMaster;
        this.transactinFlag = true;
        this.betFlag = false;
        this.winningFlag = false;
      })
    }
    else if (sessionStorage.getItem("transValue") == 'bet') {
      this.httpService.getRequest(viewBetTransactionURL + "/" + sessionStorage.getItem("userId")).subscribe((data: any) => {
        this.UserBetList = data;
        this.UserMasterModel = this.UserBetList[0].userMaster;
        this.transactinFlag = false;
        this.betFlag = true;
        this.winningFlag = false;
      
      })
    }
    else if (sessionStorage.getItem("transValue") == 'winning') {

      this.httpService.getRequest(viewWinningTransactionURL + "/" + sessionStorage.getItem("userId")).subscribe((data: any) => {
        this.UserWinningList = data;
    
        this.UserMasterModel = this.UserWinningList[0].userMaster;
        this.transactinFlag = false;
        this.betFlag = false;
        this.winningFlag = true;
      })
    }

  }


}
