  import { IfStmt } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { getAgentWalletURL, getAllFundRequestURL } from 'src/app/AllURL';
import { EventEmitterService } from '../service/event-emitter.service';
import { HttpmethodsService } from '../service/httpmethods.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  roleId: number;
AgentStatus:string;
AgentWallet:string;
AgentName:string;
roleFlag: boolean = false;
roleFlag1: boolean = false;
Count:number;
changepassFlag :boolean;


  @Output() toggleSideBarForMe:EventEmitter<any> = new EventEmitter();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private router: Router, private eventEmitterService: EventEmitterService) { }

ngOnInit() {

  this.roleId = Number(sessionStorage.getItem("roleId"));


  if (this.roleId == 1) {
    this.roleFlag = true;
    // this.roleFlag1 = false;
  }
  else {
    this.roleFlag = false;
    // this.roleFlag1 = true;
  }

  this.getAgentStatus();
  this.getAllUser();

  if (this.eventEmitterService.subsCart==undefined) {  
    this.eventEmitterService.subsCart = this.eventEmitterService.    
    invokeFirstComponentCart.subscribe((name:string) => { 
      this.getAgentStatus();     
    });
  }
  if (this.eventEmitterService.subsVar==undefined) {   
    this.eventEmitterService.subsVar = this.eventEmitterService.    
    invokeFirstComponentFunction.subscribe((name:string) => {  
      this.getAgentStatus();    
    });    
  }

  if(sessionStorage.getItem("editFlag") == "true"){
    this.changepassFlag = true;
  }
  else{
    this.changepassFlag = false;
  }
}
toggleSideBar(){
this.toggleSideBarForMe.emit();
}

logout()
{
  // sessionStorage.removeItem("compId");
  sessionStorage.removeItem("agentId");
  this.router.navigateByUrl("/auth/login");
}
getAllUser() {
  this.httpService.getRequest(getAllFundRequestURL + "/" + sessionStorage.getItem("agentId") + "/" + "Pending").subscribe((data: any) => {
    this.Count=data.length;
  })
}

getAgentStatus()
{
  this.httpService.getRequest(getAgentWalletURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
    this.AgentStatus=data.agentStatus;
    this.AgentWallet=data.agentwalletPoints;
    this.AgentName=data.agentName;
    if(this.AgentWallet == null)
    {
      this.AgentWallet= "0" ;
    }
    if(this.AgentStatus=="Inactive")
    {
      this.router.navigateByUrl("/auth/login");
      this.toastr.errorToastr("Account has been blocked please Contact Admin...", "Error");
    }

  }) 
}
}
